import React, { useEffect, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { buildCommissionHistoryLogMessage } from '../../helpers/commissionHistoryLog';
import './Commission.css';

const CommissionHistoryLog = ({ commissionHistory, nm, commissions }) => {
  const [commissionHistoryLogMessages, setCommissionHistoryLogMessages] = useState([]);

  useEffect(() => {
    if (!commissionHistory?.length) {
      setCommissionHistoryLogMessages(['No commission history logs yet']);
    } else {
      const logs = [];
      //create both objects
      const commissionHistoryGroupedByID = {};
      const commissionsObject = {};

      //loop through commissions
      for (let i = 0; i < commissions.length; i++) {
        commissionHistoryGroupedByID[commissions[i]['ID']] = [];
        commissionsObject[commissions[i]['ID']] = commissions[i];
      }

      //loop through commission history, if the id exists in the commissionHistoryGroupedByID push commission history[i] onto commissionHistoryGroupedByID[id]
      for (let i = 0; i < commissionHistory.length; i++) {
        if (commissionHistoryGroupedByID[commissionHistory[i]['RowData']['ID']]) {
          commissionHistoryGroupedByID[commissionHistory[i]['RowData']['ID']].push(commissionHistory[i]);
        }
      }

      //now you've set up the pieces, your lists that you need to loop through
      for (const [key, value] of Object.entries(commissionHistoryGroupedByID)) {
        for (let i = 0; i < value.length; i++) {
          let newerLog, olderLog, modifiedAuthor;
          modifiedAuthor = value[i].ModifiedAuthor ? value[i].ModifiedAuthor.split('@')[0] : 'networkmerchantapi';
          if (i === 0) {
            olderLog = value[i].RowData;
            newerLog = commissionsObject[key];
            if (olderLog.Currency === undefined) {
              olderLog.Currency = newerLog.Currency; //this is another layer to prevent currency property not existing in history from causing an issue
            }
          } else {
            olderLog = value[i].RowData;
            newerLog = value[i - 1].RowData;
          }

          delete newerLog.statusCode;
          delete olderLog.statusCode;

          logs.push(buildCommissionHistoryLogMessage(newerLog, olderLog, value[i].MTime, modifiedAuthor));
        }
      }

      //now you've got a correct set of logs, but you need to sort them chronologically
      //we know the commission history is already in order, so we create a map by looping through the logs variable, the property will be the commission history time, the value will be the built commissionlogmessage

      const commissionHistoryLogsMapByTime = {};
      for (let i = 0; i < logs.length; i++) {
        if (logs[i]) {
          commissionHistoryLogsMapByTime[logs[i].time] = logs[i].commissionHistoryLogMessage;
        }
      }

      const sortedMessages = [];

      for (let i = 0; i < commissionHistory.length; i++) {
        if (commissionHistoryLogsMapByTime[commissionHistory[i].MTime]) {
          sortedMessages.push(commissionHistoryLogsMapByTime[commissionHistory[i].MTime]);
        }
      }

      setCommissionHistoryLogMessages(sortedMessages);
    }
  }, [commissionHistory, nm, commissions]);

  return (
    <>
      <h3 className="header" style={{ paddingLeft: '15px' }}>
        Commission History Log
      </h3>
      <Segment.Group className="commission-history-log">
        {commissionHistoryLogMessages.map((msg, i) => {
          if (msg !== undefined) {
            return <Segment key={i}>{msg}</Segment>;
          }
          return <></>;
        })}
      </Segment.Group>
    </>
  );
};

export default CommissionHistoryLog;

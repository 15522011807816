import { Action, PayloadFailure } from '../../types';

export interface Network {
  ID: number;
  Name: string;
  TemplateURL: string;
  Disabled: boolean;
  AffiliateCode: string;
  NetworkPublisherID: number;
}

interface NetworkById {
  [networkId: number]: Network;
}

export interface NetworkState {
  networks: NetworkById | null;
}

export const GET_NETWORKS = 'GET_NETWORKS';
export const GET_NETWORKS_REQUEST = 'GET_NETWORKS_REQUEST';
export const GET_NETWORKS_SUCCESS = 'GET_NETWORKS_SUCCESS';
export const GET_NETWORKS_FAILURE = 'GET_NETWORKS_FAILURE';

interface NetworksPayload {
  networks: Network[];
}

type PayloadMap = {
  [GET_NETWORKS_REQUEST]: undefined;
  [GET_NETWORKS_SUCCESS]: NetworksPayload;
  [GET_NETWORKS_FAILURE]: PayloadFailure;
};

export type NetworkAction<T extends keyof PayloadMap> = Action<T, PayloadMap[T]>;

export type NetworkActionTypes =
  | NetworkAction<typeof GET_NETWORKS_REQUEST>
  | NetworkAction<typeof GET_NETWORKS_SUCCESS>
  | NetworkAction<typeof GET_NETWORKS_FAILURE>;

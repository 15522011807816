import React from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableRow, Loader, TableHeaderCell } from 'semantic-ui-react';
import { OnboardedMerchant } from './types';
import './RecentlyOnboardedMerchantsTable.scss';

interface RecentlyOnboardedMerchantsTableProps {
  onboardedMerchants: OnboardedMerchant[];
  isLoading: boolean;
  error: string;
}

const RecentlyOnboardedMerchantsTable = ({
  onboardedMerchants,
  isLoading,
  error,
}: RecentlyOnboardedMerchantsTableProps) => {
  const tableRows = onboardedMerchants.map((merchant: OnboardedMerchant, index) => {
    const { ID, Name, Disabled, Score, CreatedDate } = merchant;

    const formattedOnboardedDate = CreatedDate.split('T')[0] || 'Invalid Date';

    if (formattedOnboardedDate === 'Invalid Date') {
      console.error(`No created date provided for Merchant ID: ${ID}`);
    }

    return (
      <TableRow key={ID} data-testid={`table-body-row-${index}`}>
        <TableCell>
          <p>{ID}</p>
        </TableCell>
        <TableCell selectable>
          <Link to={`/merchant-admin/merchant/${ID}`}>
            <a>{Name}</a>
          </Link>
        </TableCell>
        <TableCell>
          <p>{formattedOnboardedDate}</p>
        </TableCell>
        {Disabled ? (
          <TableCell negative>
            <p>Disabled</p>
          </TableCell>
        ) : (
          <TableCell positive>
            <p>Enabled</p>
          </TableCell>
        )}
        <TableCell>
          <p>{Score}</p>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <div className="recently-onboarded-merchants-table-container">
      <Table celled striped aria-label="recently onboarded merchants table">
        <thead>
          <tr>
            <TableHeaderCell className="one wide">Merchant ID</TableHeaderCell>
            <TableHeaderCell className="two wide">Merchant Name</TableHeaderCell>
            <TableHeaderCell className="one wide">Onboarded Date</TableHeaderCell>
            <TableHeaderCell className="one wide">Disabled Status</TableHeaderCell>
            <TableHeaderCell className="one wide">Score</TableHeaderCell>
          </tr>
        </thead>
        {!Boolean(error) && !isLoading && <TableBody>{tableRows}</TableBody>}
      </Table>
      {Boolean(error) && !isLoading && (
        <div className="recently-onboarded-merchants-table-error-container">
          <span className="recently-onboarded-merchants-table-error-text">{error}</span>
        </div>
      )}
      {isLoading && <Loader active size="massive" inline="centered" data-testid="loading-spinner" />}
    </div>
  );
};

export default RecentlyOnboardedMerchantsTable;

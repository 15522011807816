import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

class KeywordDisabledLabel extends Component {
  state = {
    isLoading: false,
  };

  toggleNetworkMerchantKeywords = () => {
    this.setState({ isLoading: true });
    this.props.toggleDisabled();
  };

  // if component changes disabled/enabled, reset isLoading state
  async componentDidUpdate(prevProps) {
    const { isDisabled } = this.props;
    if (prevProps.isDisabled !== isDisabled) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading } = this.state;
    const { isDisabled, children, uneditable } = this.props;

    return (
      <div className={`disabled-label ${uneditable ? 'uneditable' : ''}`}>
        <Label color={isDisabled ? 'red' : 'green'} basic={isLoading} attached="top right">
          <span
            className={`toggle ${uneditable ? 'uneditable' : ''}`}
            onClick={!uneditable ? this.toggleNetworkMerchantKeywords : null}
          >
            {isDisabled ? 'Disabled' : 'Enabled'}
          </span>
          {children}
        </Label>
      </div>
    );
  }
}

KeywordDisabledLabel.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  children: PropTypes.node,
  toggleDisabled: PropTypes.func.isRequired,
  uneditable: PropTypes.bool,
};

export default KeywordDisabledLabel;

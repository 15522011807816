import network from 'redux/store/network/reducer';
import { combineReducers } from 'redux';
import resource from './resource';
import search from './search';

export default combineReducers({
  search,
  resource,
  network,
});

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Confirm } from 'semantic-ui-react';
import { findErrorMessage } from 'helpers';
import { getUserCanEdit } from 'selectors/user';
import './MerchantImage.css';

class MerchantImage extends React.Component {
  state = {
    deleteConfirmOpen: false,
    errorMessage: '',
  };

  handleOpenDeleteConfirm = () => this.setState({ deleteConfirmOpen: true });

  handleCloseDeleteConfirm = () => this.setState({ deleteConfirmOpen: false });

  handleRemoveMerchantImage = async () => {
    const { removeMerchantImage, image } = this.props;
    this.setState({ errorMessage: '' });
    try {
      await removeMerchantImage(image.ID);
    } catch (error) {
      this.setState({ errorMessage: findErrorMessage(error) });
    }
  };

  render() {
    const { deleteConfirmOpen, errorMessage } = this.state;
    const { image, viewOnly, canEdit } = this.props;
    const isViewOnly = viewOnly ?? !canEdit;
    return (
      <div className="merchant-image">
        {!isViewOnly && (
          <div className="options">
            <div className="close" onClick={this.handleOpenDeleteConfirm}>
              Remove
            </div>
            <Confirm
              size="tiny"
              open={deleteConfirmOpen}
              onCancel={this.handleCloseDeleteConfirm}
              onConfirm={this.handleRemoveMerchantImage}
              content={errorMessage ? `${errorMessage}. Try again?` : undefined}
            />
          </div>
        )}
        <img src={image.URL} alt={image.Kind} />
      </div>
    );
  }
}

MerchantImage.propTypes = {
  image: PropTypes.shape({
    Ordinal: PropTypes.number.isRequired,
    URL: PropTypes.string.isRequired,
    Kind: PropTypes.string.isRequired,
  }),
  removeMerchantImage: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  canEdit: PropTypes.bool,
};

const mapStateToProps = state => ({
  canEdit: getUserCanEdit(state),
});

export default connect(mapStateToProps, null)(MerchantImage);

import React from 'react';
import { Input } from 'semantic-ui-react';
import { dateRecipes, idRecipes, amountRecipes } from '../../pages/CommissionSearch/constants';
import './SearchRecipesList.css';

interface SearchRecipesListProps {
  input: Input | null;
  setSearchValue: (string: string) => void;
  searchValue: string;
}

const SearchRecipesList = (props: SearchRecipesListProps) => {
  const { setSearchValue, searchValue, input } = props;

  const handleOnClick = (string: string) => {
    const newSearchValue = `${searchValue} ${string}`.trim();
    setSearchValue(newSearchValue);
    // @ts-ignore
    const inputEl = input.inputRef.current;
    if (inputEl) {
      setTimeout(() => (inputEl.scrollLeft = inputEl.scrollWidth), 0); // 0ms delay makes this wait until after state gets updated
    }
  };

  return (
    <div className="container">
      <h2>Commission Search Recipes</h2>
      <div className="column-container">
        <div className="column">
          <h4>By ID:</h4>
          {idRecipes.map(idRecipe => {
            return (
              <p className="recipe" onClick={() => handleOnClick(idRecipe.value)}>
                {idRecipe.name}
              </p>
            );
          })}
        </div>
        <div className="column">
          <h4>By Date:</h4>
          {dateRecipes.map(dateRecipe => {
            return (
              <p className="recipe" onClick={() => handleOnClick(dateRecipe.value)}>
                {dateRecipe.name}
              </p>
            );
          })}
          <h4>By Amount:</h4>
          {amountRecipes.map(amountRecipe => {
            return (
              <p className="recipe" onClick={() => handleOnClick(amountRecipe.value)}>
                {amountRecipe.name}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchRecipesList;

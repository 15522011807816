import React from 'react';
import { Table, TableBody, TableCell, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { BonusPayment } from './types';
import TruncatedCopyCell from 'components/TruncatedCopyCell/TruncatedCopyCell';

import './BonusPaymentsListTable.scss';

interface BonusPaymentsTableColumns {
  columnName: string;
  target: keyof BonusPayment;
}

const tableColumns: BonusPaymentsTableColumns[] = [
  { columnName: 'ID', target: 'id' },
  { columnName: 'Network Name', target: 'networkName' },
  { columnName: 'Network Merchant Name', target: 'networkMerchantName' },
  { columnName: 'Application Name', target: 'applicationName' },
  { columnName: 'Created Date', target: 'createdDate' },
  { columnName: 'Modified Date', target: 'modifiedDate' },
  { columnName: 'Modified Author', target: 'modifiedAuthor' },
  { columnName: 'Sale Amount', target: 'saleAmount' },
  { columnName: 'Commission Amount', target: 'commissionAmount' },
  { columnName: 'Event Date', target: 'eventDate' },
  { columnName: 'Merchant Order ID', target: 'merchantOrderId' },
  { columnName: 'Tracking Code', target: 'trackingCode' },
  { columnName: 'Bonus Amount', target: 'bonusAmount' },
  { columnName: 'USD Bonus Amount', target: 'usdBonusAmount' },
  { columnName: 'Status', target: 'status' },
];

interface BonusPaymentListTableProps {
  bonusPayments: BonusPayment[];
}

const BonusPaymentsListTable = ({ bonusPayments }: BonusPaymentListTableProps) => {
  const renderTableBody = () => {
    if (!bonusPayments.length) {
      return <></>;
    }

    const tableRows = bonusPayments.map((bonusPayment: BonusPayment) => {
      const rowCells = tableColumns.map((tableColumn: BonusPaymentsTableColumns) => {
        switch (tableColumn.target) {
          case 'modifiedDate':
          case 'createdDate':
          case 'eventDate': {
            const formattedDate = bonusPayment[tableColumn.target].split('T')[0];
            return (
              <TableCell singleLine>
                <span>{formattedDate}</span>
              </TableCell>
            );
          }

          // uses saleCurrency
          case 'saleAmount': {
            return (
              <td>
                <span>{`${parseFloat(bonusPayment[tableColumn.target]).toFixed(2)} ${bonusPayment.saleCurrencyCode}`}</span>
              </td>
            );
          }
          // both use Application Currency
          case 'commissionAmount':
          case 'bonusAmount': {
            return (
              <TableCell>
                <span>{`${parseFloat(bonusPayment[tableColumn.target]).toFixed(2)} ${bonusPayment.applicationCurrencyCode}`}</span>
              </TableCell>
            );
          }
          case 'usdBonusAmount': {
            return (
              <TableCell>
                <span>{`${parseFloat(bonusPayment[tableColumn.target]).toFixed(2)} USD`}</span>
              </TableCell>
            );
          }
          case 'trackingCode': {
            return <TruncatedCopyCell text={bonusPayment[tableColumn.target].toString()} />;
          }

          default: {
            return (
              <TableCell>
                <span>{bonusPayment[tableColumn.target]}</span>
              </TableCell>
            );
          }
        }
      });

      return <TableRow key={bonusPayment.id}>{rowCells}</TableRow>;
    });

    return <TableBody>{tableRows}</TableBody>;
  };

  return (
    <Table compact celled striped aria-label="bonus payments table" className="bonus-payments-list-table">
      <thead>
        <tr>
          {tableColumns.map((tableColumn: BonusPaymentsTableColumns) => {
            return <TableHeaderCell singleLine>{tableColumn.columnName}</TableHeaderCell>;
          })}
        </tr>
      </thead>
      {renderTableBody()}
    </Table>
  );
};

export default BonusPaymentsListTable;

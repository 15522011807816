import { SET_SEARCH_QUERY, SET_SEARCH_RESULTS } from '../../constants/actionTypes';

export const defaultSearch = {
  isLoading: false,
  searchQuery: '',
  searchResults: [],
};

export default (state = defaultSearch, action) => {
  switch (action.type) {
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery,
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.searchResults,
      };
    default:
      return state;
  }
};

import React, { Component } from 'react';
import { Label, Popup } from 'semantic-ui-react';

import './AddToOrdinal.css';

export default class AddToOrdinal extends Component {
  render() {
    const { addToOrdinal, networkMerchantKeyword } = this.props;
    const { IsNetworkMerchantDisabled, IsDisabled } = networkMerchantKeyword;
    return (
      <div className="add-to-ordinal-label">
        <Popup
          content={
            IsDisabled || IsNetworkMerchantDisabled
              ? 'This Network Merchant Keyword cannot be added to the Ordinal Scale, because the Network Merchant or Network Merchant Keyword is disabled.'
              : 'Add this Network Merchant Keyword to the Ordinal Scale'
          }
          trigger={
            <Label
              color={IsDisabled || IsNetworkMerchantDisabled ? 'grey' : 'blue'}
              attached="top left"
              onClick={() => addToOrdinal(networkMerchantKeyword)}
            >
              Add to Ordinal
            </Label>
          }
        ></Popup>
      </div>
    );
  }
}

const errorParser = error => {
  if (error.statusCode === 400) {
    if (error.error.ErrorMessage) {
      const msg = error.error.ErrorMessage;
      const errorKeys = Object.keys(errorObj);
      for (let i = 0; i < errorKeys.length; i++) {
        if (msg.includes(errorKeys[i])) {
          return errorObj[errorKeys[i]];
        }
      }
    }
    if (error.error.Failure) {
      const failureMsg = error.error.Failure;
      return failureMsg;
    }
  } else {
    return 'An Unexpected Error Occurred';
  }
};

const validateForm = values => {
  if (values.application_id === '') {
    return 'An Application ID is required';
  }
  if (values.bonus > 50) {
    return 'Payment must be less than 50';
  }
  for (const property in values) {
    if (values[property] === '') {
      return `${property} is a required field`;
    }
  }
  const needsToBeNumber = ['application_id', 'device_id', 'bonus'];
  for (let i = 0; i < needsToBeNumber.length; i++) {
    if (Number.isNaN(Number(values[needsToBeNumber[i]]))) {
      return `${needsToBeNumber[i]} must be a number`;
    }
  }
  if (values.currency.length !== 3) {
    return 'Please Input a valid 3 digit currency code';
  }
  return '';
};

const errorObj = {
  application: 'The Application ID is missing. Please Enter a Valid Application ID.',
  device: 'The Device ID is either invalid for the selected application or missing',
  tracking: 'The Tracking code is missing. If not tracking code is needed, please enter n/a',
  currency: 'The currency code is either missing or invalid. Please enter a valid currency code, for example, USD',
  comment: 'A comment for why you are making this bonus payment is required',
  amount: 'An amount needs to be entered',
  email: 'Please enter your Wildfire Corp employee email',
};

export { errorParser, validateForm };

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import Navigation from 'components/Navigation/Navigation';
import Main from './Main';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App = () => {
  const { isLoading } = useAuth0();
  ReactGA.initialize('G-73CP0DDF3S');

  if (isLoading) {
    return <LoadingOverlay isLoading />;
  }

  return (
    <div className="app">
      <Navigation />
      <Main />
      <ToastContainer />
    </div>
  );
};

export default App;

import React from 'react';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SENTRY_DSN } from 'helpers/constants';
import Auth0ProviderWithHistory from 'hoc/Auth0ProviderWithHistory';
import App from './App';
import store, { history } from './store';
import 'semantic-ui-css/semantic.min.css';
import './index.css';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/dev-admin\.wildlink\.me\/api/,
        /^https:\/\/admin\.wildlink\.me\/api/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 5,
  environment: import.meta.env.NODE_ENV,
  beforeSend(event) {
    if (event.user) {
      // Don't send user's email address
      delete event.user.email;
    }
    return event;
  },
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
  </Provider>,
  document.getElementById('root'),
);

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Form, Table, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { getNetworkName, toPrettyAmount } from './helpers';
import PaginateControl from './PaginateControl';
import { toPrettyTime } from '../../helpers';

const PAGE_SIZE = 25;

const ReconciliationJobItems = ({ id, mid, getReconcileJobItems }) => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rows, setRows] = useState([]);
  const [job, setJob] = useState({});
  const [merchantName, setMerchantName] = useState('');

  const networkName = getNetworkName(job.NetworkId);

  const breadCrumbSections = [
    { key: 'reconciliation', content: 'Reconciliation', link: true, as: Link, to: '/reconciliation' },
    { key: networkName, content: networkName, link: true, as: Link, to: `/reconciliation/${job.Id}` },
    { key: merchantName, content: merchantName, active: true },
  ];

  useEffect(() => {
    const fetchItemData = async () => {
      setErrorMessage('');
      setIsLoading(true);
      try {
        const { Items, Job } = await getReconcileJobItems({ id, nmxid: mid, page, page_size: PAGE_SIZE });
        setRows(Items);
        setJob(Job);
        if (Items.length > 0) {
          setMerchantName(Items[0].Merchant);
        }
      } catch (e) {
        console.log(e);
        setErrorMessage(
          e.error?.ErrorMessage || e.message || 'An unknown error has occurred while fetching job items.',
        );
      }
      setIsLoading(false);
    };
    fetchItemData();
  }, [page]);

  if (errorMessage) {
    return (
      <div>
        <p>{errorMessage}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }

  return (
    <>
      <div className="flex justify-between items-baseline flex-wrap w-full">
        <h1 className="font-title w-full">{merchantName}</h1>
        <Breadcrumb icon="right angle" className="!mb-5" sections={breadCrumbSections} />
        <div className="flex mb-5 rounded-md w-full flex-nowrap">
          <div className="px-8 py-5 bg-white mr-2 rounded-md hover:bg-gray-50">
            <p>Network:</p>
            <b>{getNetworkName(job.NetworkId)}</b>
          </div>
          <div className="px-8 py-5 bg-white mx-2 rounded-md hover:bg-gray-50">
            <p>Paid Date:</p>
            <b>{toPrettyTime(job.Date)}</b>
          </div>
          <div className="px-8 py-5 bg-white mx-2 rounded-md hover:bg-gray-50">
            <p>Status:</p>
            <b>{job.Status}</b>
          </div>
          <div className="px-8 py-5 bg-white mx-2 rounded-md hover:bg-gray-50">
            <p>Import Attempts:</p>
            <b>{job.RetryCount}</b>
          </div>
        </div>
      </div>
      <div className="bg-white p-8 mb-5 rounded-md w-full">
        <Form className="get-reconcile-job-items" autoComplete="off">
          <Form.Group className="justify-end w-full">
            <PaginateControl
              page={page}
              prevDisabled={page === 1 || isLoading}
              nextDisabled={isLoading}
              onPrev={() => setPage(pageNo => pageNo - 1)}
              onNext={() => setPage(pageNo => pageNo + 1)}
            />
          </Form.Group>
        </Form>
        <Table celled>
          <thead className="text-sm font-bold text-center">
            <tr>
              <th>Sale Amount</th>
              <th>Commission Amount</th>
              <th>Status</th>
              <th>Unique ID</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {rows.map(({ UniqueID, Status, SaleAmount, CommissionAmount }) => {
              return (
                <tr key={UniqueID}>
                  <td>{toPrettyAmount(SaleAmount)}</td>
                  <td>{toPrettyAmount(CommissionAmount)}</td>
                  <td className={Status === 'SUCCESS' ? 'positive' : 'negative'}>
                    {Status !== 'SUCCESS' ? `FAILED (${Status})` : Status}
                  </td>
                  <td>{UniqueID}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ReconciliationJobItems;

import { wfGet, wfPost } from '../../actions';

export const getFraudCommissions = args => async dispatch => {
  const { sortBy = 'marked_as_fraud_on', sortOrder = 'desc' } = args;

  try {
    const res = await dispatch(wfGet(`/api/fraud-commissions?sort_by=${sortBy}&sort_order=${sortOrder}`));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addFraudCommission = commissionIds => async dispatch => {
  try {
    const newFraudCommission = await dispatch(wfPost(`/api/fraud-commission`, { commissionIds }));
    return newFraudCommission;
  } catch (error) {
    return Promise.reject(error);
  }
};

import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Form, Button } from 'semantic-ui-react';
import ResponseMessage from 'components/BonusTool/ResponseMessage';
import { errorParser, validateForm } from './bonusHelpers';

const BonusTool = ({ makeBonusPayment }) => {
  const [responseMessage, setResponseMessage] = useState({ msg: '', status: null });

  const { user } = useAuth0();

  const initialValues = {
    application_id: '',
    device_id: '',
    tracking_code: '',
    bonus: '',
    currency: '',
    comment: '',
  };

  const [values, setValues] = useState(initialValues);

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setResponseMessage({ msg: '', status: null });
    const form = { ...values, employee_id: user.email };
    const validationError = validateForm(form);
    if (validationError) {
      alert(validationError);
      return;
    }
    const id = form.application_id;
    submitBonus(id, form);
  };

  const handleReset = () => {
    setResponseMessage({ msg: '', status: null });
    setValues(initialValues);
  };

  const submitBonus = async (id, form) => {
    try {
      const res = await makeBonusPayment(id, form);
      const successMessage = res.body.Success;
      setResponseMessage({ msg: successMessage, status: 'Success' });
    } catch (e) {
      const parsedError = errorParser(e);
      setResponseMessage({ msg: parsedError, status: 'Error' });
      console.error(e);
    }
  };

  return (
    <div className="bonusTool">
      <h1>Bonus Tool</h1>
      {responseMessage.status && <ResponseMessage responseMessage={responseMessage} />}
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <Form.Group widths="equal">
          <Form.Input
            placeholder="Enter Application ID"
            label="Application ID"
            name="application_id"
            onChange={handleChange}
            value={values.application_id}
          ></Form.Input>
          <Form.Input
            placeholder="Enter Device ID"
            label="Device ID"
            name="device_id"
            onChange={handleChange}
            value={values.device_id}
          ></Form.Input>
        </Form.Group>
        <Form.Input
          placeholder="Enter a Tracking Code or 'n/a'"
          label="Tracking Code"
          name="tracking_code"
          onChange={handleChange}
          value={values.tracking_code}
        ></Form.Input>
        <Form.Group>
          <Form.Input
            placeholder="Max Value $50"
            label="Bonus Amount"
            name="bonus"
            width={10}
            onChange={handleChange}
            value={values.bonus}
          ></Form.Input>
          <Form.Input
            placeholder="USD"
            label="Type"
            name="currency"
            width={6}
            onChange={handleChange}
            value={values.currency}
          ></Form.Input>
        </Form.Group>
        <Form.TextArea
          placeholder="Please enter a comment as to why this bonus is being payed"
          label="Comment"
          name="comment"
          onChange={handleChange}
          value={values.comment}
        ></Form.TextArea>
        <p>{`User Email: ${user.email}`}</p>
        <Button type="submit" primary>
          Submit
        </Button>
        <Button type="reset">Reset</Button>
      </Form>
    </div>
  );
};

export default BonusTool;

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { startCase } from 'lodash';
import { Dropdown, Menu } from 'semantic-ui-react';
import Ellipsis from 'images/ellipsis.png';
import './OverflowMenu.scss';

interface OverflowMenuProps {
  linksToRender: string[];
}

const OverflowMenu: React.FC<OverflowMenuProps> = ({ linksToRender }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  const isActiveMenu = (menu: string) => location.pathname.indexOf(menu) !== -1;
  const { logout: logoutAuth0 } = useAuth0();

  const handleLogout = async () => {
    logoutAuth0();
  };

  return (
    <div className="overflowMenu">
      <Dropdown
        trigger={<img className={menuOpen ? 'active' : ''} src={Ellipsis} alt="Overflow Menu" />}
        onClick={() => setMenuOpen(!menuOpen)}
        onBlur={() => setMenuOpen(false)}
      >
        <Dropdown.Menu>
          {linksToRender.map((link: string) => {
            const title = startCase(link.split('-').join(' '));

            return link === 'logout' ? (
              <Menu.Item name="logout" onClick={handleLogout} />
            ) : (
              <Link key={link} to={`/${link}`}>
                <Menu.Item active={isActiveMenu(`/${link}`)}>{title}</Menu.Item>
              </Link>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default OverflowMenu;

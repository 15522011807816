export const NetworksData = {
  Admitad: {
    id: 18,
    canHaveFile: true,
    fileRequired: true,
  },
  Amazon: {
    id: 16,
    canHaveFile: false,
  },
  Avantlink: {
    id: 10,
    canHaveFile: true,
    fileRequired: true,
  },
  Awin: {
    id: 5,
    canHaveFile: true,
    fileRequired: true,
  },
  CommissionFactory: {
    id: 20,
    canHaveFile: true,
    fileRequired: true,
  },
  CommissionJunction: {
    id: 14,
    canHaveFile: true,
    fileRequired: false,
  },
  Ebay: {
    id: 17,
    canHaveFile: true,
    fileRequired: true,
  },
  FlexOffers: {
    id: 3,
    canHaveFile: true,
    fileRequired: true,
  },
  ImpactRadius: {
    id: 4,
    canHaveFile: true,
    fileRequired: false,
  },
  NetworkOneWildfire: {
    id: 1,
    canHaveFile: true,
    fileRequired: true,
  },
  Partnerize: {
    id: 19,
    canHaveFile: true,
    fileRequired: true,
  },
  Pepperjam: {
    id: 7,
    canHaveFile: true,
    fileRequired: true,
  },
  Rakuten: {
    id: 6,
    canHaveFile: true,
    fileRequired: false,
  },
  Refersion: {
    id: 21,
    canHaveFile: false,
  },
  ShareASale: {
    id: 2,
    canHaveFile: false,
  },
};

export const NetworksList = Object.keys(NetworksData);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Segment, Input, Button, Form } from 'semantic-ui-react';
import { findErrorMessage } from 'helpers';
import { getUserCanEdit } from 'selectors/user';

class KeywordDetail extends Component {
  state = {
    hasError: false,
    isLoading: false,
    errorMessage: '',
  };

  kindRef = React.createRef();
  nameRef = React.createRef();
  searchCodeRef = React.createRef();
  destinationURLRef = React.createRef();
  rankRef = React.createRef();

  handleSubmit = async e => {
    const { updateKeyword, keyword } = this.props;
    e.preventDefault();
    const Kind = this.kindRef.current.value;
    const SearchCode = this.searchCodeRef.current.value;
    const DestinationURL = this.destinationURLRef.current.value;
    const Rank = this.rankRef.current.value;
    const parsedRank = parseFloat(Rank);

    this.setState({ isLoading: true });
    try {
      await updateKeyword({
        Kind,
        KeywordName: keyword.KeywordName,
        SearchCode,
        DestinationURL,
        Rank: parsedRank,
      });
    } catch (e) {
      this.setState({
        hasError: true,
        isLoading: false,
        errorMessage: findErrorMessage(e),
      });
    }
  };

  render() {
    const { hasError, isLoading, errorMessage } = this.state;
    const { keyword, isMerchantKeyword, viewOnly, canEdit } = this.props;
    const isViewOnly = viewOnly ?? !canEdit;
    return (
      <Segment.Group className="add-keyword">
        <Segment>
          {!isViewOnly && isMerchantKeyword && (
            <Segment color="red" inverted textAlign="center">
              Updates here will sync across all Network Merchants
            </Segment>
          )}
          <Form className="keyword-form" autoComplete="off" onSubmit={!isViewOnly ? this.handleSubmit : () => {}}>
            <Input disabled fluid label="ID" name="ID" value={keyword.ID} />
            <Input
              required
              fluid
              label="Kind"
              name="Kind"
              defaultValue={keyword.Kind}
              input={{
                ref: this.kindRef,
              }}
              disabled={isViewOnly}
            />
            <Input disabled fluid label="Name" name="KeywordName" value={keyword.KeywordName} />
            <Input
              fluid
              label="Search Code"
              name="SearchCode"
              defaultValue={keyword.SearchCode}
              input={{
                ref: this.searchCodeRef,
              }}
              disabled={isViewOnly}
            />
            <Input
              fluid
              label="Destination URL"
              name="DestinationURL"
              defaultValue={keyword.DestinationURL}
              input={{
                ref: this.destinationURLRef,
              }}
              disabled={isViewOnly}
            />
            <Input
              required
              fluid
              label="Rank"
              name="Rank"
              defaultValue={keyword.Rank}
              type="number"
              input={{
                ref: this.rankRef,
              }}
              disabled={isViewOnly}
            />
            {hasError && <div className="error">{errorMessage}</div>}
            {!isViewOnly && <Button>{isLoading ? 'Loading' : 'Update'}</Button>}
          </Form>
        </Segment>
      </Segment.Group>
    );
  }
}

KeywordDetail.propTypes = {
  keyword: PropTypes.object.isRequired,
  updateKeyword: PropTypes.func.isRequired,
  isMerchantKeyword: PropTypes.bool.isRequired,
  viewOnly: PropTypes.bool,
  canEdit: PropTypes.bool,
};

const mapStateToProps = state => ({
  canEdit: getUserCanEdit(state),
});

export default connect(mapStateToProps, null)(KeywordDetail);

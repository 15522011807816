import { connect } from 'react-redux';
import Reconciliation from './Reconciliation';
import { getReconcileJobs, createReconcileJob, retryReconcileJob } from '../../actions/reconciles/reconciles';

const mapActionsToProps = {
  getReconcileJobs,
  createReconcileJob,
  retryReconcileJob,
};

export default connect(null, mapActionsToProps)(Reconciliation);

import React, { useState, useEffect } from 'react';

interface CounterProps {
  className?: string;
  counterHeight?: number;
  defaultValue: number;
  label?: string;
  minValue: number;
  maxValue: number;
  setValue: (value: number) => void;
}

const Counter = ({ className, counterHeight, defaultValue, label, minValue, maxValue, setValue }: CounterProps) => {
  const [count, setCount] = useState(defaultValue);

  const handleDecrement = () => {
    if (count > minValue) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const handleIncrement = () => {
    if (count < maxValue) {
      setCount((prevCount) => prevCount + 1);
    }
  };

  useEffect(() => {
    setValue(count);
  }, [count, setValue]);

  return (
    <form className={`max-w-xs ${className ?? ''}`}>
      <label htmlFor="quantity-input" className="block mr-2 text-sm text-gray-500 font-medium text-gray-900 font-montserrat">{label ?? ''}</label>
      <div className="relative flex items-center max-w-[6rem]">
        <button
          type="button"
          id="decrement-button"
          data-testid="decrement-button"
          disabled={count === minValue}
          onClick={handleDecrement}
          style={{ height: counterHeight ? `${counterHeight}px` : '35px' }}
          className="bg-light-grey hover:bg-wildfire-orange rounded-s-lg p-3 !mr-0 focus:ring-gray-100 focus:ring-2 focus:outline-none"
        >
          <svg className="w-2 h-2 font-medium text-gray-900 hover:!text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16" />
          </svg>
        </button>
        <input
          type="text"
          id="quantity-input"
          value={count}
          readOnly
          style={{ height: counterHeight ? `${counterHeight}px` : '35px' }}
          className="bg-gray-50 border border-gray-300 font-montserrat text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full"
        />
        <button
          type="button"
          id="increment-button"
          data-testid="increment-button"
          disabled={count === maxValue}
          onClick={handleIncrement}
          style={{ height: counterHeight ? `${counterHeight}px` : '35px' }}
          className="bg-light-grey hover:bg-wildfire-orange rounded-e-lg p-3 !mr-0 focus:ring-gray-100 focus:ring-2 focus:outline-none"
        >
          <svg className="w-2 h-2 text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
          </svg>
        </button>
      </div>
    </form >
  )
};

export default Counter;
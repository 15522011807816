import { Network, NetworkState, NetworkActionTypes, GET_NETWORKS_SUCCESS } from './types';

export const defaultNetworkState: NetworkState = {
  networks: null,
};

export default (state = defaultNetworkState, action: NetworkActionTypes): NetworkState => {
  switch (action.type) {
    case GET_NETWORKS_SUCCESS: {
      const { networks } = action.payload;
      const mappedNetworks = networks.reduce(
        (obj, item) => {
          obj[item.ID] = item;
          return obj;
        },
        {} as { [id: number]: Network },
      );
      return {
        ...state,
        networks: {
          ...mappedNetworks,
        },
      };
    }
    default: {
      return state;
    }
  }
};

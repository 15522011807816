import React, { useState, useRef } from 'react';
import { Currencies } from 'constants/currencies';
import { NetworksData, NetworksList } from 'constants/networks';
import { Form, Button, Message, Ref } from 'semantic-ui-react';
import { findErrorMessage } from 'helpers';
import { convertExcelFileToCSV } from './helpers';

const CreateReconcileForm = ({ createReconcileJob }) => {
  const [formData, setFormData] = useState({
    network: NetworksList[0],
    bankref: '',
    date: '',
    currency: 'USD',
    payment_id: '',
    amount: '',
    bank_amount: '',
    force: false,
    file: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const inputWrapperRef = useRef(null);

  const isForeignCurrency = formData.currency !== 'USD';
  const networkConfig = NetworksData[formData.network];
  const networkDropdownOptions = Object.entries(NetworksData).map(([networkName, networkData]) => ({
    key: networkName,
    value: networkName,
    text: `${networkData.id} - ${networkName}`,
  }));
  const currencyDropdownOptions = Currencies.map(currency => ({
    key: currency,
    value: currency,
    text: currency,
  }));

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = e => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const resetForm = () => {
    if (inputWrapperRef.current) {
      inputWrapperRef.current.lastChild.firstChild.value = null; // clear uncontrolled file input value
    }
    setFormData({
      network: NetworksList[0],
      bankref: '',
      date: '',
      currency: 'USD',
      payment_id: '',
      amount: '',
      bank_amount: '',
      force: false,
      file: null,
    });
  };

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }
    setErrorMessage('');
    setIsLoading(true);
    try {
      const sanitizedFormData = { ...formData };
      // swap network name for ID using NetworkIdDictionary
      sanitizedFormData.id = networkConfig.id;
      delete sanitizedFormData.network;

      // convert date from YYYY-MM-DD to YYYYMMDD
      sanitizedFormData.date = sanitizedFormData.date.replaceAll('-', '');

      if (sanitizedFormData.file && networkConfig.canHaveFile) {
        if (sanitizedFormData.file.name.endsWith('.xlsx')) {
          sanitizedFormData.file = await convertExcelFileToCSV(sanitizedFormData.file);
        } else if (!sanitizedFormData.file.name.endsWith('.csv')) {
          setErrorMessage('Only .xslx or .csv files can be submitted.');
          setIsLoading(false);
          return;
        }
      } else {
        // remove file if it is not valid for selected network.
        delete sanitizedFormData.file;
      }

      if (!isForeignCurrency) {
        // remove irrelevant form properties for USD
        delete sanitizedFormData.bank_amount;
        delete sanitizedFormData.force;
      }
      try {
        const { JobID } = await createReconcileJob(sanitizedFormData);
        resetForm();
        setSubmitSuccess(true);
        setSuccessMessage(`Reconcile Job successfully created! Job ID: ${JobID}`);
        setTimeout(() => {
          setSubmitSuccess(false);
          setSuccessMessage('');
        }, 20000);
      } catch (err) {
        setSubmitSuccess(false);
        setIsLoading(false);
        setErrorMessage(findErrorMessage(err));
      }
    } catch (e) {
      setErrorMessage(
        e.error?.ErrorMessage || e.message || 'An unknown error has occurred while creating the Reconcile job.',
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      <Form error={!!errorMessage} className="create-reconcile-form p-5" autoComplete="off" onSubmit={handleSubmit}>
        <Form.Dropdown
          search
          selection
          required
          label="Network"
          name="network"
          icon={<i className="dropdown icon text-wildfire-orange" />}
          options={networkDropdownOptions}
          value={formData.network}
          onChange={handleChange}
        />
        <Form.Input onChange={handleChange} label="Payment ID" name="payment_id" value={formData.payment_id} required />
        <Form.Input onChange={handleChange} label="Bank Ref" name="bankref" value={formData.bankref} required />
        <Form.Input onChange={handleChange} label="Date" name="date" type="date" value={formData.date} required />
        <Form.Dropdown
          search
          selection
          required
          label="Currency"
          name="currency"
          icon={<i className="dropdown icon text-wildfire-orange" />}
          options={currencyDropdownOptions}
          value={formData.currency}
          onChange={handleChange}
        />
        <Form.Group widths="equal">
          <Form.Input label="Amount" name="amount" onChange={handleChange} value={formData.amount} required />
          {isForeignCurrency && (
            <Form.Input
              label="Bank Amount"
              name="bank_amount"
              onChange={handleChange}
              value={formData.bank_amount}
              required
            />
          )}
        </Form.Group>
        {isForeignCurrency && (
          <Form.Checkbox
            label="Bypass Currency Rate Check"
            name="force"
            onChange={(e, { checked }) => setFormData({ ...formData, force: checked })}
            checked={formData.force}
            toggle
          />
        )}
        {networkConfig.canHaveFile && (
          <Ref innerRef={inputWrapperRef}>
            <Form.Input
              label="File (.xslx or .csv only)"
              name="file"
              type="file"
              accept=".xlsx, .csv"
              className="!font-montserrat"
              onChange={handleFileUpload}
              required={networkConfig.fileRequired}
            />
          </Ref>
        )}
        <div className="text-center">
          <Button loading={isLoading} color="green" className="orange-rounded-button" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Submit'}
          </Button>
          <Message error color="red">
            {errorMessage}
          </Message>
          {submitSuccess && (
            <Message success style={{ display: 'inline' }}>
              {successMessage}
            </Message>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateReconcileForm;

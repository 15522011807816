import { orderBy } from 'lodash';
import { wfGet } from '../../actions';
import { SET_SEARCH_QUERY, SET_SEARCH_RESULTS, SET_SEARCH_DETAIL } from '../../constants/actionTypes';
import { getSearchQuery } from '../../selectors/merchantAdmin';

export const setSearchQuery = searchQuery => ({
  type: SET_SEARCH_QUERY,
  searchQuery,
});

export const setSearchResults = searchResults => ({
  type: SET_SEARCH_RESULTS,
  searchResults,
});

export const search = () => async (dispatch, getState) => {
  const searchQuery = getSearchQuery(getState());
  try {
    const json = await dispatch(wfGet(`/api/search?q=${encodeURIComponent(searchQuery)}`));

    const orderedData = orderBy(json, 'Disabled');

    dispatch(setSearchResults(orderedData));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setSearchDetail = searchDetail => ({
  type: SET_SEARCH_DETAIL,
  searchDetail,
});

export const searchKeywords = query => async dispatch => {
  try {
    const json = await dispatch(wfGet(`/api/search?q=${encodeURIComponent(query)}`));
    return json.filter(result => result.Type === 'keyword');
  } catch (error) {
    return Promise.reject(error);
  }
};

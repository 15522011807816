import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';

type PropsWithChildren = {
  children?: React.ReactNode;
};

const TruncatedTableCell = ({ children }: PropsWithChildren) => {
  const [isTruncated, setIsTruncated] = useState(true);

  return (
    <Table.Cell
      className={`${isTruncated ? 'max-w-[150px] truncate' : ''} cursor-pointer`}
      onClick={() => setIsTruncated(!isTruncated)}
    >
      {children}
    </Table.Cell>
  );
};

export default TruncatedTableCell;

import React, { useState } from 'react';
import { Form, Button, Segment, Accordion, Grid } from 'semantic-ui-react';
import CloudProfile from '../../components/TechSupport/CloudProfile';
import './TechSupport.css';

const TechSupport = ({ getCloudProfile }) => {
  const [deviceID, setDeviceID] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e, data) => setDeviceID(data.value);
  const handleSubmit = () => getProfile(deviceID);

  const getProfile = async deviceID => {
    try {
      const { URL } = await getCloudProfile(deviceID);
      const res = await fetch(URL);
      if (res.status !== 200) {
        setError({ statusCode: res.status });
      } else {
        const json = await res.json();
        json.URL = URL;
        setProfile(json);
      }
    } catch (error) {
      setError(error);
    }
  };

  const parseError = statusCode => {
    switch (statusCode) {
      case 412:
        return <div>This device ID wasn't found in the db.</div>;
      case 404:
        return <div>The device ID is valid but there was an error fetching the cloud profile.</div>;
      case 429:
        return <div>You've made too many requests in a short time, please try again in a minute.</div>;
      default:
        return <div>An unknown error has occurred.</div>;
    }
  };

  return (
    <div className="tech-support">
      <h1>Welcome to the tech support tool!</h1>
      <Grid columns="equal">
        <Grid.Column width="five">
          <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input placeholder="Enter a device id..." onChange={handleChange}></Form.Input>
              <Button type="submit">Submit</Button>
            </Form.Group>
          </Form>
        </Grid.Column>
        <Grid.Column>
          {error && parseError(error.statusCode)}
          {profile && (
            <>
              <h2>Device ID: {deviceID}</h2>
              <Segment>
                <Accordion>
                  <Accordion.Title>
                    <h3 onClick={() => setIsActive(!isActive)}>Cloud Profile</h3>
                  </Accordion.Title>
                  <Accordion.Content active={isActive}>
                    <CloudProfile profile={profile} />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default TechSupport;

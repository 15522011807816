import React from 'react';
import { Loader } from 'semantic-ui-react';

import './FloatingLoadingOverlay.css';

const FloatingLoadingOverlay = () => {
  return (
    <div className="floating-loader-wrapper">
      <Loader active size="small" inline="centered" />
    </div>
  );
};

export default FloatingLoadingOverlay;

export const HOOK = {
  FETCH_INIT: 'FETCH_INIT',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

export const CHROME = {
  WF_EXTENSION_ID: 'ipldncgiiglfcmcookhlkeicoinpkdni',
  WFT_EXTENSION_ID: 'apheicfblaeehfncnbojcboangjnmgnh',
  DQX_EXTENSION_ID: 'dbhkeffaocgkiacedneclojfggdkbidk',
  PING: 'PING',
  SUCCESS: 'SUCCESS',
  SET_COUPON_TEST_DATA: 'SET_COUPON_TEST_DATA',
  GET_TARGETS_IN_QUEUE: 'GET_TARGETS_IN_QUEUE',
  COMPLETE_TARGET_IN_QUEUE: 'COMPLETE_TARGET_IN_QUEUE',
  SEND_ACTIVE_DOMAINS: 'SEND_ACTIVE_DOMAINS',
};

export const AUTH0_NAMESPACE = 'https://www.wildlink.me';

export const SENTRY_DSN = 'https://39faf60cae51f83349554600bbaa061f@o170013.ingest.sentry.io/4506616558845952';

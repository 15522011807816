import { SET_AUTH0_TOKEN, SET_DEVICE_TOKEN, SET_SENDER_TOKEN, SET_CAN_EDIT } from '../constants/actionTypes';

export const defaultUser = () => {
  // new for the networkMerchant test link
  // TODO: move this into sagas
  const deviceId = localStorage.getItem('deviceId');

  if (!deviceId) {
    localStorage.removeItem('senderToken');
    localStorage.removeItem('deviceKey');
    localStorage.removeItem('deviceId');
  }

  return {
    deviceToken: localStorage.getItem('deviceToken') || '',
    senderToken: localStorage.getItem('senderToken') || '',
    canEdit: false,
  };
};

export default (state = defaultUser(), action) => {
  switch (action.type) {
    case SET_DEVICE_TOKEN:
      return {
        ...state,
        deviceToken: action.deviceToken,
      };
    case SET_AUTH0_TOKEN:
      return {
        ...state,
        auth0Token: action.auth0Token,
      };
    case SET_SENDER_TOKEN:
      return {
        ...state,
        senderToken: action.senderToken,
      };
    case SET_CAN_EDIT:
      return {
        ...state,
        canEdit: action.canEdit,
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { addFraudCommission, getFraudCommissions } from 'actions/fraudCommissions/fraudCommissions';
import { FraudCommission } from 'pages/FraudCommissions/types';
import FraudCommissionsTable from 'components/FraudCommissions/FraudCommissionsTable';
import FraudCommissionsForm from 'components/FraudCommissions/FraudCommissionsForm';

interface FraudCommissionsProps {
  getFraudCommissions: (args: { sortBy: string; sortOrder: string }) => Promise<FraudCommission[]>;
  addFraudCommission: (commissionId: number[]) => Promise<FraudCommission>;
}

export const FraudCommissions = ({ getFraudCommissions, addFraudCommission }: FraudCommissionsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [tableError, setTableError] = useState('');
  const [formError, setFormError] = useState('');

  const [fraudCommissions, setFraudCommissions] = useState<FraudCommission[]>([]);
  const [commissionId, setCommissionId] = useState<string>('');

  const fetchFraudCommissions = async () => {
    setTableError('');
    try {
      setIsLoading(true);
      const response = await getFraudCommissions({
        sortBy: 'marked_as_fraud_on',
        sortOrder: 'desc',
      });
      setFraudCommissions(response);
    } catch (error) {
      toast.error('Failed to get fraud commissions.');
      console.error(error);
      setTableError('Unknown error has occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const createFraudCommission = async (commissionId: string) => {
    try {
      setIsAdding(true);
      // Format the commissionId to array of commissionIds
      const formattedCommissionId = commissionId
        .split(',')
        .map(v => v.trim())
        .filter(v => v !== '')
        .map(v => Number(v));
      const newFraudCommission = await addFraudCommission(formattedCommissionId);
      toast.success('Successfully added fraud commission.');
      if (newFraudCommission) {
        await fetchFraudCommissions();
      }
    } catch (error) {
      console.error(error);
      setFormError('Please check to make sure all IDs are valid commission IDs and try again.');
    } finally {
      setIsAdding(false);
      setCommissionId('');
    }
  };

  useEffect(() => {
    fetchFraudCommissions();
  }, [getFraudCommissions]);

  return (
    <div className="fraud-commissions w-full flex justify-content flex-wrap">
      <h1 className="font-title text-center w-full mb-8">Fraud Commissions</h1>
      <FraudCommissionsForm
        commissionId={commissionId}
        isLoading={isAdding}
        error={formError}
        setCommissionId={setCommissionId}
        setFormError={setFormError}
        createFraudCommission={createFraudCommission}
      />
      <FraudCommissionsTable fraudCommissions={fraudCommissions} isLoading={isLoading} error={tableError} />
    </div>
  );
};

const mapActionsToProps = {
  getFraudCommissions,
  addFraudCommission,
};

export default connect(null, mapActionsToProps)(FraudCommissions);

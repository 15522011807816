import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import CommissionsRow from './CommissionsRow';

const CommissionsTable = ({ commissions, handleUpdateCommission, viewOnly }) => {
  const canEdit = useSelector(state => state.user.canEdit);
  const isViewOnly = viewOnly ?? !canEdit;
  // To prevent random re-organization of items in list when toggling on and off

  let disabledItems = commissions.filter(item => item.Disabled === true);
  let activeItems = commissions.filter(item => item.Disabled === false);

  disabledItems.sort((a, b) => {
    return a.ID - b.ID;
  });
  activeItems.sort((a, b) => {
    return a.ID - b.ID;
  });

  const newSortedCommissions = activeItems.concat(disabledItems);

  return (
    <Table singleLine celled compact striped className="commissions-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Kind</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Display Name</Table.HeaderCell>
          <Table.HeaderCell>Is Default</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {newSortedCommissions.map(c => (
          <CommissionsRow
            key={c.ID}
            commission={c}
            handleUpdateCommission={handleUpdateCommission}
            viewOnly={isViewOnly}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default CommissionsTable;

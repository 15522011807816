import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Segment, Icon, Input as InputUI, Button, Dropdown, Checkbox } from 'semantic-ui-react';
import { createSemanticUIOption } from 'helpers';
import './Target.css';

export const Target = ({
  target,
  isLoading,
  isValidSelector,
  isValidTarget,
  updateKeywordTarget,
  doesUserHaveExtensionInstalled,
  wasTested,
  isDisabled,
  sendCouponDataToExtension,
  viewOnly,
}) => {
  const [nextTarget, setNextTarget] = useState({ ...target });
  const [isFocused, setFocused] = useState(false);
  const [showTimeouts, setShowTimeouts] = useState(false);

  const canEdit = useSelector(state => state.user.canEdit);
  const isViewOnly = viewOnly ?? !canEdit;
  const {
    ID,
    Input,
    Price,
    Submit,
    Remove,
    Timeout,
    Error: ErrorValue,
    Before,
    CCType,
    CCNumber,
    CCCSC,
    CCName,
    CCGivenName,
    CCAdditionalName,
    CCFamilyName,
    CCExp,
    CCExpMonth,
    CCExpYear,
    AttemptTimeout,
    Attempts,
    CheckoutURLs,
    ConfirmURLs,
    StartingIntoStartApplyingTimeout,
    StartApplyingIntoRemoveApplyingTimeout,
    StartApplyingIntoBeforeApplyingTimeout,
    BeforeApplyingSleepTimeout,
    StartApplyingIntoInputApplyingTimeout,
    RemoveApplyingTimeout,
    RemoveApplyingIntoBeforeApplyingTimeout,
    RemoveApplyingIntoInputApplyingTimeout,
    InputApplyingSleepTimeout,
    SubmitApplyingSleepTimeout,
    FinishApplyingSleepTimeout,
    GoingToNextIntoApplyingBestTimeout,
  } = nextTarget;

  const handleChange = (_, { name, value }) => {
    setNextTarget(prev => ({ ...prev, [name]: value }));
  };

  const handleDropdownChange = (name, value) => {
    setNextTarget(prev => ({ ...prev, [name]: value }));
  };

  const handleDisable = () => {
    setNextTarget(prev => {
      const newTarget = { ...prev, Disabled: !prev.Disabled };
      if (isValidTarget(newTarget)) {
        updateKeywordTarget(newTarget.ID, newTarget);
      }
      return newTarget;
    });
  };

  useEffect(() => {
    if (!isFocused && !_.isEqual(target, nextTarget)) {
      if (isValidTarget(nextTarget)) {
        updateKeywordTarget(nextTarget.ID, nextTarget);
      } else {
        setNextTarget({ ...target });
      }
    }
  }, [isFocused, target, nextTarget, isValidTarget, updateKeywordTarget]);

  useEffect(() => {
    setNextTarget(prev => ({ ...prev, Disabled: isDisabled }));
  }, [isDisabled]);

  return (
    <Segment.Group>
      <Segment>
        <div className="flex justify-between items-center mb-2">
          <h3>
            <Icon name="target" />
            Keyword Target (ID: {ID})
          </h3>
          <Checkbox
            label="Show Timeout Fields"
            checked={showTimeouts}
            onChange={() => setShowTimeouts(!showTimeouts)}
          />
        </div>
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Input'}
          name={'Input'}
          value={Input}
          error={!isValidSelector(Input) || !Input}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Price'}
          name={'Price'}
          value={Price}
          error={!isValidSelector(Price) || !Price}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Submit'}
          name={'Submit'}
          value={Submit}
          error={!isValidSelector(Submit)}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Remove'}
          name={'Remove'}
          value={Remove}
          error={!isValidSelector(Remove)}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Before'}
          name={'Before'}
          value={Before}
          error={!isValidSelector(Before)}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Timeout'}
          name={'Timeout'}
          type="number"
          error={Number(Timeout) < 0}
          value={Timeout}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Error'}
          name={'Error'}
          value={ErrorValue}
          error={!isValidSelector(ErrorValue)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Type'}
          name={'CCType'}
          value={CCType}
          error={!isValidSelector(CCType)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Number'}
          name={'CCNumber'}
          value={CCNumber}
          error={!isValidSelector(CCNumber)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card CVV'}
          name={'CCCSC'}
          value={CCCSC}
          error={!isValidSelector(CCCSC)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={"Credit Card Holder's Name"}
          name={'CCName'}
          value={CCName}
          error={!isValidSelector(CCName)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card First Name'}
          name={'CCGivenName'}
          value={CCGivenName}
          error={!isValidSelector(CCGivenName)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Middle Name'}
          name={'CCAdditionalName'}
          value={CCAdditionalName}
          error={!isValidSelector(CCAdditionalName)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Last Name'}
          name={'CCFamilyName'}
          value={CCFamilyName}
          error={!isValidSelector(CCFamilyName)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Exp Date'}
          name={'CCExp'}
          value={CCExp}
          error={!isValidSelector(CCExp)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Exp Month'}
          name={'CCExpMonth'}
          value={CCExpMonth}
          error={!isValidSelector(CCExpMonth)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Exp Year'}
          name={'CCExpYear'}
          value={CCExpYear}
          error={!isValidSelector(CCExpYear)}
        />
        <InputUI
          fluid
          disabled={true}
          label={'Disabled'}
          name={'Disabled'}
          value={isDisabled.toString()}
          error={!isValidSelector(isDisabled)}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Attempts'}
          name={'Attempts'}
          type="number"
          error={Number(Attempts) < 0}
          value={Attempts}
        />
        {showTimeouts && (
          <>
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Attempt Timeout'}
              name={'Attempt Timeout'}
              type="number"
              error={Number(AttemptTimeout) < 0}
              value={AttemptTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Starting Into Start Applying Timeout'}
              name={'StartingIntoStartApplyingTimeout'}
              type="number"
              error={Number(StartingIntoStartApplyingTimeout) < 0}
              value={StartingIntoStartApplyingTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Start Applying Into Remove Applying Timeout'}
              name={'StartApplyingIntoRemoveApplyingTimeout'}
              type="number"
              error={Number(StartApplyingIntoRemoveApplyingTimeout) < 0}
              value={StartApplyingIntoRemoveApplyingTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Start Applying Into Before Applying Timeout'}
              name={'StartApplyingIntoBeforeApplyingTimeout'}
              type="number"
              error={Number(StartApplyingIntoBeforeApplyingTimeout) < 0}
              value={StartApplyingIntoBeforeApplyingTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Before Applying Sleep Timeout'}
              name={'BeforeApplyingSleepTimeout'}
              type="number"
              error={Number(BeforeApplyingSleepTimeout) < 0}
              value={BeforeApplyingSleepTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Start Applying Into Input Applying Timeout'}
              name={'StartApplyingIntoInputApplyingTimeout'}
              type="number"
              error={Number(StartApplyingIntoInputApplyingTimeout) < 0}
              value={StartApplyingIntoInputApplyingTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Remove Applying Timeout'}
              name={'RemoveApplyingTimeout'}
              type="number"
              error={Number(RemoveApplyingTimeout) < 0}
              value={RemoveApplyingTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Remove Applying Into Before Applying Timeout'}
              name={'RemoveApplyingIntoBeforeApplyingTimeout'}
              type="number"
              error={Number(RemoveApplyingIntoBeforeApplyingTimeout) < 0}
              value={RemoveApplyingIntoBeforeApplyingTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Remove Applying Into Input Applying Timeout'}
              name={'RemoveApplyingIntoInputApplyingTimeout'}
              type="number"
              error={Number(RemoveApplyingIntoInputApplyingTimeout) < 0}
              value={RemoveApplyingIntoInputApplyingTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Input Applying Sleep Timeout'}
              name={'InputApplyingSleepTimeout'}
              type="number"
              error={Number(InputApplyingSleepTimeout) < 0}
              value={InputApplyingSleepTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Submit Applying Sleep Timeout'}
              name={'SubmitApplyingSleepTimeout'}
              type="number"
              error={Number(SubmitApplyingSleepTimeout) < 0}
              value={SubmitApplyingSleepTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Finish Applying Sleep Timeout'}
              name={'FinishApplyingSleepTimeout'}
              type="number"
              error={Number(FinishApplyingSleepTimeout) < 0}
              value={FinishApplyingSleepTimeout}
            />
            <InputUI
              fluid
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={isLoading || isViewOnly}
              onChange={handleChange}
              label={'Going To Next Into Applying Best Timeout'}
              name={'GoingToNextIntoApplyingBestTimeout'}
              type="number"
              error={Number(GoingToNextIntoApplyingBestTimeout) < 0}
              value={GoingToNextIntoApplyingBestTimeout}
            />
          </>
        )}
        <div className="dropdown-container">
          <label className="dropdown-label">Checkout URLs</label>
          <Dropdown
            className="dropdown-unrounded-left"
            name="CheckoutURLs"
            options={CheckoutURLs.map(createSemanticUIOption)}
            noResultsMessage={null}
            value={CheckoutURLs}
            onChange={(_, data) => handleDropdownChange('CheckoutURLs', data.value)}
            disabled={!canEdit}
            fluid
            multiple
            selection
            allowAdditions
            search
          />
        </div>
        <div className="dropdown-container">
          <label className="dropdown-label">Confirm URLs</label>
          <Dropdown
            className="dropdown-unrounded-left"
            name="ConfirmURLs"
            options={ConfirmURLs.map(createSemanticUIOption)}
            noResultsMessage={null}
            value={ConfirmURLs}
            onChange={(_, data) => handleDropdownChange('ConfirmURLs', data.value)}
            disabled={!canEdit}
            fluid
            multiple
            selection
            allowAdditions
            search
          />
        </div>
        {!isViewOnly && (
          <div className="flex-right">
            <Button
              disabled={isLoading || !isValidTarget(nextTarget)}
              color={isDisabled ? 'red' : 'green'}
              onClick={handleDisable}
            >
              {isDisabled ? 'Disabled' : 'Enabled'}
            </Button>
            <Button
              onClick={() => sendCouponDataToExtension(target)}
              disabled={!doesUserHaveExtensionInstalled || isLoading}
              color={wasTested ? 'green' : 'blue'}
              title={
                doesUserHaveExtensionInstalled
                  ? 'This will send the target data and some test coupon codes to your extension'
                  : 'This option is not currently available, we have not detected that you have a browser extension installed that can support our coupon testing data'
              }
            >
              {wasTested ? <Icon name="check" className="m-0" /> : 'Test'}
            </Button>
          </div>
        )}
      </Segment>
    </Segment.Group>
  );
};

export const Currencies = [
  'AED',
  'AUD',
  'CAD',
  'CHF',
  'DKK',
  'INR',
  'EUR',
  'GBP',
  'JPY',
  'NOK',
  'NZD',
  'PLN',
  'RUB',
  'SEK',
  'UAH',
  'USD',
];

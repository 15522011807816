import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { getNetworks } from 'actions/networkMerchantOnboarding/networkMerchantOnboarding';
import {
  getCommissionRateQueue,
  approveCommissionRate,
  reviseCommissionRate,
} from '../../actions/commissionRateQueue/commissionRateQueue';
import { updateNetworkMerchantCommission } from '../../actions/merchantAdmin/networkMerchant';
import CommissionRateQueueTable from '../../components/CommissionRateQueue/CommissionRateQueueTable';
import { CommissionRateQueueItem } from '../../components/CommissionRateQueue/types';

export interface Network {
  ID: number;
  Name: string;
  AffiliateCode: string;
  Disabled: boolean;
  NetworkPublisherID: number;
  TemplateURL: string;
}

interface CommissionRateQueueProps {
  getCommissionRateQueue: () => Promise<any>;
  approveCommissionRate: (item: CommissionRateQueueItem) => Promise<any>;
  reviseCommissionRate: (item: CommissionRateQueueItem) => Promise<any>;
  getNetworks: () => Promise<Network[]>;
}

const CommissionRateQueue: React.FC<CommissionRateQueueProps> = ({
  getCommissionRateQueue,
  approveCommissionRate,
  reviseCommissionRate,
  getNetworks,
}) => {
  const [queueData, setQueueData] = useState<CommissionRateQueueItem[]>([]);
  const [networks, setNetworks] = useState<Network[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchCommissionRateQueue = async () => {
    try {
      setIsLoading(true);
      const response = await getCommissionRateQueue();
      setQueueData(response);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNetworks = async () => {
    try {
      const res = await getNetworks();
      setNetworks(res);
    } catch (err) {
      toast.error('There was an error while fetching networks, please try again later.');
      console.error('Error fetching networks:', err);
    }
  };

  const getUserEmail = () => {
    const userEmail = localStorage.getItem('userEmail');
    if (!userEmail) {
      toast.error('User email not found in localStorage');
      return null;
    }
    return userEmail;
  };

  const handleApprove = async (rateData: CommissionRateQueueItem) => {
    const userEmail = getUserEmail();
    if (!userEmail) return;

    try {
      await approveCommissionRate({ ...rateData, reviewer: userEmail });
      await fetchCommissionRateQueue();
    } catch (err) {
      console.error('Error approving commission rate:', err);
    }
  };

  const handleRevise = async (rateData: CommissionRateQueueItem) => {
    const userEmail = getUserEmail();
    if (!userEmail) return;

    try {
      await reviseCommissionRate({ ...rateData, reviewer: userEmail });
      await fetchCommissionRateQueue();
    } catch (err) {
      console.error('Error revising commission rate:', err);
    }
  };

  useEffect(() => {
    fetchCommissionRateQueue();
    fetchNetworks();
    // only run once on first load. commissions are refetched elsewhere when necessary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Segment className="border-0 shadow-none mt-[50px]">
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="commission-rate-queue max-w-[95vw]">
      <h1 className="font-title my-2 text-center">Commission Rate Queue</h1>
      <CommissionRateQueueTable
        networks={networks}
        data={queueData}
        onApprove={handleApprove}
        onRevise={handleRevise}
      />
    </div>
  );
};

const mapDispatchToProps = {
  getNetworks,
  getCommissionRateQueue,
  approveCommissionRate,
  reviseCommissionRate,
  updateNetworkMerchantCommission,
};

export default connect(null, mapDispatchToProps)(CommissionRateQueue);

import { TOGGLE_FEATURE_FLAG } from '../../constants/actionTypes';
import { allFlags } from '../../constants/allFlags';

const initialState = initialFeatureFlagState();

function featureFlagReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FEATURE_FLAG:
      const { featureFlag } = action.payload;
      // Clone the state to avoid direct mutations
      const newState = { ...state, features: { ...state.features } };
      const isCurrentlyEnabled = newState.features[featureFlag].enabled;
      newState.features[featureFlag].enabled = !isCurrentlyEnabled;
      // Persist the updated features to localStorage
      localStorage.setItem('feature_flags', JSON.stringify(newState.features));
      return newState;
    default:
      return state;
  }
}

export function initialFeatureFlagState() {
  const allFlagsCopy = JSON.parse(JSON.stringify(allFlags));
  const savedFlagsJSON = localStorage.getItem('feature_flags');

  if (savedFlagsJSON) {
    const savedFlags = JSON.parse(savedFlagsJSON);
    // This loop updates feature flags to their most recently saved state for this user
    // without carrying over any old feature flags that have been removed
    for (const key in savedFlags) {
      if (allFlagsCopy[key]) {
        allFlagsCopy[key] = savedFlags[key];
      }
    }
  }
  localStorage.setItem('feature_flags', JSON.stringify(allFlagsCopy));
  return {
    features: allFlagsCopy,
  };
}

export default featureFlagReducer;

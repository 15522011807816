import React from 'react';
import { Table, Checkbox } from 'semantic-ui-react';

const CategoryTable = ({ searchValue, action, categories, handleCheck, disabled }) => {
  return (
    <div className="table-wrapper">
      <Table striped>
        <Table.Body>
          {categories.map(c => (
            <Table.Row
              style={{ display: `${c.Name.toLowerCase().includes(searchValue) ? 'table-row' : 'none'}` }}
              key={c.ID}
            >
              <Table.Cell>
                <Checkbox onChange={handleCheck} id={c.ID} label={c.Name} action={action} disabled={disabled} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default CategoryTable;

import { useState, useEffect, useReducer } from 'react';
import { HOOK } from '../helpers/constants';

const fetchReducer = (state, action) => {
  switch (action.type) {
    case HOOK.FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        error: {},
      };
    case HOOK.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        error: {},
        data: action.payload,
      };
    case HOOK.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      };
    default:
      throw new Error();
  }
};

export default (apiFn, initialData, ...apiArgs) => {
  const [retryCounter, setRetryCounter] = useState(0);

  const [state, dispatch] = useReducer(fetchReducer, {
    isLoading: true,
    hasError: false,
    data: initialData,
    error: {},
  });

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      dispatch({ type: HOOK.FETCH_INIT });

      try {
        const response = await apiFn(...apiArgs);
        if (!ignore && response.ok) {
          const data = await response.json();
          dispatch({ type: HOOK.FETCH_SUCCESS, payload: data });
        }
      } catch (error) {
        if (!ignore) {
          dispatch({ type: HOOK.FETCH_FAILURE, payload: error });
        }
      }
    };

    fetchData();

    return () => (ignore = true);
  }, [retryCounter, apiFn, apiArgs]);

  const reFetch = () => setRetryCounter(retryCounter + 1);

  return { ...state, reFetch };
};

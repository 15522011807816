import { connect } from 'react-redux';
import { getAgingReport } from 'actions/agingReport/agingReport';
import { getFilterOptions } from 'actions/salesDashboard/dashboardStats';
import AgingReport from './AgingReport';

const mapActionsToProps = {
  getAgingReport,
  getFilterOptions,
};

export default connect(null, mapActionsToProps)(AgingReport);

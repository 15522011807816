import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Loader } from 'semantic-ui-react';

import './Lock.css';

class Lock extends Component {
  state = {
    isLoading: false,
    hasError: false,
  };

  toggleLock = async e => {
    e.stopPropagation();
    const { commission, locks, handleResourceChange, name } = this.props;
    this.setState({ isLoading: true });
    // shallow copy
    const newLocks = locks.slice();
    const disabledIndex = newLocks.indexOf(name);
    if (disabledIndex > -1) {
      newLocks.splice(disabledIndex, 1);
    } else {
      newLocks.push(name);
    }
    const change = commission ? { ...commission, Locks: newLocks } : { Locks: newLocks };
    try {
      await handleResourceChange(change);
    } catch (error) {
      this.setState({
        isLoading: false,
        hasError: true,
      });
      console.error(error);
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { isLoading } = this.state;
    const { locks, name, disabled } = this.props;
    const isLocked = locks.indexOf(name) > -1;

    return (
      <div className="lock">
        {isLoading ? (
          <Loader active size="tiny" inline="centered" />
        ) : (
          <Icon name={isLocked ? 'lock' : 'lock open'} onClick={disabled ? null : this.toggleLock} />
        )}
      </div>
    );
  }
}

Lock.propTypes = {
  locks: PropTypes.array.isRequired,
  handleResourceChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default Lock;

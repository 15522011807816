import React from 'react';
import { Button } from 'semantic-ui-react';

const StatsTableDownload = ({
  tableHeaders,
  tableData,
  tableLabel,
  startDate,
  isNonTimeGroupedView,
  endDate,
  getDate,
  nameFromStorage,
}) => {
  const downloadCSV = async () => {
    const csvRows = [];
    const csvHeader = [];
    const columns = Object.keys(tableData[0]);
    columns.forEach(column => {
      tableHeaders.forEach(i => {
        if (i.key === column) {
          column = i.text;
          if (i.unit !== 'count') {
            column += ` (${i.unit})`;
          }
        }
      });
      csvHeader.push(column);
    });

    if (isNonTimeGroupedView) {
      csvHeader.splice(1, 0, 'Name');
    }
    csvRows.push(csvHeader);

    tableData.forEach(row => {
      const formattedRow = [];
      const values = Object.values(row);
      values.forEach((value, index) => {
        if (typeof value === 'number') {
          if (value.toString().split('.').length > 1) {
            formattedRow.push(value.toFixed(2));
          } else {
            formattedRow.push(value);
          }
        } else if (value.includes('T00:00:00Z')) {
          formattedRow.push(value.replace('T00:00:00Z', ''));
        } else {
          formattedRow.push(value);
        }
        if (index === 0 && isNonTimeGroupedView) {
          formattedRow.push(nameFromStorage(tableLabel, value));
        }
      });
      csvRows.push(formattedRow);
    });
    const csv = csvRows.map(row => row.join(',')).join('\n');

    let fileName;
    if (startDate === '10daysago') {
      fileName = getDate(-10);
    } else {
      fileName = startDate;
    }
    fileName += '-' + endDate + '-stats-by-' + tableLabel;

    const blob = new Blob([csv], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
  };

  return (
    <div className="stats-table-download-button-container">
      <Button onClick={downloadCSV}>Download Table</Button>
    </div>
  );
};

export default StatsTableDownload;

import { transform, isEqual, isArray, isObject, isEmpty } from 'lodash';
import { toPrettyTime } from './index';

export const buildCommissionHistoryLogMessage = (newerState, olderState, time, modifiedAuthor) => {
  const diffs = Object.keys(commissionHistoryDiff(olderState, newerState));
  let commissionHistoryLogMessage = `${toPrettyTime(time, true)} : ${modifiedAuthor}, Commission ID ${newerState.ID} `;
  const commissionHistoryChangesArray = [];

  if (diffs.length === 0) {
    return null;
  }

  diffs.forEach(diff => {
    //TODO - Sarah mentioned an existing currency history bug that should be fixed in regards to the below conditional
    //I include currency in this conditional because commissions and commissionHistory rowdata have different properties
    //commissions have a currency property, and commission history does not, so a diff will always occur
    if (isEmpty(diff) || diff === 'Currency' || diff === 'ModifiedAuthor') {
      return null;
    }

    if (olderState[diff] === '') {
      commissionHistoryChangesArray.push(`set the ${diff} to "${newerState[diff]}"`);
    } else if (newerState[diff] === '' && olderState[diff] !== '') {
      commissionHistoryChangesArray.push(`removed the value of ${diff} ("${olderState[diff]}")`);
    } else {
      commissionHistoryChangesArray.push(`changed ${diff} from "${olderState[diff]}" to "${newerState[diff]}"`);
    }
  });
  commissionHistoryLogMessage += commissionHistoryChangesArray.join(', ');
  return { time, commissionHistoryLogMessage };
};

export const commissionHistoryDiff = (origObj, newObj) => {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0;
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value;
      }
    });
  }
  return changes(newObj, origObj);
};

import React from 'react';
import { useParams } from 'react-router-dom';
import JobItems from 'components/Reconciliation/ReconciliationJobItems';

const ReconciliationJobItems = ({ getReconcileJobItems }) => {
  const { id, mid } = useParams();
  return (
    <div className="reconciliation-merchant-details max-w-[1350px] flex flex-wrap font-montserrat">
      <JobItems id={id} mid={mid} getReconcileJobItems={getReconcileJobItems} />
    </div>
  );
};

export default ReconciliationJobItems;

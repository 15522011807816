import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SET_AUTH0_TOKEN } from 'constants/actionTypes';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { encryptValue } from 'helpers/encrypt';
import './Auth.css';

const Auth = () => {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();

  const state = history?.location?.state;

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getAccessTokenSilently()
        .then(accessToken => {
          dispatch({
            type: SET_AUTH0_TOKEN,
            auth0Token: accessToken,
          });
          const previousPage = localStorage.getItem('previousPage');

          localStorage.setItem('encryptedId', encryptValue(user.email));
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('userEmail', user.email);

          history.push(previousPage || '/');

          if (previousPage) {
            localStorage.removeItem('previousPage');
          }
        })
        .catch(() => {
          console.warn('Failed to get Auth0 access token');
        });
    } else {
      if (state?.from && state.from !== '/auth') {
        localStorage.setItem('previousPage', state.from);
      }

      loginWithRedirect();
    }
  }, [history, isAuthenticated, isLoading, dispatch, getAccessTokenSilently, loginWithRedirect, state, user]);

  return <LoadingOverlay isLoading />;
};

export default Auth;

import React from 'react';
import { useParams } from 'react-router-dom';
import Job from 'components/Reconciliation/ReconciliationJob';

const ReconciliationJob = ({ getReconcileJob, getReconcileJobMerchants, retryReconcileJob }) => {
  const { id } = useParams();
  return (
    <div className="reconciliation-details max-w-[1350px] flex flex-wrap font-montserrat">
      <Job
        id={id}
        getReconcileJob={getReconcileJob}
        getReconcileJobMerchants={getReconcileJobMerchants}
        retryReconcileJob={retryReconcileJob}
      />
    </div>
  );
};

export default ReconciliationJob;

import React from 'react';
import { Table } from 'semantic-ui-react';
import { TableHeader } from './CommissionSearchTable';
import TruncatedTableCell from './TruncatedTableCell';

interface CommissionSearchRow {
  commission: any;
  headers: TableHeader[];
}

const CommissionSearchRow = ({ commission, headers }: CommissionSearchRow) => {
  const renderCell = (headerKey: string) => {
    switch (headerKey) {
      case 'SaleAmount': {
        const { Amount: amount, Currency: currency } = commission[headerKey];
        return <Table.Cell>{`${amount} ${currency}`}</Table.Cell>;
      }
      case 'Amounts': {
        return (
          <Table.Cell>
            {commission[headerKey].map((amount: { SplitPart: string; Currency: string; Amount: string }) => {
              const { SplitPart, Currency, Amount } = amount;
              const splitPartString = SplitPart[0] + SplitPart.slice(1).toLowerCase();
              return <p>{`${splitPartString}: ${parseFloat(Amount).toFixed(2)} ${Currency}`}</p>;
            })}
          </Table.Cell>
        );
      }
      case 'EventDate':
      case 'LockingDate':
      case 'CreatedDate':
      case 'ModifiedDate': {
        return <Table.Cell>{commission[headerKey].split('T')[0]}</Table.Cell>;
      }
      case 'MerchantSKU':
      case 'TrackingCode':
      case 'MerchantOrderID': {
        return <TruncatedTableCell>{commission[headerKey]}</TruncatedTableCell>;
      }
      default: {
        return <Table.Cell>{commission[headerKey]}</Table.Cell>;
      }
    }
  };

  return <Table.Row>{headers.map((header: TableHeader) => renderCell(header.key))}</Table.Row>;
};

export default CommissionSearchRow;

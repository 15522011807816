import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';

const LoadingOverlay = ({ isLoading }) =>
  isLoading ? (
    <Dimmer active inverted>
      <Loader>Loading</Loader>
    </Dimmer>
  ) : null;

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default LoadingOverlay;

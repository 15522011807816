export const CountryCodes = [
  { key: 8, value: 'AL', text: '8 - AL - Albania' },
  { key: 12, value: 'DZ', text: '12 - DZ - Algeria' },
  { key: 16, value: 'AS', text: '16 - AS - American Samoa' },
  { key: 20, value: 'AD', text: '20 - AD - Andorra' },
  { key: 24, value: 'AO', text: '24 - AO - Angola' },
  { key: 660, value: 'AI', text: '660 - AI - Anguilla' },
  { key: 10, value: 'AQ', text: '10 - AQ - Antarctica' },
  { key: 28, value: 'AG', text: '28 - AG - Antigua and Barbuda' },
  { key: 32, value: 'AR', text: '32 - AR - Argentina' },
  { key: 51, value: 'AM', text: '51 - AM - Armenia' },
  { key: 533, value: 'AW', text: '533 - AW - Aruba' },
  { key: 36, value: 'AU', text: '36 - AU - Australia' },
  { key: 40, value: 'AT', text: '40 - AT - Austria' },
  { key: 31, value: 'AZ', text: '31 - AZ - Azerbaijan' },
  { key: 44, value: 'BS', text: '44 - BS - Bahamas' },
  { key: 48, value: 'BH', text: '48 - BH - Bahrain' },
  { key: 50, value: 'BD', text: '50 - BD - Bangladesh' },
  { key: 52, value: 'BB', text: '52 - BB -Barbados' },
  { key: 112, value: 'BY', text: '112 - BY - Belarus' },
  { key: 56, value: 'BE', text: '56 - BE - Belgium' },
  { key: 84, value: 'BZ', text: '84 - BZ - Belize' },
  { key: 204, value: 'BJ', text: '204 - BJ - Benin' },
  { key: 60, value: 'BM', text: '60 - BM - Bermuda' },
  { key: 64, value: 'BT', text: '64 - BT - Bhutan' },
  { key: 68, value: 'BO', text: '68 - BO - Bolivia' },
  { key: 70, value: 'BA', text: '70 - BA - Bosnia and Herzegovina' },
  { key: 72, value: 'BW', text: '72 - BW - Botswana' },
  { key: 74, value: 'BV', text: '74 - BV Bouvet Island' },
  { key: 76, value: 'BR', text: '76 - BR - Brazil' },
  { key: 86, value: 'IO', text: '86 - IO - British Indian Ocean Territory' },
  { key: 96, value: 'BN', text: '96 - BN - Brunei Darussalam' },
  { key: 100, value: 'BG', text: '100 - BG - Bulgaria' },
  { key: 854, value: 'BF', text: '854 - BF - Burkina Faso' },
  { key: 108, value: 'BI', text: '108 - BI - Burundi' },
  { key: 116, value: 'KH', text: '116 - KH - Cambodia' },
  { key: 120, value: 'CM', text: '120 - CM - Cameroon' },
  { key: 124, value: 'CA', text: '124 - CA - Canada' },
  { key: 132, value: 'CV', text: '132 - CV - Cape Verde' },
  { key: 136, value: 'KY', text: '136 - KY - Cayman Islands' },
  { key: 140, value: 'CF', text: '140 - CF - Central African Republic' },
  { key: 148, value: 'TD', text: '148 - TD - Chad' },
  { key: 152, value: 'CL', text: '152 - CL - Chile' },
  { key: 156, value: 'CN', text: '156 - CN - China' },
  { key: 162, value: 'CX', text: '162 - CX - Christmas Island' },
  { key: 166, value: 'CC', text: '166 - CC - Cocos (Keeling) Islands' },
  { key: 170, value: 'CO', text: '170 - CO - Colombia' },
  { key: 174, value: 'KM', text: '174 - KM - Comoros' },
  { key: 178, value: 'CG', text: '178 - CG - Congo' },
  { key: 180, value: 'CD', text: '180 - CD - Democratic Republic of the Congo' },
  { key: 184, value: 'CK', text: '184 - CK - Cook Islands' },
  { key: 188, value: 'CR', text: '188 - CR - Costa Rica' },
  { key: 384, value: 'CI', text: `384 - CI - Côte d'Ivoire` },
  { key: 191, value: 'HR', text: '191 - HR - Croatia' },
  { key: 192, value: 'CU', text: '192 - CU - Cuba' },
  { key: 196, value: 'CY', text: '196 - CY - Cyprus' },
  { key: 203, value: 'CZ', text: '203 - CZ - Czech Republic' },
  { key: 208, value: 'DK', text: '208 - DK - Denmark' },
  { key: 262, value: 'DJ', text: '262 - DJ - Djibouti' },
  { key: 212, value: 'DM', text: '212 - DM - Dominica' },
  { key: 214, value: 'DO', text: '214 - DO - Dominican Republic' },
  { key: 218, value: 'EC', text: '218 - EC - Ecuador' },
  { key: 818, value: 'EG', text: '818 - EG - Egypt' },
  { key: 222, value: 'SV', text: '222 - SV - El Salvador' },
  { key: 226, value: 'GQ', text: '226 - GQ - Equatorial Guinea' },
  { key: 232, value: 'ER', text: '232 - ER - Eritrea' },
  { key: 233, value: 'EE', text: '233 - EE - Estonia' },
  { key: 231, value: 'ET', text: '231 - ET - Ethiopia' },
  { key: 238, value: 'FK', text: '238 - FK - Falkland Islands (Malvinas)' },
  { key: 242, value: 'FJ', text: '242 - FJ - Fiji' },
  { key: 246, value: 'FI', text: '246 - FI - Finland' },
  { key: 234, value: 'FO', text: '234 - FO - Faroe Islands' },
  { key: 250, value: 'FR', text: '250 - FR - France' },
  { key: 254, value: 'GF', text: '254 - GF - French Guiana' },
  { key: 258, value: 'PF', text: '258 - PF - French Polynesia' },
  { key: 260, value: 'TF', text: '260 - TF - French Southern Territories' },
  { key: 266, value: 'GA', text: '266 - GA - Gabon' },
  { key: 270, value: 'GM', text: '270 - GM - Gambia' },
  { key: 268, value: 'GE', text: '268 - GE - Georgia' },
  { key: 276, value: 'DE', text: '276 - DE - Germany' },
  { key: 288, value: 'GH', text: '288 - GH - Ghana' },
  { key: 292, value: 'GI', text: '292 - GI - Gibraltar' },
  { key: 300, value: 'GR', text: '300 - GR - Greece' },
  { key: 304, value: 'GL', text: '304 - GL - Greenland' },
  { key: 308, value: 'GD', text: '308 - GD - Grenada' },
  { key: 312, value: 'GP', text: '312 - GP - Guadeloupe' },
  { key: 316, value: 'GU', text: '316 - GU - Guam' },
  { key: 320, value: 'GT', text: '320 - GT - Guatemala' },
  { key: 324, value: 'GN', text: '324 - GN - Guinea' },
  { key: 624, value: 'GW', text: '624 - GW - Guinea-Bissau' },
  { key: 328, value: 'GY', text: '328 - GY - Guyana' },
  { key: 332, value: 'HT', text: '332 - HT - Haiti' },
  { key: 334, value: 'HM', text: '334 - HM - Heard Island and McDonald Islands' },
  { key: 340, value: 'HN', text: '340 - HN - Honduras' },
  { key: 344, value: 'HK', text: '344 - HK - Hong Kong' },
  { key: 348, value: 'HU', text: '348 - HU - Hungary' },
  { key: 352, value: 'IS', text: '352 - IS - Iceland' },
  { key: 356, value: 'IN', text: '356 - IN - India' },
  { key: 360, value: 'ID', text: '360 - ID - Indonesia' },
  { key: 364, value: 'IR', text: '364 - IR - Iran' },
  { key: 368, value: 'IQ', text: '368 - IQ - Iraq' },
  { key: 372, value: 'IE', text: '372 - IE - Ireland' },
  { key: 376, value: 'IL', text: '376 - IL - Israel' },
  { key: 380, value: 'IT', text: '380 - IT - Italy' },
  { key: 388, value: 'JM', text: '388 - JM - Jamaica' },
  { key: 392, value: 'JP', text: '392 - JP - Japan' },
  { key: 400, value: 'JO', text: '400 - JO - Jordan' },
  { key: 398, value: 'KZ', text: '398 - KZ - Kazakhstan' },
  { key: 404, value: 'KE', text: '404 - KE - Kenya' },
  { key: 296, value: 'KI', text: '296 - KI - Kiribati' },
  { key: 410, value: 'KR', text: '410 - KR - South Korea' },
  { key: 408, value: 'KP', text: '408 - KP - North Korea' },
  { key: 414, value: 'KW', text: '414 - KW - Kuwait' },
  { key: 417, value: 'KG', text: '417 - KG - Kyrgyzstan' },
  { key: 418, value: 'LA', text: '418 - LA - Laos' },
  { key: 428, value: 'LV', text: '428 - LV - Latvia' },
  { key: 422, value: 'LB', text: '422 - LB - Lebanon' },
  { key: 426, value: 'LS', text: '426 - LS - Lesotho' },
  { key: 430, value: 'LR', text: '430 - LR - Liberia' },
  { key: 434, value: 'LY', text: '434 - LY - Libya' },
  { key: 438, value: 'LI', text: '438 - LI - Liechtenstein' },
  { key: 440, value: 'LT', text: '440 - LT - Lithuania' },
  { key: 442, value: 'LU', text: '442 - LU - Luxembourg' },
  { key: 446, value: 'MO', text: '446 - MO - Macao' },
  { key: 807, value: 'MK', text: '807 - MK - North Macedonia' },
  { key: 450, value: 'MG', text: '450 - MG - Madagascar' },
  { key: 454, value: 'MW', text: '454 - MW - Malawi' },
  { key: 458, value: 'MY', text: '458 - MY - Malaysia' },
  { key: 462, value: 'MV', text: '462 - MV - Maldives' },
  { key: 466, value: 'ML', text: '466 - ML - Mali' },
  { key: 470, value: 'MT', text: '470 - MT - Malta' },
  { key: 584, value: 'MH', text: '584 - MH - Marshall Islands' },
  { key: 474, value: 'MQ', text: '474 - MQ - Martinique' },
  { key: 478, value: 'MR', text: '478 - MR - Mauritania' },
  { key: 480, value: 'MU', text: '480 - MU - Mauritius' },
  { key: 175, value: 'YT', text: '175 - YT - Mayotte' },
  { key: 484, value: 'MX', text: '484 - MX - Mexico' },
  { key: 583, value: 'FM', text: '583 - FM - Micronesia' },
  { key: 498, value: 'MD', text: '498 - MD - Moldova' },
  { key: 492, value: 'MC', text: '492 - MC - Monaco' },
  { key: 496, value: 'MN', text: '496 - MN - Mongolia' },
  { key: 500, value: 'MS', text: '500 - MS - Montserrat' },
  { key: 504, value: 'MA', text: '504 - MA - Morocco' },
  { key: 508, value: 'MZ', text: '508 - MZ - Mozambique' },
  { key: 104, value: 'MM', text: '104 - MM - Myanmar' },
  { key: 516, value: 'NA', text: '516 - NA - Namibia' },
  { key: 520, value: 'NR', text: '520 - NR - Nauru' },
  { key: 524, value: 'NP', text: '524 - NP - Nepal' },
  { key: 528, value: 'NL', text: '528 - NL - Netherlands' },
  { key: 530, value: 'AN', text: '530 - AN' },
  { key: 540, value: 'NC', text: '540 - NC - New Caledonia' },
  { key: 554, value: 'NZ', text: '554 - NZ - New Zealand' },
  { key: 558, value: 'NI', text: '558 - NI - Nicaragua' },
  { key: 562, value: 'NE', text: '562 - NE - Niger' },
  { key: 566, value: 'NG', text: '566 - NG - Nigeria' },
  { key: 570, value: 'NU', text: '570 - NU - Niue' },
  { key: 574, value: 'NF', text: '574 - NF - Norfolk Island' },
  { key: 580, value: 'MP', text: '580 - MP - Northern Mariana Islands' },
  { key: 578, value: 'NO', text: '578 - NO - Norway' },
  { key: 512, value: 'OM', text: '512 - OM - Oman' },
  { key: 586, value: 'PK', text: '586 - PK - Pakistan' },
  { key: 585, value: 'PW', text: '585 - PW - Palau' },
  { key: 275, value: 'PS', text: '275 - PS - Palestine' },
  { key: 591, value: 'PA', text: '591 - PA - Panama' },
  { key: 598, value: 'PG', text: '598 - PG - Papua New Guinea' },
  { key: 600, value: 'PY', text: '600 - PY - Paraguay' },
  { key: 604, value: 'PE', text: '604 - PE - Peru' },
  { key: 608, value: 'PH', text: '608 - PH - Philippines' },
  { key: 612, value: 'PN', text: '612 - PN - Pitcairn' },
  { key: 616, value: 'PL', text: '616 - PL - Poland' },
  { key: 620, value: 'PT', text: '620 - PT - Portugal' },
  { key: 630, value: 'PR', text: '630 - PR - Puerto Rico' },
  { key: 634, value: 'QA', text: '634 - QA - Qatar' },
  { key: 638, value: 'RE', text: '638 - RE - Réunion' },
  { key: 642, value: 'RO', text: '642 - RO - Romania' },
  { key: 643, value: 'RU', text: '643 - RU - Russia' },
  { key: 646, value: 'RW', text: '646 - RW - Rwanda' },
  { key: 654, value: 'SH', text: '654 - SH - Saint Helena' },
  { key: 659, value: 'KN', text: '659 - KN - Saint Kitts and Nevis' },
  { key: 662, value: 'LC', text: '662 - LC - Saint Lucia' },
  { key: 666, value: 'PM', text: '666 - PM - Saint Pierre and Miquelon' },
  { key: 670, value: 'VC', text: '670 - VC - Saint Vincent and the Grenadines' },
  { key: 882, value: 'WS', text: '882 - WS - Samoa' },
  { key: 674, value: 'SM', text: '674 - SM - San Marino' },
  { key: 678, value: 'ST', text: '678 - ST - São Tomé and Príncipe' },
  { key: 682, value: 'SA', text: '682 - SA - Saudi Arabia' },
  { key: 686, value: 'SN', text: '686 - SN - Senegal' },
  { key: 690, value: 'SC', text: '690 - SC - Seychelles' },
  { key: 694, value: 'SL', text: '694 - SL - Sierre Leone' },
  { key: 702, value: 'SG', text: '702 - SG - Singapore' },
  { key: 703, value: 'SK', text: '703 - SK - Slovakia' },
  { key: 705, value: 'SI', text: '705 - SI - Slovania' },
  { key: 90, value: 'SB', text: '90 - SB - Solomon Islands' },
  { key: 706, value: 'SO', text: '706 - SO - Somalia' },
  { key: 710, value: 'ZA', text: '710 - ZA - South Africa' },
  { key: 239, value: 'GS', text: '239 - GS - South Georgia and the South Sandwich Islands' },
  { key: 724, value: 'ES', text: '724 - ES - Spain' },
  { key: 144, value: 'LK', text: '144 - LK - Sri Lanka' },
  { key: 736, value: 'SD', text: '736 - SD - Sudan' },
  { key: 740, value: 'SR', text: '740 - SR - Suriname' },
  { key: 744, value: 'SJ', text: '744 - SJ - Svalbard and Jan Mayen' },
  { key: 748, value: 'SZ', text: '748 - SZ - Eswatini' },
  { key: 752, value: 'SE', text: '752 - SE - Sweden' },
  { key: 756, value: 'CH', text: '756 - CH - Switzerland' },
  { key: 760, value: 'SY', text: '760 - SY - Syria' },
  { key: 158, value: 'TW', text: '158 - TW - Taiwan' },
  { key: 762, value: 'TJ', text: '762 - TJ - Tajikstan' },
  { key: 834, value: 'TZ', text: '834 - TZ - Tanzania' },
  { key: 764, value: 'TH', text: '764 - TH - Thailand' },
  { key: 626, value: 'TL', text: '626 - TL - Timor-Leste' },
  { key: 768, value: 'TG', text: '768 - TG - Togo' },
  { key: 772, value: 'TK', text: '772 - TK - Tokelau' },
  { key: 776, value: 'TO', text: '776 - TO - Tonga' },
  { key: 780, value: 'TT', text: '780 - TT - Trinidad and Tobago - TT' },
  { key: 788, value: 'TN', text: '788 - TN - Tunisia' },
  { key: 792, value: 'TR', text: '792 - TR - Turkey' },
  { key: 795, value: 'TM', text: '795 - TM - Turkmenistan' },
  { key: 796, value: 'TC', text: '796 - TC - Turks and Caicos Islands' },
  { key: 798, value: 'TV', text: '798 - TV - Tuvalu' },
  { key: 800, value: 'UG', text: '800 - UG - Uganda' },
  { key: 804, value: 'UA', text: '804 - UA - Ukraine' },
  { key: 784, value: 'AE', text: '784 - AE - United Arab Emirates' },
  { key: 826, value: 'GB', text: '826 - GB - United Kingdom' },
  { key: 840, value: 'US', text: '840 - US - United States' },
  { key: 581, value: 'UM', text: '581 - UM - United States Minor Outlying Islands' },
  { key: 858, value: 'UY', text: '858 - UY - Uruguay' },
  { key: 860, value: 'UZ', text: '860 - UZ - Uzbekistan' },
  { key: 548, value: 'VU', text: '548 - VU - Vanuatu' },
  { key: 336, value: 'VA', text: '336 - VA - Vatican City' },
  { key: 862, value: 'VE', text: '862 - VE - Venezuela' },
  { key: 704, value: 'VN', text: '704 - VN - Vietnam' },
  { key: 92, value: 'VG', text: '92 - VG - British Virgin Islands' },
  { key: 850, value: 'VI', text: '850 - VI - U.S Virgin Islands' },
  { key: 876, value: 'WF', text: '876 - WF - Wallis and Futuna' },
  { key: 732, value: 'EH', text: '732 - EH - Western Sahara' },
  { key: 887, value: 'YE', text: '887 - YE - Yemen' },
  { key: 891, value: 'YU', text: '891 - YU - Yugoslavia' },
  { key: 894, value: 'ZM', text: '894 - ZM - Zambia' },
  { key: 716, value: 'ZW', text: '716 - ZW - Zimbabwe' },
  { key: 4, value: 'AF', text: '4 - AF - Afghanistan' },
  { key: 688, value: 'RS', text: '688 - RS - Serbia' },
  { key: 248, value: 'AX', text: '248 - AX - Aland Islands' },
  { key: 535, value: 'BQ', text: '535 - BQ - Caribbean Netherlands' },
  { key: 831, value: 'GG', text: '831 - GG - Guernsey' },
  { key: 832, value: 'JE', text: '832 - JE - Jersey' },
  { key: 531, value: 'CW', text: '531 - CW - Curaçao' },
  { key: 833, value: 'IM', text: '833 - IM - Isle of Man' },
  { key: 652, value: 'BL', text: '652 - BL - Saint Barthélemy ' },
  { key: 663, value: 'MF', text: '663 - MF - Saint Martin' },
  { key: 534, value: 'SX', text: '534 - SX - Sint Maarten' },
  { key: 499, value: 'ME', text: '499 - ME - Montenegro' },
  { key: 728, value: 'SS', text: '728 - SS - South Sudan' },
  { key: 900, value: 'XK', text: '900 - XK - Kosovo' },
  { key: 998, value: 'None', text: '998 - None' },
  { key: 999, value: 'International', text: '999 - International' },
  { key: 999800, value: 'International Freephone', text: '999800 - International Freephone' },
  { key: 999870, value: 'Inmarsat', text: '999870 - Inmarsat' },
  { key: 999875, value: 'Maritime Mobile service', text: '999875 - Maritime Mobile service' },
  {
    key: 999878,
    value: 'Universal Personal Telecommunications services',
    text: '999878 - Universal Personal Telecommunications services',
  },
  { key: 999879, value: 'National non-commercial purposes', text: '999879 - National non-commercial purposes' },
  { key: 999881, value: 'Global Mobile Satellite System', text: '999881 - Global Mobile Satellite System' },
  { key: 999882, value: 'International Networks', text: '999882 - International Networks' },
  { key: 999888, value: 'Disaster Relief', text: '999888 - Disaster Relief' },
  { key: 999979, value: 'International Premium Rate Service', text: '999979 - International Premium Rate Service' },
  {
    key: 999991,
    value: 'International Telecommunications Public Correspondence Service',
    text: '999991 - International Telecommunications Public Correspondence Service',
  },
  {
    key: 999999999,
    value: 'US + US Territories',
    text: 'US + US Territories',
  },
  {
    key: 999999998,
    value: 'All Countries',
    text: 'All Countries',
  },
  {
    key: 999999997,
    value: 'South East Asia',
    text: 'South East Asia',
  },
  {
    key: 999999996,
    value: 'Europe',
    text: 'Europe',
  },
];

// South East Asia
export const seaCountryCodes = ['BN', 'KH', 'TL', 'ID', 'LA', 'MY', 'MM', 'PH', 'SG', 'TH', 'VN'];
// US + US Territories
export const unitedStateCountryCodes = ['US', 'AS', 'GU', 'MP', 'PR', 'VI'];
// Europe Country Codes
export const europeCountryCodes = [
  'AL',
  'AD',
  'AT',
  'BY',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IS',
  'IE',
  'IT',
  'XK',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'MD',
  'MC',
  'ME',
  'NL',
  'MK',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'UA',
  'GB',
  'VA',
];
export const allCountryCodes = [
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FJ',
  'FI',
  'FO',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IL',
  'IT',
  'JM',
  'JP',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KR',
  'KP',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'AN',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'SH',
  'KN',
  'LC',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'SC',
  'SL',
  'SG',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VU',
  'VA',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'YU',
  'ZM',
  'ZW',
  'AF',
  'RS',
  'AX',
  'BQ',
  'GG',
  'JE',
  'CW',
  'IM',
  'BL',
  'MF',
  'SX',
  'ME',
  'SS',
  'XK',
];

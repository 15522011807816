import React from 'react';
import { TableHeaderCell } from 'semantic-ui-react';
import { CouponTargetAssignmentStatus } from 'pages/KeywordTargetQueue/types';

interface KeywordTargetQueueHeadersProps {
  activeStatusFilter: CouponTargetAssignmentStatus;
}

const KeywordTargetQueueTableHeaders = ({ activeStatusFilter }: KeywordTargetQueueHeadersProps) => {
  return (
    <thead>
      <tr>
        <TableHeaderCell className="one wide">ID</TableHeaderCell>
        <TableHeaderCell className="one wide">Merchant</TableHeaderCell>
        <TableHeaderCell className="six wide">Keyword</TableHeaderCell>
        <TableHeaderCell className="one wide">Madmin Keyword Link</TableHeaderCell>
        {activeStatusFilter === 'PENDING' && <TableHeaderCell className="one wide">Actions</TableHeaderCell>}
      </tr>
    </thead>
  );
};

export default KeywordTargetQueueTableHeaders;

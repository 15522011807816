import { wfGet } from '..';

export const getNetworkMerchants = () => async dispatch => {
  try {
    const networkMerchants = await dispatch(wfGet(`/api/network-merchant-onboarding`));
    return networkMerchants;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNetworks = () => async dispatch => {
  try {
    const networks = await dispatch(wfGet(`/api/networks`));
    return networks;
  } catch (error) {
    return Promise.reject(error);
  }
};

import { RootState } from 'store';
import { defaultError } from './reducers/error';
import { initialFeatureFlagState } from './reducers/featureFlags';
import { defaultResource } from './reducers/merchantAdmin/resource';
import { defaultSearch } from './reducers/merchantAdmin/search';
import { defaultUser } from './reducers/user';
import { defaultNetworkState } from './redux/store/network/reducer';

export default (): RootState => ({
  user: defaultUser(),
  error: defaultError,
  merchantAdmin: {
    search: defaultSearch,
    resource: defaultResource,
    network: defaultNetworkState,
  },
  featureFlags: initialFeatureFlagState(),
});

import React from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { Table, Checkbox, Icon } from 'semantic-ui-react';

const CouponRow = ({ coupon, handleUpdateCoupon, viewOnly }) => {
  const canEdit = useSelector(state => state.user.canEdit);
  const isViewOnly = viewOnly ?? !canEdit;
  const toDate = date => {
    return formatInTimeZone(new Date(date), 'UTC', 'yyyy-MM-dd HH:mm:ssxxx');
  };

  const isUpcoming = startDate => {
    return new Date(startDate).getTime() >= new Date().getTime();
  };

  return (
    <Table.Row className={coupon.Disabled ? 'disable' : null}>
      {!isViewOnly ? (
        <Table.Cell>
          <Checkbox toggle defaultChecked={!coupon.Disabled} onChange={() => handleUpdateCoupon(coupon)}></Checkbox>
        </Table.Cell>
      ) : (
        <Table.Cell className="!text-center text-lg">
          <Icon name={coupon.Disabled ? 'minus circle' : 'check circle'} />
        </Table.Cell>
      )}
      <Table.Cell>{coupon.ID}</Table.Cell>
      <Table.Cell positive={isUpcoming(coupon.StartDate)}>{toDate(coupon.StartDate)}</Table.Cell>
      <Table.Cell>{toDate(coupon.EndDate)}</Table.Cell>
      <Table.Cell>{coupon.Code}</Table.Cell>
      <Table.Cell className="fixed-table-cell">{coupon.Exclusions}</Table.Cell>
      <Table.Cell className="fixed-table-cell">{coupon.Description}</Table.Cell>
      <Table.Cell>{coupon.Source}</Table.Cell>
      <Table.Cell>
        <a target="_blank" rel="noopener noreferrer" href={coupon.URL}>
          {coupon.URL}
        </a>
      </Table.Cell>
    </Table.Row>
  );
};

export default CouponRow;

import React from 'react';
import { Table, Loader } from 'semantic-ui-react';
import { formatDate } from '../../helpers';
import { FraudCommission } from 'pages/FraudCommissions/types';

interface FraudCommissionsTableProps {
  fraudCommissions: FraudCommission[];
  isLoading: boolean;
  error: string;
}

export const FraudCommissionsTable = ({ fraudCommissions, isLoading, error }: FraudCommissionsTableProps) => {
  if (isLoading) {
    return <Loader active size="massive" inline="centered" />;
  }

  if (error) {
    return (<div className="flex justify-center items-center w-full">
      <p className="text-wildfire-orange">{error}</p>
    </div>);
  }

  const tableHeaders = (
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Commission ID</Table.HeaderCell>
      <Table.HeaderCell>Merchant Name</Table.HeaderCell>
      <Table.HeaderCell>Application Name</Table.HeaderCell>
      <Table.HeaderCell>Created Date</Table.HeaderCell>
    </Table.Row>
  );

  const tableRows = fraudCommissions.map((fraudCommission: FraudCommission, index) => {
    const { id, commissionId, markedAsFraudOn, merchantName, applicationName } = fraudCommission;

    return (
      <Table.Row key={id}>
        <Table.Cell>
          <p>{id}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{commissionId}</p>
        </Table.Cell>
        <Table.Cell>
          <p className="truncate max-w-[300px]">{merchantName}</p>
        </Table.Cell>
        <Table.Cell>
          <p className="truncate max-w-[300px]">{applicationName}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{formatDate(markedAsFraudOn)}</p>
        </Table.Cell>
      </Table.Row>
    )

  });

  return (
    <div className="font-montserrat text-sm whitespace-nowrap bg-white p-8 w-full max-w-[96vw] shadow-lg rounded-md overflow-x-scroll">
      <Table celled striped>
        <Table.Header className="text-center">{tableHeaders}</Table.Header>
        {Boolean(fraudCommissions.length) && <Table.Body>{tableRows}</Table.Body>}
      </Table>
    </div>
  );
};

export default FraudCommissionsTable;
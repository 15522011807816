import React from 'react';
import { TableHeaderCell } from 'semantic-ui-react';
import { CommissionRateQueueStatus } from './types';

interface CommissionRateQueueTableHeaderProps {
  activeStatusFilter: CommissionRateQueueStatus;
}

const CommissionRateQueueTableHeader: React.FC<CommissionRateQueueTableHeaderProps> = ({ activeStatusFilter }) => {
  return (
    <thead className="bg-gray-100 whitespace-nowrap">
      <tr>
        <TableHeaderCell className="text-sm font-bold text-center p-2">Review Item ID</TableHeaderCell>
        <TableHeaderCell className="text-sm font-bold text-center p-2">Status</TableHeaderCell>
        <TableHeaderCell className="text-sm font-bold text-center p-2">Merchant Name</TableHeaderCell>
        <TableHeaderCell className="text-sm font-bold text-center p-2">Merchant Score</TableHeaderCell>
        <TableHeaderCell className="text-sm font-bold text-center p-2">Affiliate Network</TableHeaderCell>
        <TableHeaderCell className="text-sm font-bold text-center p-2">Network Merchant ID</TableHeaderCell>
        <TableHeaderCell className="text-sm font-bold text-center p-2">Network Merchant Commission ID</TableHeaderCell>
        {activeStatusFilter === 'PENDING' && (
          <TableHeaderCell className="text-sm font-bold text-center p-2">
            Network Merchant Commission Name
          </TableHeaderCell>
        )}
        <TableHeaderCell className="text-sm font-bold text-center p-2">Suggested Display Name</TableHeaderCell>
        {activeStatusFilter !== 'PENDING' && (
          <TableHeaderCell className="text-sm font-bold text-center p-2">Updated Commission Name</TableHeaderCell>
        )}
        <TableHeaderCell className="text-sm font-bold text-center p-2">Proposed By</TableHeaderCell>
        <TableHeaderCell className="text-sm font-bold text-center p-2">Created Date</TableHeaderCell>
        <TableHeaderCell className="text-sm font-bold text-center p-2">Reviewer</TableHeaderCell>
        <TableHeaderCell className="text-sm font-bold text-center p-2">Note</TableHeaderCell>
        {activeStatusFilter === 'PENDING' ? (
          <TableHeaderCell className="text-sm font-bold text-center p-2">Actions</TableHeaderCell>
        ) : (
          <TableHeaderCell className="text-sm font-bold text-center p-2">Reviewer Updated Date</TableHeaderCell>
        )}
      </tr>
    </thead>
  );
};

export default CommissionRateQueueTableHeader;

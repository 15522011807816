import { wfCSVGet } from '../../actions';

export const getMerchantStats = () => async dispatch => {
  try {
    const res = await dispatch(wfCSVGet(`/api/merchant-stats`));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Sales Dashboard Form Constants
export const timeZoneOptions = [
  { key: 'pst', text: 'PST', value: 'America/Los_Angeles' },
  { key: 'utc', text: 'UTC', value: 'UTC' },
];
export const groupByOptions = [
  { key: 'day', text: 'Day', value: 'day' },
  { key: 'week', text: 'Week', value: 'week' },
  { key: 'month', text: 'Month', value: 'month' },
  { key: 'year', text: 'Year', value: 'year' },
  { key: 'application', text: 'Application', value: 'application' },
  { key: 'merchant', text: 'Merchant', value: 'merchant' },
  { key: 'network', text: 'Network', value: 'network' },
];

export const timeLabel = {
  day: 'Date',
  week: 'Week of',
  month: 'Month of',
  year: 'Year of',
  application: 'Application ID',
  merchant: 'Merchant ID',
  network: 'Network ID',
};

// Graph Constants
// Better Long Term Fix - Have the networks and applications come in dynamically and filter out the ones that we don't need. Just need to figure out how to keep the colors consistent

export const applicationColors = {
  3: '#2F4C79',
  24: '#FEBE47',
  46: '#75C947',
  36: '#B9ECE3',
  38: '#02A888',
  21: '#1B2C6B',
  30: '#A1266A',
  52: '#F44F1C',
  68: '#9370DF',
  71: '#3948CD',
};

export const networkColors = {
  2: '#AFE1AF',
  3: '#002366',
  4: '#E40146',
  5: '#FF6600',
  6: '#C20000',
  7: '#9370DB',
  10: '#1F6DB4',
  14: '#49C5B1',
  16: '#FF9802',
  18: '#6730B2',
  19: '#4a5335',
};

export const networksToIgnore = {
  1: 'WF',
  8: 'ShareResults',
  9: 'DigitalRiver',
  11: 'LinkConnector',
  12: 'PerformanceHorizon',
  15: 'Webgains',
};

// only need to filter out the ones we don't want that include the term "client" or "wildfire"
export const applicationsToIgnore = {
  32: 'Rakuten Clients',
  34: 'Ibotta Clients',
  40: 'Pei Mobile Clients',
  42: 'Earny Mobile Clients',
  44: 'Messages Improved Clients',
  48: 'Summit Tech Clients',
  54: 'Edison Clients',
  58: 'Acorns Mobile Client',
  60: 'Acorns Web Client',
};

// api
export const WF_FETCH = 'WF_FETCH';
export const WF_CSV_FETCH = 'WF_CSV_FETCH';
// error handling
export const SET_BAD_AUTH = 'SET_BAD_AUTH';
// user
export const LOGOUT = 'LOGOUT';
export const SET_DEVICE_TOKEN = 'SET_DEVICE_TOKEN';
export const SET_SENDER_TOKEN = 'SET_SENDER_TOKEN';
export const SET_AUTH0_TOKEN = 'SET_AUTH0_TOKEN';
export const SET_CAN_EDIT = 'SET_CAN_EDIT';
// search
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SEARCH_DETAIL = 'SET_SEARCH_DETAIL';
// resource
export const SET_LAST_UPDATED = 'SET_LAST_UPDATED';
// feature flags
export const TOGGLE_FEATURE_FLAG = 'TOGGLE_FEATURE_FLAG';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Table, TableBody, Loader, Button } from 'semantic-ui-react';
import { getKeywordTargetQueue, updateKeywordTargetQueueByID } from 'actions/keywordTargets/keywordTargets';
import { findErrorMessage, wfUserAgent } from 'helpers';
import KeywordTargetQueueTableHeaders from './KeywordTargetQueueTableHeader';
import { CouponTargetAssignment, CouponTargetAssignmentStatus } from '../../pages/KeywordTargetQueue/types';
import './KeywordTargetQueueTable.scss';

interface UpdateKeywordTargetQueueByIDBody {
  ID: number;
  Status: string;
  ModifiedAuthor: string;
}

interface KeywordTargetQueueTableProps {
  getKeywordTargetQueue: () => Promise<CouponTargetAssignment[]>;
  updateKeywordTargetQueueByID: (id: number, body: UpdateKeywordTargetQueueByIDBody) => Promise<void>;
  activeStatusFilter: CouponTargetAssignmentStatus;
}

const KeywordTargetQueueTable = (props: KeywordTargetQueueTableProps) => {
  const { getKeywordTargetQueue, updateKeywordTargetQueueByID, activeStatusFilter } = props;
  const [couponTargetAssignments, setCouponTargetAssignments] = useState<CouponTargetAssignment[]>([]);
  const [filteredCouponTargetAssignments, setFilteredCouponTargetAssignments] = useState<CouponTargetAssignment[]>([]);
  const [isUpdatingCouponTargetAssignment, setIsUpdatingCouponTargetAssignment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchKeywordTargetQueue = async () => {
    try {
      setIsLoading(true);
      const response = await getKeywordTargetQueue();
      if (!Array.isArray(response)) throw new Error('Failed to get keyword target queue.');

      setCouponTargetAssignments(response);
    } catch (error) {
      toast.error('Failed to get Keyword target queue.');
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(capitalize(error.message) || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchKeywordTargetQueue();
  }, [getKeywordTargetQueue]);

  const handleOnActionButtonClick = async (id: number, status: 'BLOCKED' | 'IGNORED') => {
    const userEmail = localStorage.getItem('userEmail') || wfUserAgent();
    try {
      setIsUpdatingCouponTargetAssignment(true);
      await updateKeywordTargetQueueByID(id, {
        ID: id,
        Status: status,
        ModifiedAuthor: userEmail,
      });
      toast.success('Successfully updated status!');
      fetchKeywordTargetQueue();
    } catch (error) {
      toast.error(`Failed to update coupon target assignment ID: ${id}`);
      setError(findErrorMessage(error) || 'Unknown error has occurred.');
    } finally {
      setIsUpdatingCouponTargetAssignment(false);
    }
  };

  useEffect(() => {
    const newFilteredCouponTargetAssignments = couponTargetAssignments.filter(
      (couponTargetAssignment: CouponTargetAssignment) => couponTargetAssignment.Status === activeStatusFilter,
    );

    setFilteredCouponTargetAssignments(newFilteredCouponTargetAssignments);
  }, [couponTargetAssignments, activeStatusFilter]);

  const className = 'keyword-target-queue-table';
  const tableRows = filteredCouponTargetAssignments.map(({ ID, KeywordID, Keyword, Merchant }) => {
    return (
      <tr key={ID}>
        <td>
          <p>{ID}</p>
        </td>
        <td>
          <p>{Merchant}</p>
        </td>
        <td>
          <p>{Keyword}</p>
        </td>
        <td className="keyword-link-button">
          <div className={`${className}-button-container`}>
            <Link to={`/keyword-targets/${KeywordID}`}>
              <Button>More info</Button>
            </Link>
          </div>
        </td>
        {activeStatusFilter === 'PENDING' && (
          <td>
            <div className={`${className}-action-button-container`}>
              <Button
                disabled={isUpdatingCouponTargetAssignment}
                onClick={() => handleOnActionButtonClick(ID, 'BLOCKED')}
              >
                Block
              </Button>
              <Button
                disabled={isUpdatingCouponTargetAssignment}
                onClick={() => handleOnActionButtonClick(ID, 'IGNORED')}
              >
                Ignore
              </Button>
            </div>
          </td>
        )}
      </tr>
    );
  });

  return (
    <div className={`${className}-container`}>
      <Table celled striped aria-label="keyword target queue table" className={`${className}-table`}>
        <KeywordTargetQueueTableHeaders activeStatusFilter={activeStatusFilter} />
        {!Boolean(error) && <TableBody>{tableRows}</TableBody>}
      </Table>
      {Boolean(error) && !isLoading && (
        <div className="keyword-target-queue-table-error-container">
          <span className="keyword-target-queue-table-error-text">{error}</span>
        </div>
      )}
      {isLoading && <Loader active size="massive" inline="centered" />}
    </div>
  );
};

const mapActionsToProps = {
  getKeywordTargetQueue,
  updateKeywordTargetQueueByID,
};

export default connect(null, mapActionsToProps)(KeywordTargetQueueTable);

import { SET_BAD_AUTH } from '../constants/actionTypes';

export const defaultError = {
  badAuth: false,
  lastError: {},
};

export default (state = defaultError, action) => {
  switch (action.type) {
    case SET_BAD_AUTH:
      return {
        ...state,
        badAuth: true,
      };
    default:
      return state;
  }
};

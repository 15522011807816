import React from 'react';
import { Message } from 'semantic-ui-react';

const ResponseMessage = ({ responseMessage }) => {
  let info;
  if (responseMessage.status === 'Success') {
    info = <p>{`Your bonus was successful. Transaction ID: ${responseMessage.msg}`}</p>;
  } else {
    info = <p>{responseMessage.msg}</p>;
  }

  return (
    <Message>
      <Message.Header>{responseMessage.status}</Message.Header>
      {info}
    </Message>
  );
};

export default ResponseMessage;

import React, { useState, KeyboardEvent } from 'react';
import { Form } from 'semantic-ui-react';
import { Commission } from './types';

interface NetworkMerchantOnboardingCommissionsDisplayNameProps {
  commission: Commission;
  handleSubmit: (e: KeyboardEvent<HTMLInputElement>, commission: Commission, displayName: string) => void;
}

const NetworkMerchantOnboardingCommissionsDisplayName: React.FC<
  NetworkMerchantOnboardingCommissionsDisplayNameProps
> = ({ commission, handleSubmit }) => {
  const [displayName, setDisplayName] = useState(commission.DisplayName);

  return (
    <Form.Input
      transparent
      fluid
      className="display-name"
      onChange={(e, { value }) => setDisplayName(value)}
      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleSubmit(e, commission, displayName)}
      value={displayName}
    />
  );
};

export default NetworkMerchantOnboardingCommissionsDisplayName;

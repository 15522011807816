const getLastWeek = () => {
  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7);
  return {
    today,
    lastWeek,
  };
};

const getPreviousMonthDates = () => {
  const currentDate = new Date();

  currentDate.setDate(0);
  const lastDayOfPreviousMonth = new Date(currentDate);

  currentDate.setDate(1);
  const firstDayOfPreviousMonth = new Date(currentDate);

  return {
    lastDayOfPreviousMonth,
    firstDayOfPreviousMonth,
  };
};

const convertToISOString = (date: Date) => date.toISOString().split('T')[0];

export function getFollowingDay(dateStr: string) {
  const date = new Date(dateStr);
  const dayAfter = new Date(date);
  dayAfter.setDate(date.getDate() + 1);
  return convertToISOString(dayAfter);
}

const { today, lastWeek } = getLastWeek();
const { lastDayOfPreviousMonth, firstDayOfPreviousMonth } = getPreviousMonthDates();

export const dateRecipes = [
  {
    name: 'Get Commissions in last week',
    value: `CreatedDate:>"${convertToISOString(lastWeek)}"`,
  },
  {
    name: 'Get Commissions in last month',
    value: `CreatedDate:>"${convertToISOString(firstDayOfPreviousMonth)}" CreatedDate:<"${convertToISOString(
      lastDayOfPreviousMonth,
    )}"`,
  },
  {
    name: 'Get Commissions by Event Date',
    value: `EventDate:"${convertToISOString(today)}"`,
  },
];

export const idRecipes = [
  {
    name: 'Get Commissions for Ebay',
    value: `Merchant:"Ebay"`,
  },
  {
    name: 'Get Commissions by Commission ID',
    value: 'ID:14552406',
  },
  {
    name: 'Get Commissions by Device ID',
    value: `DeviceID:1`,
  },
  {
    name: 'Get Commissions by Merchant ID',
    value: 'MerchantID:1234',
  },
  {
    name: 'Get Commissions by Order ID',
    value: `MerchantOrderID:1234`,
  },
  {
    name: 'Get Commissions by Merchant SKU',
    value: `MerchantSKU:1234`,
  },
  {
    name: 'Get Commissions by Tracking Code',
    value: `TrackingCode:"uid=b7b09b78-141f-46a0-af7b-076c6cac4405,o=transaction,dp=safari-mbl"`,
  },
];

export const amountRecipes = [
  {
    name: 'Get Commissions where Sale Amount is greater than $10',
    value: `SaleAmount:>10`,
  },
  {
    name: 'Get Commissions where Sale Amount is less than $100',
    value: `SaleAmount:<100`,
  },
];

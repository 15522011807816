import { wfCSVGet } from 'actions';

export const getAgingReport =
  ({ type, id, month }) =>
  async dispatch => {
    try {
      const agingReport = await dispatch(wfCSVGet(`/api/aging-report/admin?type=${type}&id=${id}&month=${month}`));
      return agingReport;
    } catch (error) {
      return Promise.reject(error);
    }
  };

import React, { useEffect, FC, ReactElement, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getNetworksRequest } from 'redux/store/network/actions';
import { networksSelector } from 'redux/store/network/selectors';
import { Grid } from 'semantic-ui-react';
import SearchV2 from 'components/SearchV2/SearchV2';
import { checkIsDQContractor } from 'helpers/auth0';
import KeywordContainer from '../../components/Keyword/KeywordContainer';
import MerchantContainer from '../../components/Merchant/MerchantContainer';
import NetworkMerchantContainer from '../../components/NetworkMerchant/NetworkMerchantContainer';
import NetworkMerchantHotelContainer from '../../components/NetworkMerchantHotel/NetworkMerchantHotelContainer';

import './MerchantAdmin.css';

const MerchantAdmin: FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();
  const networks = useSelector(networksSelector, shallowEqual);
  const [isDQContractor, setIsDQContractor] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    if (networks === null) {
      dispatch(getNetworksRequest());
    }
  }, [dispatch, networks]);

  useEffect(() => {
    setIsDQContractor(checkIsDQContractor(user));
  }, [user]);

  return (
    <div className="merchant-admin">
      <h1>Merchant Admin</h1>
      <Grid columns="equal">
        <Grid.Column width="4">
          <SearchV2 />
        </Grid.Column>
        <Grid.Column>
          {match.isExact ? (
            'Welcome to the Merchant Admin tool :) Start by searching and selecting something of interest.'
          ) : (
            <Switch>
              <Route
                path={`${match.path}/merchant/:merchantId`}
                render={(params): ReactElement => <MerchantContainer {...params} isDQContractor={isDQContractor} />}
              />
              <Route
                path={`${match.path}/network_merchant/:networkMerchantId`}
                render={(params): ReactElement => (
                  <NetworkMerchantContainer {...params} includeKeywords isRoot isDQContractor={isDQContractor} />
                )}
              />
              <Route
                path={`${match.path}/keyword/:keywordId`}
                render={(params): ReactElement => <KeywordContainer {...params} isDQContractor={isDQContractor} />}
              />
              <Route
                path={`${match.path}/network_merchant_hotel/:id`}
                component={NetworkMerchantHotelContainer}
                render={(params): ReactElement => <NetworkMerchantHotelContainer {...params} />}
              />
            </Switch>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default MerchantAdmin;

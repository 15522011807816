import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import rootSaga from 'redux/store/rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import initialState from './initialState';
import wfApi from './middleware/wfApi';
import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const middleware = [thunk, wfApi, sagaMiddleware];

const store = createStore(
  rootReducer,
  initialState(),
  composeWithDevTools(applyMiddleware(...middleware), sentryReduxEnhancer),
);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;

export default store;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Loader, Input, Button } from 'semantic-ui-react';

import './AddMerchant.css';

class AddMerchant extends Component {
  state = {
    name: '',
    isLoading: false,
    hasError: false,
  };

  inputRef = React.createRef();

  handleChange = e => {
    const { value } = e.target;
    this.setState({
      hasError: false,
      name: value,
    });
  };

  handleSubmit = async () => {
    const { name } = this.state;
    const { addMerchant, handleUpdateNetworkMerchantMerchant } = this.props;
    this.setState({ isLoading: true });
    try {
      const newMerchant = await addMerchant(name);
      if (newMerchant.ID) {
        await handleUpdateNetworkMerchantMerchant(newMerchant.ID);
      }
    } catch (error) {
      this.setState({ hasError: true });
      console.error(error);
    }
    this.setState({ isLoading: false });
  };

  handleKeyDown = e => {
    // only do work on 'enter'
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    const { isLoading, hasError, name } = this.state;

    if (isLoading) {
      return <Loader active size="massive" inline="centered" />;
    }

    return (
      <div className="add-merchant">
        <Segment>
          <h3>Add Merchant</h3>
          <Input
            className="input-field"
            ref={this.inputRef}
            label="Name"
            fluid
            // preventing autofilling with autocomplete
            autoComplete="new-password"
            onChange={this.handleChange}
            error={hasError}
            value={name}
            onKeyDown={this.handleKeyDown}
          />
          <Button className="add-merchant-button" onClick={this.handleSubmit}>
            Add
          </Button>
        </Segment>
      </div>
    );
  }
}

AddMerchant.propTypes = {
  addMerchant: PropTypes.func.isRequired,
  handleUpdateNetworkMerchantMerchant: PropTypes.func.isRequired,
};

export default AddMerchant;

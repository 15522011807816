import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './unauthorized.css';

const Unauthorized = () => {
  const { logout: logoutAuth0 } = useAuth0();
  const [timer, setTimer] = useState(8);

  setTimeout(() => {
    if (timer === 0) {
      logoutAuth0();
    } else {
      setTimer(timer - 1);
    }
  }, 1000);

  return (
    <div className="unauthorized-container">
      <h3>User is not authorized to use this application.</h3>
      <p>{`You will be automatically signed out in ${timer} seconds or`}</p>
      <p className="logout-text" onClick={() => logoutAuth0()}>
        Click here to log out.
      </p>
    </div>
  );
};

export default Unauthorized;

import { SET_BAD_AUTH } from '../constants/actionTypes';
import { history } from '../store';

export const setBadAuth = () => {
  const path = history.location.pathname;
  const previousPage = localStorage.getItem('previousPage');
  if (!previousPage && path !== '/auth') {
    localStorage.setItem('previousPage', path);
  }
  history.push('/auth');
  return { type: SET_BAD_AUTH, badAuth: true };
};

import { SET_LAST_UPDATED } from '../../constants/actionTypes';

export const defaultResource = {
  lastUpdated: '',
};

export default (state = defaultResource, action) => {
  switch (action.type) {
    case SET_LAST_UPDATED:
      return {
        ...state,
        lastUpdated: action.lastUpdated,
      };
    default:
      return state;
  }
};

import React, { useState } from 'react';
import { Segment, Input as InputUI, Icon, Button } from 'semantic-ui-react';

export const NewTarget = ({
  keywordId,
  createKeywordTarget,
  isValidSelector,
  isValidTarget,
  isLoading,
  doesUserHaveExtensionInstalled,
  wasTested,
  sendCouponDataToExtension,
}) => {
  const [nextTarget, setNextTarget] = useState({
    KeywordID: keywordId,
    Input: '',
    Price: '',
    Submit: '',
    Remove: '',
    Timeout: 0,
    Error: '',
    Before: '',
    CCType: '',
    CCNumber: '',
    CCCSC: '',
    CCName: '',
    CCGivenName: '',
    CCAdditionalName: '',
    CCFamilyName: '',
    CCExp: '',
    CCExpMonth: '',
    CCExpYear: '',
    Disabled: false,
    Attempts: 0,
    AttemptTimeout: 0,
    StartingIntoStartApplyingTimeout: 0,
    StartApplyingIntoRemoveApplyingTimeout: 0,
    StartApplyingIntoBeforeApplyingTimeout: 0,
    BeforeApplyingSleepTimeout: 0,
    StartApplyingIntoInputApplyingTimeout: 0,
    RemoveApplyingTimeout: 0,
    RemoveApplyingIntoBeforeApplyingTimeout: 0,
    RemoveApplyingIntoInputApplyingTimeout: 0,
    InputApplyingSleepTimeout: 0,
    SubmitApplyingSleepTimeout: 0,
    FinishApplyingSleepTimeout: 0,
    GoingToNextIntoApplyingBestTimeout: 0,
  });

  const {
    Input,
    Price,
    Submit,
    Remove,
    Timeout,
    Error: ErrorValue,
    Before,
    CCType,
    CCNumber,
    CCCSC,
    CCName,
    CCGivenName,
    CCAdditionalName,
    CCFamilyName,
    CCExp,
    CCExpMonth,
    CCExpYear,
    Disabled,
    Attempts,
    AttemptTimeout,
    StartingIntoStartApplyingTimeout,
    StartApplyingIntoRemoveApplyingTimeout,
    StartApplyingIntoBeforeApplyingTimeout,
    BeforeApplyingSleepTimeout,
    StartApplyingIntoInputApplyingTimeout,
    RemoveApplyingTimeout,
    RemoveApplyingIntoBeforeApplyingTimeout,
    RemoveApplyingIntoInputApplyingTimeout,
    InputApplyingSleepTimeout,
    SubmitApplyingSleepTimeout,
    FinishApplyingSleepTimeout,
    GoingToNextIntoApplyingBestTimeout,
  } = nextTarget;

  const handleChange = (_, { name, value }) => {
    const target = { ...nextTarget };
    target[name] = value;
    setNextTarget(target);
  };

  const handleCreate = () => {
    if (!isValidTarget(nextTarget)) return;
    createKeywordTarget(keywordId, nextTarget);
  };

  return (
    <Segment.Group>
      <Segment>
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Input'}
          name={'Input'}
          value={Input}
          error={!isValidSelector(Input) || !Input}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Price'}
          name={'Price'}
          value={Price}
          error={!isValidSelector(Price) || !Price}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Submit'}
          name={'Submit'}
          value={Submit}
          error={!isValidSelector(Submit)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Remove'}
          name={'Remove'}
          value={Remove}
          error={!isValidSelector(Remove)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Before'}
          name={'Before'}
          value={Before}
          error={!isValidSelector(Before)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Timeout'}
          name={'Timeout'}
          type="number"
          error={Number(Timeout) < 0}
          value={Timeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Error'}
          name={'Error'}
          value={ErrorValue}
          error={!isValidSelector(ErrorValue)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Type'}
          name={'CCType'}
          value={CCType}
          error={!isValidSelector(CCType)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Number'}
          name={'CCNumber'}
          value={CCNumber}
          error={!isValidSelector(CCNumber)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card CVV'}
          name={'CCCSC'}
          value={CCCSC}
          error={!isValidSelector(CCCSC)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={"Credit Card Holder's Name"}
          name={'CCName'}
          value={CCName}
          error={!isValidSelector(CCName)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card First Name'}
          name={'CCGivenName'}
          value={CCGivenName}
          error={!isValidSelector(CCGivenName)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Middle Name'}
          name={'CCAdditionalName'}
          value={CCAdditionalName}
          error={!isValidSelector(CCAdditionalName)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Last Name'}
          name={'CCFamilyName'}
          value={CCFamilyName}
          error={!isValidSelector(CCFamilyName)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Exp Date'}
          name={'CCExp'}
          value={CCExp}
          error={!isValidSelector(CCExp)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Exp Month'}
          name={'CCExpMonth'}
          value={CCExpMonth}
          error={!isValidSelector(CCExpMonth)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Exp Year'}
          name={'CCExpYear'}
          value={CCExpYear}
          error={!isValidSelector(CCExpYear)}
        />
        <InputUI
          fluid
          disabled={true}
          label={'Disabled'}
          name={'Disabled'}
          value={Disabled}
          error={!isValidSelector(Disabled)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Attempts'}
          name={'Attempts'}
          type="number"
          error={Number(Attempts) < 0}
          value={Attempts}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Attempt Timeout'}
          name={'AttemptTimeout'}
          type="number"
          error={Number(AttemptTimeout) < 0}
          value={AttemptTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Starting Into Start Applying Timeout'}
          name={'StartingIntoStartApplyingTimeout'}
          type="number"
          error={Number(StartingIntoStartApplyingTimeout) < 0}
          value={StartingIntoStartApplyingTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Start Applying Into Remove Applying Timeout'}
          name={'StartApplyingIntoRemoveApplyingTimeout'}
          type="number"
          error={Number(StartApplyingIntoRemoveApplyingTimeout) < 0}
          value={StartApplyingIntoRemoveApplyingTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Start Applying Into Before Applying Timeout'}
          name={'StartApplyingIntoBeforeApplyingTimeout'}
          type="number"
          error={Number(StartApplyingIntoBeforeApplyingTimeout) < 0}
          value={StartApplyingIntoBeforeApplyingTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Before Applying Sleep Timeout'}
          name={'BeforeApplyingSleepTimeout'}
          type="number"
          error={Number(BeforeApplyingSleepTimeout) < 0}
          value={BeforeApplyingSleepTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Start Applying Into Input Applying Timeout'}
          name={'StartApplyingIntoInputApplyingTimeout'}
          type="number"
          error={Number(StartApplyingIntoInputApplyingTimeout) < 0}
          value={StartApplyingIntoInputApplyingTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Remove Applying Timeout'}
          name={'RemoveApplyingTimeout'}
          type="number"
          error={Number(RemoveApplyingTimeout) < 0}
          value={RemoveApplyingTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Remove Applying Into Before Applying Timeout'}
          name={'RemoveApplyingIntoBeforeApplyingTimeout'}
          type="number"
          error={Number(RemoveApplyingIntoBeforeApplyingTimeout) < 0}
          value={RemoveApplyingIntoBeforeApplyingTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Remove Applying Into Input Applying Timeout'}
          name={'RemoveApplyingIntoInputApplyingTimeout'}
          type="number"
          error={Number(RemoveApplyingIntoInputApplyingTimeout) < 0}
          value={RemoveApplyingIntoInputApplyingTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Input Applying Sleep Timeout'}
          name={'InputApplyingSleepTimeout'}
          type="number"
          error={Number(InputApplyingSleepTimeout) < 0}
          value={InputApplyingSleepTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Submit Applying Sleep Timeout'}
          name={'SubmitApplyingSleepTimeout'}
          type="number"
          error={Number(SubmitApplyingSleepTimeout) < 0}
          value={SubmitApplyingSleepTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Finish Applying Sleep Timeout'}
          name={'FinishApplyingSleepTimeout'}
          type="number"
          error={Number(FinishApplyingSleepTimeout) < 0}
          value={FinishApplyingSleepTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Going To Next Into Applying Best Timeout'}
          name={'GoingToNextIntoApplyingBestTimeout'}
          type="number"
          error={Number(GoingToNextIntoApplyingBestTimeout) < 0}
          value={GoingToNextIntoApplyingBestTimeout}
        />
        <div className="flex-right">
          <Button disabled={isLoading || !isValidTarget(nextTarget)} positive onClick={handleCreate}>
            Create
          </Button>
          <Button
            onClick={() => sendCouponDataToExtension(nextTarget)}
            disabled={!doesUserHaveExtensionInstalled || isLoading || !isValidTarget(nextTarget)}
            color={wasTested ? 'green' : 'blue'}
            title={
              doesUserHaveExtensionInstalled
                ? 'This will send the target data and some test coupon codes to your extension'
                : 'This option is not currently available, we have not detected that you have a browser extension installed that can support our coupon testing data'
            }
          >
            {wasTested ? <Icon name="check" className="m-0" /> : 'Test'}
          </Button>
        </div>
      </Segment>
    </Segment.Group>
  );
};

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NetworksData } from 'constants/networks';
import { Form } from 'semantic-ui-react';
import { Button, Table, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { getNetworkDropdownOptions, getNetworkName, toPrettyAmount, getEmptyDropdown } from './helpers';
import PaginateControl from './PaginateControl';
import { toPrettyTime } from '../../helpers';

const PAGE_SIZE = 25;

const ItemsColumn = ({ Failed, Started, Success }) => {
  return (
    <>
      {Success > 0 && <span>Success: {Success}</span>}
      <br />
      {Failed > 0 && <span>Failed: {Failed}</span>}
      <br />
      {Started > 0 && <span>Started: {Started}</span>}
    </>
  );
};

const GetReconcileJobs = ({ getReconcileJobs, retryReconcileJob }) => {
  const history = useHistory();
  const [queryData, setQueryData] = useState({
    network: '',
    page: 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rows, setRows] = useState([]);
  const [showRetry, setShowRetry] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const [retryError, setRetryError] = useState('');

  const networkDropdownOptions = [getEmptyDropdown(), ...getNetworkDropdownOptions()];

  const fetchJobs = async () => {
    const sanitizedQuery = { ...queryData, page_size: PAGE_SIZE };
    // swap network name for ID using NetworkIdDictionary
    const networkConfig = NetworksData[queryData.network];
    if (networkConfig) {
      sanitizedQuery.network_id = networkConfig.id;
    }
    setErrorMessage('');
    setIsLoading(true);
    try {
      const jobs = await getReconcileJobs(sanitizedQuery);
      setRows(jobs);
    } catch (e) {
      console.error(e);
      setErrorMessage(e.error?.ErrorMessage || e.message || 'An unknown error has occurred while fetching jobs.');
    }
    setIsLoading(false);
  };

  const retryFailedJob = async id => {
    setRetryError('');
    setIsRetrying(true);
    try {
      const retriedJob = await retryReconcileJob({ id });
      if (retriedJob) {
        fetchJobs();
      }
    } catch (e) {
      console.error(e);
      setRetryError(e.error?.ErrorMessage || e.message || 'An unknown error has occurred while retrying jobs.');
    } finally {
      setIsRetrying(false);
    }
  };

  const handleChange = (e, { name, value }) => {
    setQueryData({ ...queryData, [name]: value });
  };

  const goToJob = id => {
    history.push(`/reconciliation/${id}`);
  };

  const getStatus = (bankRef, id, status) => {
    // Show retry button only for selected failed jobs if its bankRef does not include 'NAReconcile'
    if (showRetry && status === 'FAILED' && !bankRef.includes('NAReconcile')) {
      return (
        <>
          {status}
          <Button
            disabled={isRetrying}
            className="grey-rounded-button !ml-2 !text-sm !py-1 !px-3"
            onMouseEnter={() => setShowRetry(true)}
            onClick={() => {
              retryFailedJob(id);
            }}
          >
            Retry
          </Button>
        </>
      );
    }
    return status;
  };

  useEffect(() => {
    fetchJobs();
  }, [queryData]);

  if (errorMessage || retryError) {
    return (
      <div>
        {errorMessage && <p>{errorMessage}</p>}
        {retryError && <p>{retryError}</p>}
      </div>
    );
  }

  if (isLoading || isRetrying) {
    return (
      <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }

  return (
    <>
      <Form className="get-reconcile-jobs px-5" autoComplete="off">
        <Form.Group className="justify-between text-md">
          <Form.Dropdown
            label="Network"
            name="network"
            options={networkDropdownOptions}
            onChange={handleChange}
            value={queryData.network}
            search
            selection
            placeholder="Select Network"
            icon={<i className="dropdown icon text-wildfire-orange" />}
          />
          <PaginateControl
            page={queryData.page}
            prevDisabled={queryData.page === 1 || isLoading}
            nextDisabled={isLoading}
            onPrev={() => setQueryData(data => ({ ...data, page: data.page - 1 }))}
            onNext={() => setQueryData(data => ({ ...data, page: data.page + 1 }))}
          />
        </Form.Group>
      </Form>
      <div className="px-5 pt-2.5 pb-5 max-w-[96vw] overflow-x-scroll">
        <Table celled selectable>
          <thead className="text-sm font-bold text-center hidden md:!table-header-group whitespace-nowrap">
            <tr>
              <th>Id</th>
              <th>Network</th>
              <th>Paid Date</th>
              <th>Amounts</th>
              <th>Status</th>
              <th>Item Counts</th>
              <th>Created Date</th>
              <th>Modified Date</th>
              <th>Job Details</th>
            </tr>
          </thead>
          <tbody className="text-sm whitespace-nowrap">
            {rows.map(
              ({
                Id,
                NetworkId,
                Currency,
                Date,
                Status,
                CreatedDate,
                ModifiedDate,
                Amount,
                BankAmount,
                BankRef,
                Started,
                Success,
                Failed,
              }) => {
                const networkName = getNetworkName(NetworkId);
                const getAmount =
                  Currency === 'USD' ? toPrettyAmount(Amount) : `${toPrettyAmount(BankAmount)} (${Amount} ${Currency})`;

                return (
                  <tr
                    key={Id}
                    onMouseEnter={() => {
                      Status === 'FAILED' ? setShowRetry(true) : setShowRetry(false);
                    }}
                    onMouseLeave={showRetry ? () => setShowRetry(false) : null}
                  >
                    <td>{Id}</td>
                    <td title={networkName}>{networkName}</td>
                    <td>{toPrettyTime(Date)}</td>
                    <td>{getAmount}</td>
                    <td className="min-w-[131px]">{getStatus(BankRef, Id, Status)}</td>
                    <td>{ItemsColumn({ Started, Success, Failed })}</td>
                    <td>{toPrettyTime(CreatedDate, true)}</td>
                    <td>{toPrettyTime(ModifiedDate, true)}</td>
                    <td className="!text-left md:!text-center">
                      <Button
                        className="grey-rounded-button !text-sm !py-2.5 !px-5 !whitespace-nowrap !mr-0"
                        onClick={() => {
                          goToJob(Id);
                        }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default GetReconcileJobs;

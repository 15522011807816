import React, { FC, useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { Table } from 'semantic-ui-react';
import './NetworkDashboard.css';

interface NetworksRes {
  ID: number;
  Name: string;
  AffiliateCode: string;
  Disabled: boolean;
  NetworkPublisherID: number;
  TemplateURL: string;
}

interface NetworkDashboardProps {
  getNetworks: () => Promise<NetworksRes[]>;
}

const NetworkDashboard: FC<React.PropsWithChildren<NetworkDashboardProps>> = ({ getNetworks }) => {
  const [networkData, setNetworkData] = useState<NetworksRes[]>([]);
  const [error, setError] = useState('');

  const tableHeaders = (
    <>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>TemplateURL</Table.HeaderCell>
    </>
  );

  const tableRows = networkData.map(({ ID, Name, TemplateURL }) => {
    return (
      <Table.Row key={ID}>
        <Table.Cell>{ID}</Table.Cell>
        <Table.Cell>{Name}</Table.Cell>
        <Table.Cell>{TemplateURL}</Table.Cell>
      </Table.Row>
    );
  });

  useEffect(() => {
    const fetchNetworks = async () => {
      try {
        const res = await getNetworks();
        if (!Array.isArray(res)) throw new Error('Failed to fetch networks, please try logging out and back in again.');
        setNetworkData(res);
        setError('');
      } catch (error) {
        if (typeof error === 'string') {
          setError(error);
        } else if (error instanceof Error) {
          setError(capitalize(error.message) || 'Unknown error has occurred.');
        } else {
          setError('Unknown error has occurred.');
        }
      }
    };

    fetchNetworks();
  }, [getNetworks]);

  return (
    <>
      <h3 className="section-title">Network Dashboard</h3>
      {error ? (
        <div className="error">{error}</div>
      ) : (
        <div className="network-table-container">
          <Table celled>
            <Table.Header>
              <Table.Row>{tableHeaders}</Table.Row>
            </Table.Header>
            <Table.Body>{tableRows}</Table.Body>
          </Table>
        </div>
      )}
    </>
  );
};

export default NetworkDashboard;

import React, { useMemo, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import { Breadcrumb, Button, Table, Dimmer, Loader } from 'semantic-ui-react';
import { getNetworkName, toPrettyAmount, getEmptyDropdown } from './helpers';
import PaginateControl from './PaginateControl';
import { toPrettyTime } from '../../helpers';

const PAGE_SIZE = 25;

const StatusColumn = ({ status, value }) => {
  if (!value) {
    return null;
  }

  const { Count, TotalSales, TotalCommissions } = value;
  const itemWord = Count === 1 ? 'item' : 'items';
  return (
    <div className="mr-8">
      <div
        className={`font-semibold text-sm mb-1 ${status === 'Success' ? 'text-success-green' : status === 'Failed' ? 'text-failed-red' : ''}`}
      >
        {status}
      </div>
      <div className="text-xs">
        <span>
          {Count} {itemWord}
        </span>
        <br />
        <span>Sales: {toPrettyAmount(TotalSales)}</span>
        <br />
        <span>Commissions: {toPrettyAmount(TotalCommissions)}</span>
      </div>
    </div>
  );
};

const ReconciliationJob = ({ id, getReconcileJob, getReconcileJobMerchants }) => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rows, setRows] = useState([]);
  const [job, setJob] = useState({});
  const [merchantOptions, setMerchantOptions] = useState([getEmptyDropdown()]);

  const networkName = useMemo(() => getNetworkName(job.NetworkId), [job.NetworkId]);

  const statusOptions = [
    {
      key: 'all',
      text: 'All',
      value: 'all',
    },
    {
      key: 'success',
      text: 'Success',
      value: 'success',
    },
    {
      key: 'failed',
      text: 'Failed',
      value: 'failed',
    },
    {
      key: 'started',
      text: 'Started',
      value: 'started',
    },
  ];

  const breadCrumbSections = [
    { key: 'reconciliation', content: 'Reconciliation', link: true, as: Link, to: '/reconciliation' },
    { key: networkName, content: networkName, active: true },
  ];

  useEffect(() => {
    const fetchJobData = async () => {
      setErrorMessage('');
      setIsLoading(true);
      try {
        const statusForRequest = status === 'all' ? null : status;
        const { Job, Merchants } = await getReconcileJob({ id, page, page_size: PAGE_SIZE, status: statusForRequest });
        setJob(Job);
        setRows(Merchants || []);
      } catch (e) {
        console.error(e);
        setErrorMessage(e.error?.ErrorMessage || e.message || 'An unknown error has occurred while fetching jobs.');
      }
      setIsLoading(false);
    };
    fetchJobData();
  }, [page, status, id]);

  useEffect(() => {
    const fetchJobMerchants = async () => {
      try {
        const merchants = await getReconcileJobMerchants({ id });
        const options = merchants.map(({ Name, XID }) => ({
          key: XID,
          text: Name,
          value: XID,
        }));
        setMerchantOptions([getEmptyDropdown(), ...options]);
      } catch (e) {
        console.error(e);
      }
    };
    fetchJobMerchants();
  }, [id]);

  const handleStatusChange = (e, { value }) => {
    setStatus(value);
    setPage(1);
  };

  const handleMerchantChange = (e, { value }) => {
    history.push(`/reconciliation/${id}/merchant/${value}`);
  };

  const goToJobItems = mid => {
    history.push(`/reconciliation/${id}/merchant/${mid}`);
  };

  if (errorMessage) {
    return (
      <div>
        <p>{errorMessage}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Dimmer active inverted>
        <Loader>Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <>
      <div className="flex justify-between items-baseline flex-wrap w-full">
        <h1 className="font-title w-full">{networkName}</h1>
        <Breadcrumb icon="right angle" className="!mb-5" sections={breadCrumbSections} />
        <div className="flex mb-5 rounded-md w-full flex-nowrap">
          <div className="px-8 py-5 bg-white mr-2 rounded-md hover:bg-gray-50">
            <p>Paid Date:</p>
            <b>{toPrettyTime(job.Date)}</b>
          </div>
          <div className="px-8 py-5 bg-white mx-2 rounded-md hover:bg-gray-50">
            <p>Amounts:</p>
            <b>{toPrettyAmount(job.Amount)}</b>
          </div>
          <div className="px-8 py-5 bg-white mx-2 rounded-md hover:bg-gray-50">
            <p>Items:</p>
            <b>{job.NumItems}</b>
          </div>
          <div className="px-8 py-5 bg-white mx-2 rounded-md hover:bg-gray-50">
            <p>Status:</p>
            <b>{job.Status}</b>
          </div>
          <div className="px-8 py-5 bg-white mx-2 rounded-md hover:bg-gray-50">
            <p>Import Attempts:</p>
            <b>{job.RetryCount}</b>
          </div>
        </div>
      </div>
      <div className="bg-white p-8 mb-5 rounded-md w-full">
        <Form className="get-reconcile-job" autoComplete="off">
          <Form.Group className="justify-between items-end m-0">
            <div className="flex">
              <Form.Dropdown
                label="Merchants"
                name="merchants"
                className="!mr-3"
                options={merchantOptions}
                onChange={handleMerchantChange}
                search
                selection
                placeholder="Select Merchant"
                icon={<i className="dropdown icon text-wildfire-orange" />}
              />
              <Form.Dropdown
                label="Status"
                name="status"
                options={statusOptions}
                onChange={handleStatusChange}
                value={status}
                selection
                icon={<i className="dropdown icon text-wildfire-orange" />}
              />
            </div>
            <PaginateControl
              page={page}
              prevDisabled={page === 1 || isLoading}
              nextDisabled={isLoading}
              onPrev={() => setPage(pageNo => pageNo - 1)}
              onNext={() => setPage(pageNo => pageNo + 1)}
            />
          </Form.Group>
        </Form>
        <Table celled>
          <thead className="text-sm font-bold text-center">
            <tr>
              <th>Merchant</th>
              <th>Status Details</th>
              <th>Merchant Details</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {rows.map(({ MerchantName, MerchantXID, Data }) => {
              return (
                <tr key={MerchantXID}>
                  <td title={MerchantName}>{MerchantName}</td>
                  <td className="flex flex-nowrap">
                    {Data.Started && <StatusColumn status="Started" value={Data.Started} />}
                    {Data.Success && <StatusColumn status="Success" value={Data.Success} />}
                    {Data.Failed && <StatusColumn status="Failed" value={Data.Failed} />}
                  </td>
                  <td className="!text-left md:!text-center">
                    <Button
                      className="grey-rounded-button !text-sm !py-2.5 !px-5 !mr-0"
                      onClick={() => {
                        goToJobItems(MerchantXID);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ReconciliationJob;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader, Pagination } from 'semantic-ui-react';
import { getBonusPaymentsList } from 'actions/reconciles/reconciles';
import { findErrorMessage } from 'helpers';
import BonusPaymentsListTable from './BonusPaymentsListTable';
import { BonusPaymentsListResponse, BonusPayment } from './types';

interface BonusPaymentListProps {
  getBonusPaymentsList: (args: { pageNumber: number; status: string }) => Promise<BonusPaymentsListResponse>;
}

const BonusPaymentList = ({ getBonusPaymentsList }: BonusPaymentListProps) => {
  const [currentBonusPaymentsList, setCurrentBonusPaymentsList] = useState<BonusPayment[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isListLoading, setIsListLoading] = useState(false);
  const [listError, setListError] = useState('');

  const handlePaginationChange = (e: React.MouseEvent<HTMLAnchorElement>, { activePage }: any) => {
    setActivePage(activePage);
  };

  useEffect(() => {
    const fetchBonusPaymentsList = async () => {
      try {
        setIsListLoading(true);
        const response = await getBonusPaymentsList({ pageNumber: activePage, status: 'PENDING' });

        setTotalPages(response.totalPages || 1);
        setCurrentBonusPaymentsList(response.bonusPayments || []);
      } catch (error) {
        setListError('Failed to retrieve bonus payments list');
        console.error(findErrorMessage(error));
      } finally {
        setIsListLoading(false);
      }
    };

    fetchBonusPaymentsList();
  }, [activePage]);

  const showEmptyListMessage = !Boolean(currentBonusPaymentsList.length) && !isListLoading && !Boolean(listError);
  const showErrorMessage = Boolean(listError) && !isListLoading;
  return (
    <div className="font-montserrat">
      <div className="flex justify-between items-center mb-4">
        <h2 className="m-0">Bonus Payments</h2>
        <Pagination
          activePage={activePage}
          onPageChange={handlePaginationChange}
          totalPages={totalPages}
          data-testid="bonus-payments-list-pagination"
        />
      </div>
      <div className="p-5 bg-white rounded-md shadow-md overflow-x-scroll">
        <BonusPaymentsListTable bonusPayments={currentBonusPaymentsList} />
        {showEmptyListMessage && (
          <div className="flex justify-center items-center w-full">
            <p>
              {totalPages === 1
                ? 'There are currently no bonus payments'
                : 'There is no bonus payments for selected page'}
            </p>
          </div>
        )}
        {showErrorMessage && (
          <div className="flex justify-center items-center w-full">
            <p className="text-red-600">{listError}</p>
          </div>
        )}
        {isListLoading && <Loader active size="massive" inline="centered" data-test-id="bonus payments list loader" />}
      </div>
    </div>
  );
};

const mapActionsToProp = {
  getBonusPaymentsList,
};

export default connect(null, mapActionsToProp)(BonusPaymentList);

import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

const PaginateControl = ({ prevDisabled, nextDisabled, onPrev, onNext, page }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '1rem' }}>
        <b>Page {page}</b>
      </span>
      <Button
        className="!flex !justify-center !align-center !text-base !rounded-[30px] !w-[30px] !h-[42px] hover:!bg-wildfire-orange hover:!text-white"
        disabled={prevDisabled}
        onClick={onPrev}
      >
        <Icon className="m-0" name="triangle left" />
      </Button>
      <Button
        className="!flex !justify-center !align-center !text-base !rounded-[30px] !w-[30px] !h-[42px] hover:!bg-wildfire-orange hover:!text-white"
        disabled={nextDisabled}
        onClick={onNext}
      >
        <Icon className="m-0" name="triangle right" />
      </Button>
    </div>
  );
};

export default PaginateControl;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getFilterOptions } from 'actions/salesDashboard/dashboardStats';
import { findErrorMessage } from 'helpers';
import { AppOption } from './FindDeviceIDForm';
import FindDeviceIDForm from './FindDeviceIDForm';

interface ApplicationItem {
  ID: number;
  Name: string;
}

interface FindDeviceIDProps {
  getFilterOptions: (category: string) => Promise<ApplicationItem[]>;
}

const FindDeviceID = ({ getFilterOptions }: FindDeviceIDProps) => {
  const [appOptions, setAppOptions] = useState<AppOption[]>([]);
  const [isFetchingAppOptions, setIsFetchingAppOptions] = useState(false);
  const [hasAppOptionsError, setHasAppOptionsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getApplicationOptions = async () => {
      try {
        setIsFetchingAppOptions(true);
        const response = await getFilterOptions('application');
        const newDropdownOptions = response.map((application: ApplicationItem) => {
          return {
            key: application.ID,
            text: `${application.ID}. ${application.Name}`,
            name: application.Name,
            value: application.ID.toString(),
          };
        });

        setAppOptions(newDropdownOptions);
      } catch (error) {
        const errorMessage = 'Failed to get application options.';
        toast.error(errorMessage);
        setErrorMessage(findErrorMessage(error) || 'Unknown error has occurred.');
        setHasAppOptionsError(true);
      } finally {
        setIsFetchingAppOptions(false);
      }
    };

    getApplicationOptions();
  }, []);

  return (
    <div className="flex justify-center flex-col items-center align mb-7">
      <h2>Find Device ID</h2>
      <FindDeviceIDForm
        appOptions={appOptions}
        isFetchingAppOptions={isFetchingAppOptions}
        hasAppOptionsError={hasAppOptionsError}
        errorMessage={errorMessage}
      />
    </div>
  );
};

const mapActionsToProps = {
  getFilterOptions,
};

export default connect(null, mapActionsToProps)(FindDeviceID);

import React, { useEffect, useRef, useState } from 'react';
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Checkbox, Icon } from 'semantic-ui-react';
import {
  applicationColors,
  networkColors,
  applicationsToIgnore,
  networksToIgnore,
} from '../../pages/SalesDashboard/Constants';

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Filler, Legend);

let chart;

const StatsChart = ({ chartStats, timeLabel, groupBy, chartMetric, chartType, setChartType, filterItems }) => {
  const chartContainer = useRef(null);
  const [appsHidden, setAppsHidden] = useState(false);

  const transformColor = str => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  };

  const transformStats = (stats, groupBy, filterItems) => {
    // reset the data arrays before processing the new incoming stats
    const ignore = groupBy === 'network' ? networksToIgnore : applicationsToIgnore;
    const colorsMap = groupBy === 'network' ? networkColors : applicationColors;
    const chartItems = groupBy === 'network' ? filterItems.networks : filterItems.applications;

    const dataSets = chartItems
      .filter(item => !ignore[item.key])
      .map(item => {
        const bgColor = colorsMap[item.key] || transformColor(item.name);

        return {
          id: item.key,
          label: item.name.replace(/clients|client/i, ''),
          data: [],
          backgroundColor: bgColor,
          borderColor: chartType === 'area' ? 'black' : bgColor,
          pointBorderColor: chartType === 'area' ? 'black' : bgColor,
          pointRadius: 4,
          fill: chartType === 'area' ? true : false,
          tension: chartType === 'area' ? 0.3 : 0,
          borderWidth: chartType === 'area' ? 1 : 3,
        };
      });

    const transformedStats = {
      labels: [],
      datasets: dataSets,
    };

    stats.forEach(stat => {
      const { ReportDate, ID } = stat; //Can also destruct AppName if needed
      const date = ReportDate.slice(0, 10);

      //add new date to labels array if it's a new date
      if (!transformedStats.labels.includes(date)) {
        transformedStats.labels.push(date);
      }

      const dateIndex = transformedStats.labels.indexOf(date);
      const foundApp = transformedStats.datasets.find(item => item.id === ID);
      if (foundApp) {
        foundApp.data[dateIndex] = Number(stat[chartMetric.key]);
      }
    });

    // for the populated datasets, fill in any empty indexes with 0
    transformedStats.datasets.forEach(dataSet => {
      if (!dataSet.data.length) {
        return;
      }

      for (let i = 0; i < transformedStats.labels.length; i++) {
        if (!dataSet.data[i]) {
          dataSet.data[i] = 0;
        }
      }
    });

    // Limits Chart to show Top 20 Applications in GrossCommissions
    if (groupBy === 'application') {
      transformedStats.datasets = transformedStats.datasets
        .sort((a, b) => {
          const aSum = a.data.reduce((a, b) => Number(a) + Number(b), 0);
          const bSum = b.data.reduce((a, b) => Number(a) + Number(b), 0);

          return bSum - aSum;
        })
        .slice(0, 20);
    }

    return transformedStats;
  };

  const chartConfig = {
    type: 'line',
    data: transformStats(chartStats, groupBy, filterItems),
    options: {
      spanGaps: true,
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          fullWidth: true,
          labels: {
            fontColor: 'rgb(0,0,0)',
            boxWidth: 20,
            padding: 20,
            borderWidth: 1,
            font: {
              size: 12,
            },
          },
        },
        tooltip: {
          mode: 'index',
          itemSort:
            chartType === 'area' ? (a, b) => b.datasetIndex - a.datasetIndex : (a, b) => b.parsed.y - a.parsed.y,
          callbacks: {
            label: context => {
              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                if (chartMetric.unit === '$') {
                  label += new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(context.parsed.y);
                } else if (chartMetric.unit === '%') {
                  label += `${context.parsed.y.toFixed(2)}%`;
                } else {
                  label += context.parsed.y;
                }
              }
              return label;
            },
          },
        },
        pointBackgroundColor: '#fff',
        radius: 10,
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: true,
            text: groupBy === 'network' ? 'Date' : timeLabel,
          },
        },
        y: {
          beginAtZero: true,
          stacked: chartType === 'area' ? true : false,
          title: {
            display: true,
            text: `${chartMetric.text} (${chartMetric.unit})`,
          },
        },
      },
    },
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (typeof chart !== 'undefined') {
        chart.destroy();
      }
      chart = new Chart(chartContainer.current, chartConfig);
      chart.data.datasets.forEach(item => {
        item.hidden = appsHidden;
      });
      chart.update();
    }
  }, [chartContainer, chartStats, chartType, chartMetric, chartConfig, appsHidden, timeLabel]);

  return (
    <div className="sales-dashboard-chart-container">
      <h3>
        {chartMetric.text} By {groupBy === 'network' ? 'Network' : 'Application'}
      </h3>
      {chartStats.length ? (
        <div className="chart-wrapper">
          <div className="chart-toggle">
            <Icon className="chart area " size="large" style={{ margin: '0 5px' }} />

            <Checkbox
              toggle
              checked={chartType === 'line'}
              onClick={() => {
                if (chartType === 'line') {
                  setChartType('area');
                } else if (chartType === 'area') {
                  setChartType('line');
                }
              }}
            />

            <Icon className="chart line chart-icon" size="large" style={{ margin: '0 5px' }} />
          </div>
          <div className="chart-scroll-wrapper">
            <div className="chart-area-wrapper">
              <div className="chart-toggle-apps">
                <div>Toggle All Applications</div>
                <Checkbox toggle onClick={() => setAppsHidden(!appsHidden)} defaultChecked={true} />
              </div>
              <canvas id="stats-chart" ref={chartContainer} />
            </div>
          </div>
        </div>
      ) : (
        <div>There are no stats for the specified parameters.</div>
      )}
    </div>
  );
};

export default React.memo(StatsChart);

import { wfUserAgent } from 'helpers';
import { wfGet, wfPut, wfPost, wfDelete } from '../../actions';

export const getMerchant = id => async dispatch => {
  try {
    const merchant = await dispatch(wfGet(`/api/merchant/${id}`));
    return merchant;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMerchants = args => async dispatch => {
  const { sortBy = '', sortOrder = 'desc', limit = 100, minScore = 0 } = args;
  try {
    const response = await dispatch(
      wfGet(`/api/merchants?sort_by=${sortBy}&sort_order=${sortOrder}&limit=${limit}&min_score=${minScore}`),
    );
    return response?.Merchants || [];
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMerchantHistory = id => async dispatch => {
  try {
    const history = await dispatch(wfGet(`/api/merchant/${id}/history`));
    return history;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMerchantCategoryHistory = id => async dispatch => {
  try {
    const history = await dispatch(wfGet(`/api/merchant/${id}/history/category`));
    return history;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMerchantImageHistory = id => async dispatch => {
  try {
    const history = await dispatch(wfGet(`/api/merchant/${id}/history/image`));
    return history;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMerchantNetworkMerchants = id => async dispatch => {
  try {
    const networkMerchants = await dispatch(wfGet(`/api/merchant/${id}/network-merchant`));
    return networkMerchants;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateMerchant = updatedMerchant => async dispatch => {
  try {
    await dispatch(wfPut(`/api/merchant/${updatedMerchant.ID}`, updatedMerchant));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMerchantKeywords = id => async dispatch => {
  try {
    const keywords = await dispatch(wfGet(`/api/merchant/${id}/keyword`));
    return keywords;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const upsertMerchantKeyword = (id, keyword) => async dispatch => {
  try {
    await dispatch(wfPut(`/api/merchant/${id}/keyword`, keyword));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteMerchantKeyword =
  (id, { KeywordName }) =>
  async dispatch => {
    const body = {
      KeywordName,
      Disabled: true,
    };
    try {
      await dispatch(wfPut(`/api/merchant/${id}/keyword`, body));
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getCategoryOptions = id => async dispatch => {
  try {
    const merchantCategoryOptions = await dispatch(wfGet(`/api/merchant/${id}/category_options`));
    return merchantCategoryOptions;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMerchantCategories = id => async dispatch => {
  try {
    const merchantCategory = await dispatch(wfGet(`/api/merchant/${id}/category`));
    return merchantCategory;
  } catch (error) {
    return Promise.reject(error);
  }
};
// important to use body as an argument so json data is passed along
// wfFetch
export const updateMerchantCategories = (id, body) => async dispatch => {
  try {
    await dispatch(wfPut(`/api/merchant/${id}/category`, body));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const searchMerchants = query => async dispatch => {
  try {
    const merchants = await dispatch(wfGet(`/api/merchant?q=${query}`));
    return merchants;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addMerchant = name => async dispatch => {
  try {
    const userEmail = localStorage.getItem('userEmail');
    const ModifiedAuthor = userEmail ? userEmail : 'merchant admin nm create';

    const newMerchant = await dispatch(wfPost(`/api/merchant`, { name, ModifiedAuthor }));
    return newMerchant;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addMerchantImage = (id, formData) => async dispatch => {
  try {
    const userEmail = localStorage.getItem('userEmail');
    const ModifiedAuthor = userEmail ? userEmail : 'merchant admin image create';
    formData.append('ModifiedAuthor', ModifiedAuthor);
    await dispatch(wfPost(`/api/merchant/${id}/image`, { formData }));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteMerchantImage = (id, merchantImageId) => async dispatch => {
  try {
    const userEmail = localStorage.getItem('userEmail');
    const modifiedAuthor = userEmail ? userEmail : wfUserAgent();
    await dispatch(wfDelete(`/api/merchant/${id}/image/${merchantImageId}`, { modifiedAuthor }));
  } catch (error) {
    return Promise.reject(error);
  }
};

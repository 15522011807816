export function isNetworkMerchant(obj) {
  const pattern = {
    ID: 'number',
    Name: 'string',
    XID: 'string',
    NetworkID: 'number',
    MerchantID: 'number',
    SearchURL: 'string',
    URL: 'string',
    BannerID: 'string',
    Disabled: 'boolean',
    DisabledReason: 'string',
    Note: 'string',
    DefaultRate: 'string',
    MaxRate: 'string',
    RedirectCode: 'string',
    Locks: 'object',
    Countries: 'object',
    DeeplinkDisabled: 'boolean',
    CouponDisabled: 'boolean',
    ExistingCustomerRateDisabled: 'boolean',
    IsITPCompliant: 'boolean',
    CreatedDate: 'string',
  };

  for (let key in pattern) {
    if (!(key in obj)) {
      return false;
    }
    if (typeof obj[key] !== pattern[key]) {
      return false;
    }
  }
  return true;
}

import { SalesDashDateTruncCategory } from '../types';

// Returns formatted values for y-axis graph label
export const generateNumberAbbreviationLabel = (tickValue: number, maxValueLength: number): string => {
  if (tickValue === 0) {
    return `${tickValue}`;
  }

  // HighestValue < $999
  if (maxValueLength <= 3) {
    return `${tickValue}`;
  }

  // HighestValue < $999k
  if (maxValueLength <= 6) {
    const newTickValue = tickValue / 1000;
    return `${newTickValue}k`;
  } else {
    // HighestValue > $1m
    const newTickValue = tickValue / 1000000;
    return `${newTickValue}m`;
  }
};

// Get the difference between two dates in days
export const getDateDiffInDays = (startDate: string, endDate: string): number => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

// Get the default date intervals for the given date range
export const getDefaultDateInterval = (startDate: string | null, endDate: string | null): SalesDashDateTruncCategory => {
  let defaultDateInterval: SalesDashDateTruncCategory = 'day';

  if (startDate?.length && endDate?.length) {
    const dateRange = getDateDiffInDays(startDate, endDate);

    // If date range is less than two weeks
    // show data in days
    if (dateRange <= 14) {
      defaultDateInterval = 'day';
      // If date range is greater than two weeks but less than a month
      // show data in weeks
    } else if (dateRange > 14 && dateRange <= 30) {
      defaultDateInterval = 'week';
      // If date range is greater than a year but less than 3 years
      // show data in quarters
    } else if (dateRange > 30 && dateRange <= 365) {
      defaultDateInterval = 'month';
    } else if (dateRange > 365 && dateRange <= 365 * 3) {
      defaultDateInterval = 'quarter';
      // If date range is greater than 3 years
      // show data in years
    } else if (dateRange > 365 * 3) {
      defaultDateInterval = 'year';
    } // Default is to show data in months
  }

  return defaultDateInterval;
};

export const formatToMoney = (number: number) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);

export const getUniqueObjectsByProps = (arr: Record<string, any>[], props: string[]): Record<string, any>[] => {
  const unique = new Map<string, Record<string, any>>();
  arr.forEach(obj => {
    const key = props.map(prop => obj[prop]).join('-');

    if (!unique.has(key)) {
      unique.set(
        key,
        props.reduce(
          (acc, prop) => {
            acc[prop] = obj[prop];
            return acc;
          },
          {} as Record<string, any>,
        ),
      );
    }
  });

  return Array.from(unique.values());
};

// Checks for arrays that only consists of 0's
export const allNumbersAreZero = (arr: number[]) => arr.every(number => number === 0);

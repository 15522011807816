import React, { useState } from 'react';
import { Accordion, Icon, Segment } from 'semantic-ui-react';
import { toPrettyTime } from '../../helpers';

const CloudProfile = ({ profile }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <h4>Meta</h4>
      <Segment>
        <p>
          Created Date - {toPrettyTime(profile.Metadata.Created)}
          <br></br>
          URL - {profile.URL}
        </p>
      </Segment>
      <h4>Commission Summary</h4>
      <Segment>
        <p>
          {`Pending Amount -
          ${parseFloat(profile.CommissionStatsSummary.PendingAmount.Amount).toFixed(2)}`}
          <br></br>
          {`Ready Amount -
          ${parseFloat(profile.CommissionStatsSummary.ReadyAmount.Amount).toFixed(2)}`}
          <br></br>
          {`Paid Amount -
          ${parseFloat(profile.CommissionStatsSummary.PaidAmount.Amount).toFixed(2)}`}
        </p>
      </Segment>

      <h4>Commission Details - by most recent</h4>
      <Accordion className="comm-details" styled>
        {profile.CommissionStatsDetail?.map((comm, idx) => {
          return (
            <React.Fragment key={idx}>
              <Accordion.Title active={activeIndex === idx} index={idx} onClick={handleClick}>
                <Icon name="dropdown" />
                {comm.Merchant}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === idx}>
                <p>
                  ID - {comm.ID}
                  <br></br>
                  Status - {comm.Status}
                  <br></br>
                  Date - {toPrettyTime(comm.Date)}
                  <br></br>
                  Amount - {parseFloat(comm.Amount.Amount).toFixed(2)}
                  {comm.Amount.Currency}
                </p>
              </Accordion.Content>
            </React.Fragment>
          );
        })}
      </Accordion>
    </>
  );
};

export default CloudProfile;

import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { SalesDashChartMetric, SalesDashStatsData, SalesDashTableClickedColumn } from './types';

interface SalesDashTableHeaderProps {
  columns: SalesDashChartMetric[];
  sortBy: string;
  sortOrder: string;
  onSort: (clickedColumn: SalesDashTableClickedColumn) => void;
  chartMetric: SalesDashChartMetric;
  setChartMetric: (chartMetric: SalesDashChartMetric) => void;
  groupByLabel: string;
  isGroupedByDate: boolean;
  isGroupByDateToggleEnabled: boolean;
}

const StatsTableHeader = ({
  columns,
  sortBy,
  sortOrder,
  onSort,
  chartMetric,
  setChartMetric,
  groupByLabel,
  isGroupedByDate,
  isGroupByDateToggleEnabled,
}: SalesDashTableHeaderProps) => {
  const groupByLabelHeader: SalesDashChartMetric = {
    key: groupByLabel as keyof SalesDashStatsData,
    text: groupByLabel,
    value: 'groupByLabel',
    unit: 'groupByLabel',
  };

  const dateHeader: SalesDashChartMetric = {
    key: 'Date' as keyof SalesDashStatsData,
    text: 'Date',
    value: 'groupByLabel',
    unit: 'groupByLabel',
  };

  const nameHeader = {
    key: 'Name' as keyof SalesDashStatsData,
    text: 'Name',
    value: 'name',
    unit: 'name',
  };

  const renderContent = (column: SalesDashChartMetric) => {
    const isActive: boolean = sortBy === column.key;

    return (
      <Table.HeaderCell key={column.key} className="headerCell align-top" direction={isActive ? sortOrder : 'asc'}>
        <div
          className={`active flex items-center justify-center text-sm cursor-pointer ${isActive ? 'font-bold' : 'font-medium'}`}
          onClick={() => {
            onSort(column.key);
          }}
        >
          <span>
            {column.text}
            <Icon className={`!ml-2 ${isActive ? (sortOrder === 'asc' ? 'sort up' : 'sort down') : 'sort'}`} />
          </span>
        </div>
      </Table.HeaderCell>
    );
  };

  const renderColumns = () => columns.map(column => renderContent(column));

  // Render chart icon for all columns, except for Date and ClickCount
  const renderIcons = () => {
    const columnsCopy = [...columns];
    if (isGroupedByDate || isGroupByDateToggleEnabled) {
      columnsCopy.unshift(groupByLabelHeader);
    } else {
      columnsCopy.unshift(groupByLabelHeader, nameHeader);
    }
    return columnsCopy.map(column => {
      /** Render empty cell without the chartIcon for columns that won't affect chart **/
      /** (Date, Application ID, Merchant ID, Network ID, Name) **/
      return column.key === 'Date' || column.value === 'groupByLabel' || column.value === 'name' ? (
        <Table.HeaderCell key={column.key} className="headerCell align-top relative cursor-pointer"></Table.HeaderCell>
      ) : (
        <Table.HeaderCell
          key={column.key}
          className="headerCell chartIcon"
          onClick={() => {
            setChartMetric(column);
          }}
        >
          <span className="iconCell flex justify-center align-center">
            <Icon
              className={`cursor-pointer ${chartMetric?.key === column.key ? 'inverted' : ''}`}
              circular
              name="area graph"
              color="orange"
              size="small"
            />
          </span>
        </Table.HeaderCell>
      );
    });
  };

  return (
    <Table.Header id="tableHeader" className="sticky top-0 z-10 bg-[#f3f4f9] hidden md:!table-header-group">
      <Table.Row>
        {[
          isGroupByDateToggleEnabled ? renderContent(dateHeader) : renderContent(groupByLabelHeader),
          !isGroupedByDate && !isGroupByDateToggleEnabled && renderContent(nameHeader),
          renderColumns()
        ]}
      </Table.Row>
      <Table.Row>{renderIcons()}</Table.Row>
    </Table.Header>
  );
};

export default StatsTableHeader;

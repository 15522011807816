import { connect } from 'react-redux';
import { getUserCanEdit } from 'selectors/user';
import NetworkMerchantHotel from './NetworkMerchantHotel';
import { setLastUpdated } from '../../actions/merchantAdmin';

const mapStateToProps = state => ({
  canEdit: getUserCanEdit(state),
});

const mapActionsToProps = {
  setLastUpdated,
};

export default connect(mapStateToProps, mapActionsToProps)(NetworkMerchantHotel);

import { connect } from 'react-redux';
import ReconciliationJob from './ReconciliationJob';
import { getReconcileJob, getReconcileJobMerchants } from '../../actions/reconciles/reconciles';

const mapActionsToProps = {
  getReconcileJob,
  getReconcileJobMerchants,
};

export default connect(null, mapActionsToProps)(ReconciliationJob);

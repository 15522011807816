import { toast } from 'react-toastify';
import { SERVER_ENDPOINT } from '../constants';
import { findErrorMessage, wfUserAgent } from './index';
interface Patch {
  [key: string]: string | number | boolean;
}

export const request = async (method: string, path: string, body?: object): Promise<Response> => {
  const deviceToken = localStorage.getItem('deviceToken') || '';
  const auth0AccessToken = localStorage.getItem('accessToken');
  const encryptedId = localStorage.getItem('encryptedId') || 'No identifier set';

  if (!deviceToken) {
    return Promise.reject('Missing device token');
  }

  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-WF-UserAgent': wfUserAgent(),
      'X-WF-UserId': encryptedId,
      'X-WF-DeviceToken': deviceToken,
      Authorization: `Bearer ${auth0AccessToken}`,
    },
    credentials: 'include',
  };
  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(`${SERVER_ENDPOINT}${path}`, options);

  if (!response.ok) {
    const json = await response.json();
    toast.error(findErrorMessage(json));
    return Promise.reject(response);
  }

  return response;
};

export default {
  getNetworkMerchantHotel: (id: string): Promise<Response> => request('GET', `/api/network-merchant-hotel/${id}`),
  patchNetworkMerchantHotel: (id: string, patch: Patch): Promise<Response> =>
    request('PATCH', `/api/network-merchant-hotel/${id}`, patch),
  getNetworks: (): Promise<Response> => request('GET', '/api/networks'),
};

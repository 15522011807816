import { wfGet, wfPost, wfPut } from '../../actions';

export const getCommissionRateQueue = () => async dispatch => {
  try {
    const res = await dispatch(wfGet('/api/commission-rate-queue'));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const approveCommissionRate = rateData => async dispatch => {
  try {
    const res = await dispatch(wfPost('/api/commission-rate-queue/approve', rateData));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const reviseCommissionRate = rateData => async dispatch => {
  try {
    const res = await dispatch(wfPut('/api/commission-rate-queue/revise', rateData));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

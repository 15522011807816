import React, { useState, useEffect, SyntheticEvent } from 'react';
import { uniq, uniqBy } from 'lodash';
import { FileUpload, FileUploadSelectEvent, ItemTemplateOptions } from 'primereact/fileupload';
import { InputSwitch } from 'primereact/inputswitch';
import { Select, Modal, Icon, Loader, Dimmer, Segment, Button, ButtonProps } from 'semantic-ui-react';
import ColorSelectIcon from './ColorSelectIcon';
import { isValidImageKind } from './helpers';
import { ImageDetail, ImageKindOption, ErrorType } from './types';
import { IMAGE_KINDS } from '../../../constants';
import './AddImage.css';

const MAX_SIZE = 3;
const MARGIN = {
  FEATURED: 20,
  LOGORECT: 15,
  LOGO: 10,
};

interface AddImageProps {
  addMerchantImage: (formData: FormData) => void;
}

const imageKindOptions: ImageKindOption[] = [
  { text: IMAGE_KINDS.FEATURED.NAME, value: IMAGE_KINDS.FEATURED.NAME },
  { text: IMAGE_KINDS.LOGO.NAME, value: IMAGE_KINDS.LOGO.NAME },
  { text: IMAGE_KINDS.LOGORECT.NAME, value: IMAGE_KINDS.LOGORECT.NAME },
];

const getImageTopLeftPixelColor = (image: HTMLImageElement) => {
  // Create a temporary canvas to get color of top left pixel in image
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = image.width;
  tempCanvas.height = image.height;
  const tempCtx = tempCanvas.getContext('2d')!;
  tempCtx.drawImage(image, 0, 0);
  const topLeftPixel = tempCtx.getImageData(0, 0, 1, 1).data;
  return `rgba(${topLeftPixel[0]}, ${topLeftPixel[1]}, ${topLeftPixel[2]}, ${topLeftPixel[3] / 255})`;
};

const AddImage = ({ addMerchantImage }: AddImageProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [dimensionErrors, setDimensionErrors] = useState<ErrorType>({});
  const [generalErrors, setGeneralErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageDetails, setImageDetails] = useState<ImageDetail[]>([]);
  const [canUpload, setCanUpload] = useState(false);

  const removePreview = (file: File, callback: () => void) => {
    const imageDetailsCopy = [...imageDetails];
    const imageToRemoveIndex = imageDetailsCopy.findIndex(
      imageDetail => imageDetail?.originalImage?.name === file.name,
    );
    imageDetailsCopy.splice(imageToRemoveIndex, 1);
    setImageDetails(imageDetailsCopy);
    setDimensionErrors({});
    callback();
  };

  const dropZone = () => {
    return (
      <div className="drag-drop-contents">
        <Icon className="icon-download" name="cloud upload" />
        <span>Drag and Drop Images Here</span>
      </div>
    );
  };

  const validateUpload = () => {
    const hasCorrectDimensions = (image: ImageDetail) => {
      const { width, height, kind } = image;
      return width === IMAGE_KINDS[kind].WIDTH && height == IMAGE_KINDS[kind].HEIGHT;
    };

    if (imageDetails.every(hasCorrectDimensions)) {
      setCanUpload(true);
    }
  };

  const getImageKind = (width: number, height: number) => {
    if (width && height) {
      if (IMAGE_KINDS.FEATURED.WIDTH === width && IMAGE_KINDS.FEATURED.HEIGHT === height)
        return IMAGE_KINDS.FEATURED.NAME;
      if (IMAGE_KINDS.LOGO.WIDTH === width && IMAGE_KINDS.LOGO.HEIGHT === height) return IMAGE_KINDS.LOGO.NAME;
      if (IMAGE_KINDS.LOGORECT.WIDTH === width && IMAGE_KINDS.LOGORECT.HEIGHT === height)
        return IMAGE_KINDS.LOGORECT.NAME;
    }
    return IMAGE_KINDS.FEATURED.NAME;
  };

  const setShouldAddMargin = (fileName: string, shouldAddMargin: boolean) => {
    const image = imageDetails.filter(imageDetail => imageDetail.originalImage?.name === fileName)[0];
    resizeImage({ ...image, shouldAddMargin }, image.backgroundColorOverride || image.backgroundColor);
  };

  const resizeImage = (imageToResize: ImageDetail, bgColor: string) => {
    const imageToResizeCopy = { ...imageToResize };
    const { kind, originalImage } = imageToResizeCopy;
    if (!originalImage) return;
    const imageDetailsCopy = imageDetails.map(imageDetail =>
      imageDetail.originalImage === imageToResizeCopy.originalImage ? imageToResizeCopy : { ...imageDetail },
    );
    const targetWidth = IMAGE_KINDS[kind].WIDTH;
    const targetHeight = IMAGE_KINDS[kind].HEIGHT;
    const reader = new FileReader();
    URL.createObjectURL(originalImage);

    reader.onload = e => {
      const img = new Image();

      img.onload = () => {
        // Calculate the margin and adjust dimensions to accommodate it
        const margin = imageToResizeCopy.shouldAddMargin ? MARGIN[kind] : 0;
        const adjustedWidth = targetWidth - margin * 2;
        const adjustedHeight = targetHeight - margin * 2;

        // Calculate the scale factor while maintaining aspect ratio
        const scaleX = adjustedWidth / img.width;
        const scaleY = adjustedHeight / img.height;
        const scale = Math.min(scaleX, scaleY);

        const newWidth = Math.floor(img.width * scale);
        const newHeight = Math.floor(img.height * scale);

        // Create target canvas
        const targetCanvas = document.createElement('canvas');
        targetCanvas.width = targetWidth;
        targetCanvas.height = targetHeight;
        const targetCtx = targetCanvas.getContext('2d');

        if (targetCtx) {
          targetCtx.fillStyle = bgColor;
          targetCtx.fillRect(0, 0, targetWidth, targetHeight);

          // Draw the resized image centered in the target canvas, adjusted for margin
          const offsetX = Math.floor((targetWidth - newWidth) / 2);
          const offsetY = Math.floor((targetHeight - newHeight) / 2);
          targetCtx.drawImage(img, 0, 0, img.width, img.height, offsetX, offsetY, newWidth, newHeight);

          // Convert the final canvas to a blob directly
          targetCanvas.toBlob(blob => {
            // Convert the blob into a File object
            if (blob) {
              const newFile = new File([blob], originalImage.name, { type: originalImage.type });
              imageToResizeCopy.editedImage = newFile;
            }

            // Update dimensions without changing the original
            imageToResizeCopy.width = targetWidth;
            imageToResizeCopy.height = targetHeight;
            imageToResizeCopy.editInProgress = true;
            imageToResizeCopy.backgroundColorOverride = bgColor;
            setImageDetails(imageDetailsCopy);
          }, originalImage.type);
        }
      };

      img.onerror = () => console.error('Error loading image');
      if (typeof e.target?.result === 'string') {
        img.src = e.target.result;
      }
    };

    reader.onerror = () => console.error('FileReader error');
    reader.readAsDataURL(originalImage);
  };

  const validateImages = () => {
    setCanUpload(false);
    const newErrors: ErrorType = {};

    imageDetails.forEach(imageDetail => {
      const { kind, width, height, originalImage, editInProgress } = imageDetail;

      if (!width || !height || !originalImage) return;

      if (editInProgress && dimensionErrors[originalImage.name]) {
        newErrors[originalImage.name] = dimensionErrors[originalImage.name];
      }

      if (!(width === IMAGE_KINDS[kind].WIDTH && height === IMAGE_KINDS[kind].HEIGHT)) {
        switch (kind) {
          case 'FEATURED':
            newErrors[originalImage.name] = 'Featured images should be 660px x 380px.';
            break;
          case 'LOGO':
            newErrors[originalImage.name] = 'Logo images should be 200px x 200px.';
            break;
          case 'LOGORECT':
            newErrors[originalImage.name] = 'LogoRect images should be 260px x 200px.';
            break;
          default:
            newErrors[originalImage.name] = 'Image dimensions are invalid';
        }
      }
    });

    setDimensionErrors(newErrors);
    validateUpload();
  };

  const handleSelect = (e: FileUploadSelectEvent) => {
    const newGeneralErrors = [...generalErrors];
    const files = e.files as File[];

    // For images that are dragged into the drop zone
    if (e.originalEvent && 'dataTransfer' in e.originalEvent) {
      const allFiles: File[] = Array.from(e.originalEvent.dataTransfer?.files ?? []);

      //  Images with file size that exceeds max size is rejected but this is to display a reason for rejection
      allFiles.forEach(file => {
        if (file.size > 1024 * 1024 * MAX_SIZE) {
          newGeneralErrors.push(`Maximum upload size is ${MAX_SIZE}MB.`);
          const uniqueErrors = uniq(newGeneralErrors);
          setGeneralErrors(uniqueErrors);
        }
      });
    }

    if (files.length) {
      files.forEach(file => {
        if (!file.type.includes('image')) return;
        const objectURL = URL.createObjectURL(file); // Workaround to avoid getting errors around objectURL not being a property of type File

        if (file.type.includes('image')) {
          const image = new Image();
          image.src = objectURL;
          image.onload = () => {
            const backgroundColor = getImageTopLeftPixelColor(image);

            if (!backgroundColor) throw new Error('File is not an image');

            setImageDetails(images =>
              uniqBy(
                [
                  ...images,
                  {
                    width: image.width,
                    height: image.height,
                    originalImage: file,
                    backgroundColor,
                    kind: getImageKind(image.width, image.height),
                    ordinal: 1,
                    shouldAddMargin: false,
                    editInProgress: false,
                  },
                ],
                details => details.originalImage?.name.split('.')[0],
              ),
            );
          };
        }
      });
    }
  };

  const handleUpload = () => {
    const newErrors = [...generalErrors];
    if (imageDetails.length) {
      imageDetails.forEach(async ({ kind, ordinal, editedImage, originalImage }) => {
        const formData = new FormData();
        const imageToUpload = editedImage || originalImage;
        formData.append('kind', kind);
        formData.append('ordinal', ordinal.toString());
        formData.append('image', imageToUpload as any); // Form data expects a string or Blob but image is a File or null

        try {
          setIsLoading(true);
          await addMerchantImage(formData);
          setGeneralErrors([]);
          setImageDetails([]);
          setIsLoading(false);
          setModalOpen(false);
        } catch (err) {
          if (typeof err === 'string') {
            newErrors.push(err);
            setGeneralErrors(newErrors);
          } else if (err instanceof Error) {
            newErrors.push(err.message || 'Unknown error has occurred.');
            setGeneralErrors(newErrors);
            setIsLoading(false);
          }
        }
      });
    }
  };

  const closeGeneralError = (newError: string) => {
    const newErrors = [...generalErrors];
    const errorIndex = newErrors.findIndex(error => error === newError);
    newErrors.splice(errorIndex, 1);
    setGeneralErrors(newErrors);
  };

  const closeDimensionError = (imageName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [imageName]: _, ...remainingErrors } = dimensionErrors;
    setDimensionErrors(remainingErrors);
  };

  const confirmImageResize = (image: ImageDetail) => {
    setImageDetails(
      imageDetails.map(imageDetail =>
        imageDetail.originalImage === image.originalImage ? { ...image, editInProgress: false } : { ...imageDetail },
      ),
    );
  };

  const imageControls = (file: object, options: ItemTemplateOptions) => {
    const actualFile = file as File;
    if (!actualFile) return <></>;

    // If not an image file, remove preview image
    if (!actualFile.type.includes('image')) {
      options.onRemove({} as SyntheticEvent<Element, Event>);
    }

    const imageToDisplay = imageDetails.filter(imageDetail => imageDetail?.originalImage?.name === actualFile.name)[0];

    if (!imageToDisplay) return <></>;

    const { originalImage, editedImage, width, height, backgroundColor, shouldAddMargin, editInProgress } =
      imageToDisplay;

    const objectURL = URL.createObjectURL(editedImage || originalImage);
    const imageKind = getImageKind(width, height);

    return (
      <div className="preview-container">
        {originalImage?.name && originalImage.name in dimensionErrors && (
          <div key={originalImage.name} className="errorMessage">
            <div className="message">
              <div style={{ textAlign: 'left' }}>
                {dimensionErrors[originalImage.name]}
                <div className="resize-options-container">
                  <div className="resize-option">
                    <span style={{ marginRight: '0.25rem' }}>Select background color to resize:</span>
                    <ColorSelectIcon
                      color={backgroundColor}
                      onClick={() => resizeImage(imageToDisplay, backgroundColor)}
                    />
                    <ColorSelectIcon color="black" onClick={() => resizeImage(imageToDisplay, 'black')} />
                    <ColorSelectIcon color="white" onClick={() => resizeImage(imageToDisplay, 'white')} />
                  </div>
                  <Button
                    color="red"
                    className="resize-confirm-button"
                    disabled={!editInProgress}
                    onClick={() => confirmImageResize(imageToDisplay)}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
            <Icon
              className="close close-button"
              name="close"
              onClick={() => {
                if (originalImage?.name) closeDimensionError(originalImage.name);
              }}
            />
          </div>
        )}
        <div className="preview-row">
          <div className="preview-image">
            <img alt={originalImage?.name} role="presentation" src={objectURL} width={100} />
            <span className="image-name">{originalImage?.name}</span>
          </div>
          <div className="preview-actions">
            <div className="image-config-container">
              <Select
                required
                name="kind"
                options={imageKindOptions}
                defaultValue={imageKind}
                onChange={(e, { value }) => {
                  if (typeof value === 'string') {
                    if (isValidImageKind(value)) {
                      const newImageDetails = imageDetails.map(imageDetail =>
                        imageDetail.originalImage.name === actualFile.name
                          ? { ...imageDetail, kind: value }
                          : { ...imageDetail },
                      );
                      setImageDetails(newImageDetails);
                    }
                  }
                }}
              />
              <div className="margin-container">
                <InputSwitch
                  checked={shouldAddMargin}
                  onChange={() => setShouldAddMargin(originalImage.name, !shouldAddMargin)}
                  inputId="add-margin"
                />
                <label htmlFor="add-margin" style={{ color: 'inherit', cursor: 'pointer', marginLeft: '5px' }}>
                  Add margin
                </label>
              </div>
            </div>
            <Button circular icon="close" onClick={() => removePreview(actualFile, () => options.onRemove({} as any))} />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    validateImages();
  }, [imageDetails]);

  return (
    <Modal
      trigger={<Icon name="add" size="large" onClick={() => setModalOpen(true)} />}
      open={modalOpen}
      onClose={() => {
        setImageDetails([]);
        setDimensionErrors({});
        setGeneralErrors([]);
        setModalOpen(false);
        setIsLoading(false);
      }}
    >
      <Modal.Header>
        <Icon name="image" />
        Add Image
      </Modal.Header>
      <Modal.Content>
        <div className="form-add-image">
          {!!generalErrors.length &&
            generalErrors.map(error => (
              <div key={error} className="errorMessage" onClick={() => closeGeneralError(error)}>
                {error}
                <Icon className="close close-button" name="close" onClick={() => closeGeneralError(error)} />
              </div>
            ))}
          {isLoading ? (
            <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
              <Dimmer active inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
            </Segment>
          ) : (
            <>
              <FileUpload
                multiple
                customUpload
                name="merchant-admin-image-upload"
                accept="image/*"
                chooseLabel="Browse"
                url="api/uploader"
                maxFileSize={1024 * 1024 * MAX_SIZE}
                emptyTemplate={dropZone}
                itemTemplate={imageControls}
                onSelect={(event: FileUploadSelectEvent) => handleSelect(event)}
                uploadHandler={handleUpload}
                pt={{
                  uploadButton: {
                    root: {
                      className: canUpload ? '' : 'btn-disabled',
                      disabled: canUpload ? false : true,
                    },
                  },
                }}
              />
            </>
          )}
          <div className="disclaimer">
            <p>
              Featured images should be{' '}
              <span>
                {IMAGE_KINDS.FEATURED.WIDTH}px x {IMAGE_KINDS.FEATURED.HEIGHT}px
              </span>
              .
            </p>
            <p>
              Logo images should be{' '}
              <span>
                {IMAGE_KINDS.LOGO.WIDTH}px x {IMAGE_KINDS.LOGO.HEIGHT}px
              </span>
              .
            </p>
            <p>
              LogoRect images should be{' '}
              <span>
                <span>
                  {IMAGE_KINDS.LOGORECT.WIDTH}px x {IMAGE_KINDS.LOGORECT.HEIGHT}px
                </span>
              </span>
              .
            </p>
            <p>
              Max image size is <span>{MAX_SIZE}MB</span>.
            </p>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AddImage;

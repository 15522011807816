import { wfPost } from '../../actions';

export const makeBonusPayment = (id, form) => async dispatch => {
  try {
    const res = await dispatch(wfPost(`/api/provision/application/${id}/bonus`, { form }));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

import React, { useState } from 'react';
import { Button, Message, Icon } from 'semantic-ui-react';
import { findErrorMessage } from 'helpers';
import './MerchantStats.css';

const MerchantStats = ({ getMerchantStats }) => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const downloadCSV = () => {
    setIsLoading(true);

    const getCSV = async () => {
      try {
        return await getMerchantStats();
      } catch (err) {
        setError(findErrorMessage(err));
        return Promise.reject(err);
      }
    };

    getCSV()
      .then(({ body, headers }) => {
        const fileName = headers['content-disposition'].split('filename=')[1];
        const blob = new Blob([body], { type: 'text/csv' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName || 'stats.csv';
        a.click();
      })
      .catch(err => {
        console.error('Error fetching or processing CSV:', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      {error ? (
        <p>An unexpected error occurred: {error}</p>
      ) : isLoading ? (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header style={{ textAlign: 'left' }}>Just one second</Message.Header>
            We are fetching that content for you.
          </Message.Content>
        </Message>
      ) : (
        <div className="csv-button">
          <Button onClick={downloadCSV}>Download CSV</Button>
        </div>
      )}
    </div>
  );
};

export default MerchantStats;

import { SagaIterator } from 'redux-saga';
import { all, spawn, call } from 'redux-saga/effects';
import networkSagas from './network/sagas';

export default function* rootSaga(): SagaIterator {
  const sagas = [...networkSagas];

  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.error(error);
          }
        }
      }),
    ),
  );
}

import { findErrorMessage } from 'helpers';
import { wfPost } from '../actions';
import { LOGOUT, SET_DEVICE_TOKEN, SET_CAN_EDIT } from '../constants/actionTypes';

export const logout = () => {
  localStorage.removeItem('deviceToken');
  localStorage.removeItem('deviceId');
  localStorage.removeItem('deviceKey');

  return { type: LOGOUT };
};

export const setDeviceToken = deviceToken => ({
  type: SET_DEVICE_TOKEN,
  deviceToken,
});

export const setCanEdit = canEdit => ({
  type: SET_CAN_EDIT,
  canEdit,
});

export const getDevice = () => async dispatch => {
  try {
    const device = await dispatch(wfPost('/api/auth/device'));
    if (device) {
      const { DeviceID, DeviceToken, DeviceKey } = device;
      dispatch(setDeviceToken(DeviceToken));
      localStorage.setItem('deviceToken', DeviceToken);
      localStorage.setItem('deviceId', DeviceID);
      localStorage.setItem('deviceKey', DeviceKey);
    }
  } catch (err) {
    const message = findErrorMessage(err);
    console.error(message);
    Promise.reject(err);
  }
};

import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TableCell, Radio } from 'semantic-ui-react';
import { Feature } from './types';
import { toggleFeatureFlag } from '../../actions/featureFlags';

export default function FeatureToggle({ name, title, description, enabled }: Feature): JSX.Element {
  const [checked, setChecked] = useState(enabled);
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    setChecked(!checked);
    dispatch(toggleFeatureFlag(name));
  }, [name, checked, dispatch]);
  return (
    <TableCell colSpan={2}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <label style={{ fontSize: '1rem', fontWeight: 'bold' }} htmlFor={title}>
          {title}
        </label>
        <Radio toggle id={title} checked={checked} onClick={handleClick} name={name} />
      </div>
      <p style={{ marginLeft: '10px', marginTop: '10px', fontSize: '13px', fontWeight: 400 }}>{description}</p>
    </TableCell>
  );
}

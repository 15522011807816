import React, { FC, useState } from 'react';
import { Icon, IconGroup } from 'semantic-ui-react';

interface ColorSelectIconProps {
  onClick: () => void;
  color: string;
}

const ColorSelectIcon: FC<ColorSelectIconProps> = ({ onClick, color }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  return (
    <IconGroup
      size="large"
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role="button"
      style={{ cursor: 'pointer', margin: '0 5px' }}
    >
      <Icon name="circle" style={{ color, marginRight: 0 }} />
      {isHovered && <Icon name="check circle outline" color="green" />}
    </IconGroup>
  );
};

export default ColorSelectIcon;

import React from 'react';
import { Form, Button } from 'semantic-ui-react';

interface FraudCommissionsFormProps {
  commissionId: string;
  isLoading: boolean;
  error: string;
  setCommissionId: (commissionId: string) => void;
  setFormError: (error: string) => void;
  createFraudCommission: (commissionId: string) => void;
}

const FraudCommissionsForm: React.FC<FraudCommissionsFormProps> = ({
  commissionId,
  isLoading,
  error,
  setCommissionId,
  setFormError,
  createFraudCommission,
}) => {
  const isValidCommissionsId = (commissionId: string) => {
    // Checks if the commissionId only consists digits and commas
    return commissionId === '' || /^\d+$/.test(commissionId);
  };

  const handleSubmit = () => {
    if (!commissionId) return;
    createFraudCommission(commissionId);
  };

  return (
    <Form onSubmit={handleSubmit} className=" rounded-md w-full font-montserrat">
      <div className="flex flex-nowrap justify-end items-center">
        <Form.Field className="min-w-[300px] m-0">
          <Form.Input
            placeholder="Enter commission IDs (separated by commas)"
            name="commissionId"
            type="text"
            className="!text-sm !font-montserrat"
            value={commissionId}
            onChange={e => {
              setFormError('');
              const values = e.target.value.split(',').map(v => v.trim());
              if (values.every(isValidCommissionsId)) {
                setCommissionId(e.target.value);
              }
            }}
          />
        </Form.Field>
        <Button type="submit" className="orange-rounded-button !ml-3 !text-sm" disabled={isLoading} loading={isLoading}>
          Add +
        </Button>
      </div>
      <div className="flex justify-end mb-[10px]">{error && <p className="text-red-500 text-sm mt-2">{error}</p>}</div>
      <div className="w-full mb-5 flex justify-end">
        <p className="text-[10px] max-w-[600px] text-end text-gray-500 italic">
          By adding a commission ID to this table, it will be removed from the PAdmin Performance Summary and MAdmin
          Sales Dash reporting. It will not be removed from the commissionApi or PAdmin Commission History.
        </p>
      </div>
    </Form>
  );
};

export default FraudCommissionsForm;

import { ITarget } from 'interfaces';
import { CHROME } from './constants';

interface Domain {
  ID: number;
  Domain: string;
}

export const dqx = {
  async isInstalled(): Promise<boolean> {
    return new Promise(resolve => {
      try {
        //@ts-expect-error
        if (typeof window?.chrome === 'undefined' || typeof window?.chrome?.runtime === 'undefined') {
          return resolve(false);
        }
        const data = {
          status: CHROME.PING,
        };
        //@ts-expect-error
        window.chrome.runtime.sendMessage(CHROME.DQX_EXTENSION_ID, data, (response: any) => {
          resolve(response?.status === CHROME.SUCCESS);
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_) {
        resolve(false);
      }
    });
  },
  async getTargetsInQueue(keywordID: number): Promise<ITarget[]> {
    return new Promise(resolve => {
      try {
        //@ts-expect-error
        if (typeof window?.chrome === 'undefined' || typeof window?.chrome?.runtime === 'undefined') {
          return resolve([]);
        }
        const data = {
          status: CHROME.GET_TARGETS_IN_QUEUE,
          payload: keywordID,
        };
        //@ts-expect-error
        window.chrome.runtime.sendMessage(CHROME.DQX_EXTENSION_ID, data, (response: any) => {
          resolve(response?.status === CHROME.SUCCESS ? (response?.payload ?? []) : []);
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_) {
        resolve([]);
      }
    });
  },
  async completeTargetInQueue(keywordID: number, createdTargetID: number, target: ITarget): Promise<boolean> {
    return new Promise(resolve => {
      try {
        //@ts-expect-error
        if (typeof window?.chrome === 'undefined' || typeof window?.chrome?.runtime === 'undefined') {
          return resolve(false);
        }
        const data = {
          status: CHROME.COMPLETE_TARGET_IN_QUEUE,
          payload: {
            keywordID,
            createdTargetID,
            target,
          },
        };
        //@ts-expect-error
        window.chrome.runtime.sendMessage(CHROME.DQX_EXTENSION_ID, data, (response: any) => {
          resolve(response?.status === CHROME.SUCCESS);
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_) {
        resolve(false);
      }
    });
  },
  async sendActiveDomains(payload: { domains: Domain[] }): Promise<boolean> {
    return new Promise(resolve => {
      try {
        //@ts-expect-error
        if (typeof window?.chrome === 'undefined' || typeof window?.chrome?.runtime === 'undefined') {
          return resolve(false);
        }
        const data = {
          status: CHROME.SEND_ACTIVE_DOMAINS,
          payload,
        };
        //@ts-expect-error
        window.chrome.runtime.sendMessage(CHROME.DQX_EXTENSION_ID, data, (response: any) => {
          resolve(response?.status === CHROME.SUCCESS);
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_) {
        resolve(false);
      }
    });
  },
};

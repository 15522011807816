import {
  SalesDashGroupByOption,
  SalesDashGroupByLabel,
  SalesDashDateIntervalOption,
} from '../../components/SalesDashboardV2/types';

// Sales Dashboard Form Constants
export const groupByOptions: SalesDashGroupByOption[] = [
  { key: 'date', text: 'Date', value: 'date' },
  { key: 'application_id', text: 'Application ID', value: 'application_id' },
  { key: 'merchant_id', text: 'Merchant ID', value: 'merchant_id' },
  { key: 'network_id', text: 'Network ID', value: 'network_id' },
];

export const groupByLabel: SalesDashGroupByLabel = {
  date: 'Date',
  application_id: 'Application ID',
  network_id: 'Network ID',
  merchant_id: 'Merchant ID',
};

export const dateIntervalOptions: SalesDashDateIntervalOption[] = [
  { key: 'day', text: 'Day', value: 'day' },
  { key: 'week', text: 'Week', value: 'week' },
  { key: 'month', text: 'Month', value: 'month' },
  { key: 'quarter', text: 'Quarter', value: 'quarter' },
  { key: 'year', text: 'Year', value: 'year' },
];

// Second element of each array lists rgba of the color in the first element in 20% opacity
export const graphColors: [string, string][] = [
  ['#201142', 'rgba(32, 17, 66, 0.2)'],
  ['#3A2D80', 'rgba(58, 45, 128, 0.2)'],
  ['#642C97', 'rgba(100, 44, 151, 0.2)'],
  ['#7357C9', 'rgba(115, 87, 201, 0.2)'],
  ['#9B4AD9', 'rgba(155, 74, 217, 0.2)'],
  ['#CA56AE', 'rgba(202, 86, 174, 0.2)'],
  ['#D974C6', 'rgba(217, 116, 198, 0.2)'],
  ['#E14E5D', 'rgba(225, 78, 93, 0.2)'],
  ['#F36F3A', 'rgba(243, 111, 58, 0.2)'],
  ['#FD931D', 'rgba(253, 147, 29, 0.2)'],
];

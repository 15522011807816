import React, { useState, useEffect } from 'react';
import { Table, Dimmer, Loader, Segment } from 'semantic-ui-react';
import NetworkMerchantOnboardingModal from '../../components/NetworkMerchantOnboarding/NetworkMerchantOnboardingModal';
import { toPrettyTime } from '../../helpers';
import './NetworkMerchantOnboarding.scss';

const NetworkMerchantOnboarding = ({
  getNetworkMerchants,
  getNetworks,
  getNetworkMerchant,
  updateNetworkMerchant,
  getNetworkMerchantCommissions,
  getNetworkMerchantCommissionHistory,
  updateNetworkMerchantCommission,
  getNetworkMerchantKeywords,
  upsertNetworkMerchantKeyword,
  getMerchant,
  getCategoryOptions,
  getMerchantCategories,
  updateMerchantCategories,
  setLastUpdated,
  addMerchantImage,
  deleteMerchantImage,
}) => {
  const [networkMerchantData, setNetworkMerchantData] = useState(null);
  const [networks, setNetworks] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchNetworkMerchantData = async () => {
      try {
        const [networkMerchantsRes, networksRes] = await Promise.all([getNetworkMerchants(), getNetworks()]);
        setNetworkMerchantData(networkMerchantsRes?.body);
        const reformattedNetworks = {};
        networksRes.forEach(network => {
          reformattedNetworks[network.ID] = network.Name;
        });
        setNetworks(networksRes);
      } catch (err) {
        if (err?.message?.includes("storage: object doesn't exist")) {
          setError(new Error('There are no network merchants to home.'));
        } else {
          setError(err);
        }
      }
      setIsLoading(false);
    };
    fetchNetworkMerchantData();
  }, [getNetworkMerchants, getNetworks, setNetworkMerchantData, setNetworks]);

  const getNetworkNameById = networkID => {
    const currentNetwork = networks?.find(network => network.ID === networkID);
    return currentNetwork.Name || '';
  };

  const generateTable = () => {
    if (!networkMerchantData) return null;
    const rows = JSON.parse(networkMerchantData);

    rows.sort((a, b) => {
      // sort unranked & 999999 ranked to bottom of the list
      if (a.DomainRanking === 0 || a.DomainRanking === 999999) return 1;
      if (b.DomainRanking === 0 || b.DomainRanking === 999999) return -1;
      return a.DomainRanking - b.DomainRanking;
    });

    return (
      <>
        <Table celled>
          <thead>
            <tr>
              <th>Name</th>
              <th>ID</th>
              <th>XID</th>
              <th>Network</th>
              <th>Created Date</th>
              <th>URL</th>
              <th>Admin Network Merchant</th>
            </tr>
          </thead>
          <tbody>
            {/* TODO: Limit to 500 rows for performance. This is TEMPORARY. Replace with pagination asap. */}
            {rows.slice(0, 500).map(({ ID, XID, Name, NetworkID, URL, CreatedDate }) => (
              <tr key={ID}>
                <td title={Name} className="truncate">
                  <p className="label">Name: </p>
                  {Name}
                </td>
                <td>
                  <p className="label">ID: </p>
                  {ID}
                </td>
                <td>
                  <p className="label">XID: </p>
                  {XID}
                </td>
                <td className="truncate">
                  <p className="label">Network: </p>
                  {getNetworkNameById(NetworkID)}
                </td>
                <td>
                  <p className="label">Created Date: </p>
                  {toPrettyTime(CreatedDate, true)}
                </td>
                <td title={URL} className="truncate">
                  <p className="label">URL: </p>
                  <a href={URL}>{URL}</a>
                </td>
                <td className="details-button">
                  <NetworkMerchantOnboardingModal
                    ID={ID}
                    networks={networks}
                    getNetworkMerchant={getNetworkMerchant}
                    updateNetworkMerchant={updateNetworkMerchant}
                    getNetworkMerchantCommissions={getNetworkMerchantCommissions}
                    getNetworkMerchantCommissionHistory={getNetworkMerchantCommissionHistory}
                    updateNetworkMerchantCommission={updateNetworkMerchantCommission}
                    getNetworkMerchantKeywords={getNetworkMerchantKeywords}
                    upsertNetworkMerchantKeyword={upsertNetworkMerchantKeyword}
                    getMerchant={getMerchant}
                    getCategoryOptions={getCategoryOptions}
                    getMerchantCategories={getMerchantCategories}
                    updateMerchantCategories={updateMerchantCategories}
                    setLastUpdated={setLastUpdated}
                    addMerchantImage={addMerchantImage}
                    deleteMerchantImage={deleteMerchantImage}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <div className="network-merchant-onboarding">
      <h1 className="title">Network Merchant Onboarding</h1>
      {networkMerchantData && (
        <h3 className="network-merchant-count">Network Merchants: {JSON.parse(networkMerchantData).length}</h3>
      )}
      <div className="page-container">
        {error && (
          <div>
            <p>{error.message}</p>
          </div>
        )}

        {isLoading ? (
          <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        ) : (
          <div className="table-container">{generateTable()}</div>
        )}
      </div>
    </div>
  );
};

export default NetworkMerchantOnboarding;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Icon, Loader, Dropdown, Grid, Button } from 'semantic-ui-react';
import NetworkMerchantKeywordContainer from './NetworkMerchantKeyword/NetworkMerchantKeywordContainer';
import OrdinalRankTable from './OrdinalRankTable/OrdinalRankTable';
import { history } from '../../store';
import KeywordDisabledLabel from '../DisabledLabel/KeywordDisabledLabel';
import Input from '../Input/Input';
import NotFound from '../NotFound/NotFound';

class Keyword extends Component {
  state = {
    isLoading: true,
    networkMerchantKeywords: [],
    networkMerchantKeywordsWithOrdinal: [],
    keywordName: '',
    notFound: false,
    isDisabled: true,
    orderBy: null,
    isOrdinalMode: false,
  };

  getKeywordNetworkMerchants = async () => {
    const { match, getKeywordNetworkMerchants } = this.props;
    const { keywordId } = match.params;
    try {
      const networkMerchantKeywords = await getKeywordNetworkMerchants(keywordId);
      // determine if keyword is disabled based on associated network merchant keywords
      if (networkMerchantKeywords.length) {
        const isDisabled = networkMerchantKeywords.every(v => v.Disabled);
        const nmks = await Promise.all(networkMerchantKeywords.map(this.getNetworkMerchantKeywordWithExtraData));
        const nmksWithOrdinal = nmks.filter(v => v.Ordinal);
        this.setState({
          networkMerchantKeywords: nmks,
          networkMerchantKeywordsWithOrdinal: nmksWithOrdinal,
          keywordName: networkMerchantKeywords[0].KeywordName,
          isLoading: false,
          isDisabled,
        });
      } else {
        this.setState({ notFound: true, isLoading: false });
      }
    } catch (e) {
      const status = e?.response?.statusCode || e?.response?.status || e?.status;
      // when no rows found, returns 500
      if (status === 500) {
        this.setState({ notFound: true });
      }
      this.setState({ isLoading: false });
    }
  };

  // Network Merchant Keywords need extra data from the network merchant
  // We do this in the main component and not child for concurrency and
  // The ability to reuse this data in the Ordinal ranking system
  getNetworkMerchantKeywordWithExtraData = async networkMerchantKeyword => {
    const { getNetworkMerchant } = this.props;
    const nmk = { ...networkMerchantKeyword };
    const networkMerchant = await getNetworkMerchant(nmk.NetworkMerchantID);
    nmk.NetworkMerchantName = networkMerchant.Name;
    nmk.SearchURL = networkMerchant.SearchURL;
    nmk.IsNetworkMerchantDisabled = networkMerchant.Disabled;
    return nmk;
  };

  // all network merchant keywords need to be enabled/disabled
  toggleKeyword = async () => {
    const { isDisabled, networkMerchantKeywords } = this.state;
    const { upsertNetworkMerchantKeyword, setLastUpdated } = this.props;
    const networkMerchantKeywordsToBeToggled = networkMerchantKeywords.filter(networkMerchantKeyword => {
      return networkMerchantKeyword.Disabled === isDisabled;
    });
    await Promise.all(
      networkMerchantKeywordsToBeToggled.map(async networkMerchantKeyword => {
        const update = {
          Disabled: !isDisabled,
        };
        await upsertNetworkMerchantKeyword(networkMerchantKeyword.NetworkMerchantID, {
          KeywordName: networkMerchantKeyword.KeywordName,
          ...update,
        });
      }),
    );
    setLastUpdated();
  };

  addToOrdinal = async ({ NetworkMerchantID, KeywordName }) => {
    const { upsertNetworkMerchantKeyword, setLastUpdated } = this.props;
    const { networkMerchantKeywordsWithOrdinal } = this.state;
    const body = {
      KeywordName,
    };
    if (!networkMerchantKeywordsWithOrdinal.length) {
      body.Before = '0';
      body.After = '0';
    } else {
      body.After = networkMerchantKeywordsWithOrdinal[networkMerchantKeywordsWithOrdinal.length - 1].Ordinal;
    }

    await upsertNetworkMerchantKeyword(NetworkMerchantID, body);
    setLastUpdated();
  };

  setNewOrdinal = async (startingPostion, newPosition) => {
    const { upsertNetworkMerchantKeyword, setLastUpdated } = this.props;
    const { networkMerchantKeywordsWithOrdinal } = this.state;
    let nmks = [...networkMerchantKeywordsWithOrdinal];
    const nmkToSet = nmks[startingPostion];

    try {
      const body = {
        KeywordName: nmkToSet.KeywordName,
      };

      // If the new position is the starting point,
      // Then the After will be empty, the before will be the contendender in the
      // current starting point
      delete nmks[startingPostion];
      if (!newPosition) {
        body.Before = nmks[newPosition].Ordinal;
        nmks.unshift(nmkToSet);
        // If the new position is the last point,
        // Then the before will be the last nmk
      } else if (newPosition === nmks.length - 1) {
        body.After = nmks[newPosition].Ordinal;
        nmks.push(nmkToSet);
      } else {
        if (startingPostion < newPosition) {
          body.After = nmks[newPosition].Ordinal;
          body.Before = nmks[newPosition + 1].Ordinal;
        } else {
          body.After = nmks[newPosition - 1].Ordinal;
          body.Before = nmks[newPosition].Ordinal;
        }
        const newNmks = [];
        for (let i = 0; i < nmks.length; i++) {
          if (startingPostion < newPosition) {
            newNmks.push(nmks[i]);
            if (i === newPosition) {
              newNmks.push(nmkToSet);
            }
          } else {
            if (i === newPosition) {
              newNmks.push(nmkToSet);
            }
            newNmks.push(nmks[i]);
          }
        }
        nmks = newNmks;
      }
      nmks = nmks.filter(Boolean);
      this.setState({ networkMerchantKeywordsWithOrdinal: nmks });
      await upsertNetworkMerchantKeyword(nmkToSet.NetworkMerchantID, body);
      setLastUpdated();
    } catch (err) {
      console.error(err);
      this.setState({ networkMerchantKeywordsWithOrdinal });
    }
  };
  removeFromOrdinal = async ({ NetworkMerchantID, KeywordName }) => {
    const { upsertNetworkMerchantKeyword, setLastUpdated } = this.props;
    await upsertNetworkMerchantKeyword(NetworkMerchantID, {
      KeywordName,
      Before: '-1',
      After: '-1',
    });
    setLastUpdated();
  };

  // frontend hack to rename a keyword since no backend solution exists
  // disable keyword for all associated network merchants
  // add keyword with new name to all network merchants
  renameKeyword = async ({ KeywordName }) => {
    const { networkMerchantKeywords } = this.state;
    const { renameKeyword, setLastUpdated } = this.props;
    const { id } = await renameKeyword(KeywordName, networkMerchantKeywords);
    if (id) {
      history.push(`/merchant-admin/keyword/${id}`);
    } else {
      // no id returned; could not find. leave it up to the user
      await setLastUpdated();
    }
  };

  async componentDidMount() {
    await this.getKeywordNetworkMerchants();
  }

  async componentDidUpdate(prevProps) {
    const { match, lastUpdated } = this.props;
    // make sure keywords are up to date
    if (lastUpdated !== prevProps.lastUpdated) {
      this.getKeywordNetworkMerchants();
    }
    // only if this component is rendered by <Route>
    if (match && match.params.keywordId !== prevProps.match.params.keywordId) {
      this.setState({
        isLoading: true,
        notFound: false,
      });
      await this.getKeywordNetworkMerchants();
    }
  }

  orderNetworkMerchantKeywords = nmKeywords => {
    const { orderBy } = this.state;
    if (!orderBy) return nmKeywords;
    const nmks = [...nmKeywords];
    const nmksSortingAlgos = {
      id: (a, b) => a.ID - b.ID,
      enabled: (a, b) => (a.Disabled === b.Disabled ? 0 : a.Disabled ? 1 : -1),
    };
    return nmks.sort(nmksSortingAlgos[orderBy]);
  };
  handleOrderChange = (_, { value }) => {
    this.setState({
      orderBy: value || null,
    });
  };

  render() {
    const {
      networkMerchantKeywords,
      networkMerchantKeywordsWithOrdinal,
      keywordName,
      isLoading,
      notFound,
      isDisabled,
      orderBy,
      isOrdinalMode,
    } = this.state;
    const { match, isDQContractor, canEdit } = this.props;
    if (notFound) {
      return <NotFound />;
    }

    if (isLoading) {
      return <Loader active size="massive" inline="centered" />;
    }

    return (
      <Segment.Group>
        <Segment>
          <h3>
            <Icon name="tag" />
            Keyword (ID: {match.params.keywordId})
          </h3>
          <KeywordDisabledLabel
            isDisabled={isDisabled}
            toggleDisabled={this.toggleKeyword}
            uneditable={isDQContractor || !canEdit}
          />
          <Input
            label="KeywordName"
            value={keywordName}
            handleResourceChange={this.renameKeyword}
            disabled={isDQContractor || !canEdit}
          />
        </Segment>
        {networkMerchantKeywords.length > 1 && (
          <Segment>
            <Grid divided="vertically">
              <Grid.Row columns={2} verticalAlign="middle">
                <Grid.Column floated="left" width={3}>
                  <h3>Order By</h3>
                  <Dropdown
                    clearable
                    options={[
                      { key: 1, text: 'ID', value: 'id' },
                      { key: 2, text: 'Enabled First', value: 'enabled' },
                    ]}
                    selection
                    onChange={this.handleOrderChange}
                    value={orderBy}
                  />
                </Grid.Column>
                <Grid.Column floated="right" width={3}>
                  <Button
                    color={isOrdinalMode ? 'red' : 'blue'}
                    fluid
                    size="large"
                    onClick={() => this.setState({ isOrdinalMode: !isOrdinalMode })}
                  >
                    {isOrdinalMode ? 'Stop Ordinal Mode' : 'Ordinal Mode'}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
        {isOrdinalMode && (
          <Segment.Group>
            <OrdinalRankTable
              networkMerchantKeywordsWithOrdinal={networkMerchantKeywordsWithOrdinal}
              removeFromOrdinal={this.removeFromOrdinal}
              setNewOrdinal={this.setNewOrdinal}
            />
          </Segment.Group>
        )}
        {this.orderNetworkMerchantKeywords(networkMerchantKeywords).map(networkMerchantKeyword => (
          <NetworkMerchantKeywordContainer
            key={networkMerchantKeyword.ID}
            networkMerchantKeyword={networkMerchantKeyword}
            isOrdinalMode={isOrdinalMode}
            addToOrdinal={this.addToOrdinal}
            isDisabled={isDQContractor}
          />
        ))}
      </Segment.Group>
    );
  }
}

Keyword.propTypes = {
  match: PropTypes.object.isRequired,
  getKeywordNetworkMerchants: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  renameKeyword: PropTypes.func.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  isDQContractor: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default Keyword;

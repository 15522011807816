import { wfGet } from 'actions';

export const getCloudProfile = deviceId => async dispatch => {
  try {
    const profile = await dispatch(wfGet(`/api/tech-support/cloud-profile/${deviceId}`));
    return profile;
  } catch (error) {
    return Promise.reject(error);
  }
};

import React from 'react';
import { useSelector } from 'react-redux';
import { TableRow, TableHeaderCell, TableHeader, TableBody, Table } from 'semantic-ui-react';
import FeatureToggle from './FeatureToggle';
import { Feature } from './types';
import { allFeatureFlagsSelector } from '../../selectors/featureFlags';

export default function FeatureDashboard(): JSX.Element {
  const features: Record<string, Feature> = useSelector(allFeatureFlagsSelector);
  const featuresList = Object.values(features);
  if (!featuresList.length) return <p style={{ textAlign: 'center' }}>There are currently no active Feature Flags.</p>;
  return (
    <Table aria-label="feature flag table">
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Feature Flag</TableHeaderCell>
          <TableHeaderCell textAlign="right">Enabled</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {featuresList.map(feature => (
          <TableRow key={feature.name}>
            <FeatureToggle
              name={feature.name}
              title={feature.title}
              description={feature.description}
              enabled={feature.enabled}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

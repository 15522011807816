import { wfGet } from '..';

export const getDashboardStats = searchURI => async dispatch => {
  try {
    let uri = `/api/sales-dashboard/dashboard-stats${searchURI}`;
    const dashboardStats = await dispatch(wfGet(uri));
    return dashboardStats;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDashboardGraphStats = searchURI => async dispatch => {
  try {
    let uri = `/api/sales-dashboard/dashboard-graph-stats${searchURI}`;

    const dashboardStats = await dispatch(wfGet(uri));
    return dashboardStats;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDashboardTableStats = searchURI => async dispatch => {
  try {
    let uri = `/api/sales-dashboard/dashboard-table-stats${searchURI}`;

    const dashboardStats = await dispatch(wfGet(uri));
    return dashboardStats;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDashboardLastUpdated = () => async dispatch => {
  try {
    let uri = '/api/sales-dashboard/dashboard-timestamp';

    const dashboardTimestamp = await dispatch(wfGet(uri));
    return dashboardTimestamp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAppGroupOptions = () => async dispatch => {
  try {
    const appGroupOptions = await dispatch(wfGet('/api/sales-dashboard/filter-items/application-groups'));
    return appGroupOptions;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFilterOptions = category => async dispatch => {
  try {
    const filterItems = await dispatch(wfGet(`/api/sales-dashboard/filter-items?category=${category}`));
    return filterItems;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSalesDashboardFilterItems = category => async dispatch => {
  try {
    const filterItems = await dispatch(wfGet(`/api/sales-dashboard/all-filter-items/${category}`));
    return filterItems;
  } catch (error) {
    return Promise.reject(error);
  }
};

import React from 'react';
import { Tab } from 'semantic-ui-react';
import CreateReconcileForm from 'components/Reconciliation/CreateReconcileForm';
import GetReconcileJobs from 'components/Reconciliation/GetReconcileJobs';

const Reconciliation = ({ createReconcileJob, getReconcileJobs, retryReconcileJob }) => {
  const options = [
    {
      menuItem: { key: 'view', content: 'View Reconcile Jobs', className: 'font-montserrat text-md' },
      className: 'reconciliation-tab',
      render: () => (
        <Tab.Pane>
          <GetReconcileJobs getReconcileJobs={getReconcileJobs} retryReconcileJob={retryReconcileJob} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: 'create', content: 'Create a New Reconcile Job', className: 'font-montserrat text-md' },
      render: () => (
        <Tab.Pane>
          <CreateReconcileForm createReconcileJob={createReconcileJob} />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <div className="grow max-w-[1350px] font-montserrat w-full">
      <h1 className="reconciliation font-title text-center mb-8">Reconciliation</h1>
      <Tab panes={options} />
    </div>
  );
};

export default Reconciliation;

import React, { useCallback, useMemo } from 'react';
import { Table, Icon } from 'semantic-ui-react';

const StatsTableHeader = ({
  columns,
  sortBy,
  sortOrder,
  onSort,
  chartMetric,
  setChartMetric,
  chartType,
  timeLabel,
  isNonTimeGroupedView,
}) => {
  const dateHeader = {
    key: timeLabel,
    text: timeLabel,
    value: 'ReportDate',
    unit: 'Date',
  };
  const nameHeader = {
    key: 'Name',
    text: 'Name',
    value: 'Name',
    unit: 'Name',
  };

  const handleSort = column => {
    if (column.text.includes('ID')) {
      onSort(column.text);
    } else {
      onSort(column.value);
    }
  };

  const renderContent = useCallback(
    column => {
      const isActive = sortBy === column.text || sortBy === column.value;
      return column.value === 'ReportDate' ||
        column.value === 'Name' ||
        column.value === 'ClickCount' ||
        timeLabel === 'Merchant ID' ? (
        <Table.HeaderCell key={column.key} className="stats-table-header-cell">
          <div
            className="chart-header-label"
            direction={isActive ? sortOrder : 'asc'}
            onClick={() => handleSort(column)}
          >
            {column.text}
            <Icon className={isActive ? (sortOrder === 'asc' ? 'sort up' : 'sort down') : 'sort'} />
          </div>
        </Table.HeaderCell>
      ) : (
        <Table.HeaderCell key={column.key} className="stats-table-header-cell">
          <div
            className="chart-header-label"
            direction={isActive ? sortOrder : 'asc'}
            onClick={() => handleSort(column)}
          >
            {column.text}
            <Icon className={isActive ? (sortOrder === 'asc' ? 'sort up' : 'sort down') : 'sort'} />
          </div>
          <span
            className="table-icon"
            onClick={() => {
              setChartMetric(column);
            }}
          >
            <Icon className={`${chartMetric?.key === column.key ? 'blue' : ''} chart ${chartType}`} size="large" />
          </span>
        </Table.HeaderCell>
      );
    },
    [onSort, sortBy, sortOrder, chartMetric, chartType, setChartMetric, timeLabel],
  );

  const renderedColumns = useMemo(() => {
    return columns.map(column => {
      return renderContent(column);
    });
  }, [columns, renderContent]);

  return (
    <Table.Header id="table-header">
      <Table.Row>
        {renderContent(dateHeader)}
        {isNonTimeGroupedView && renderContent(nameHeader)}
        {renderedColumns}
      </Table.Row>
    </Table.Header>
  );
};

export default StatsTableHeader;

import { connect } from 'react-redux';
import { getUserCanEdit } from 'selectors/user';
import Merchant from './Merchant';
import {
  getMerchant,
  getMerchantHistory,
  getMerchantImageHistory,
  getMerchantCategoryHistory,
  getMerchantNetworkMerchants,
  updateMerchant,
  getMerchantKeywords,
  upsertMerchantKeyword,
  deleteMerchantKeyword,
  getCategoryOptions,
  getMerchantCategories,
  updateMerchantCategories,
  setLastUpdated,
  addMerchantImage,
  deleteMerchantImage,
} from '../../actions/merchantAdmin';
import { getLastUpdated } from '../../selectors/merchantAdmin';

const mapStateToProps = state => ({
  lastUpdated: getLastUpdated(state),
  canEdit: getUserCanEdit(state),
});

const mapActionsToProps = {
  getMerchant,
  getMerchantHistory,
  getMerchantImageHistory,
  getMerchantCategoryHistory,
  getMerchantNetworkMerchants,
  updateMerchant,
  getMerchantKeywords,
  upsertMerchantKeyword,
  deleteMerchantKeyword,
  getCategoryOptions,
  getMerchantCategories,
  updateMerchantCategories,
  setLastUpdated,
  addMerchantImage,
  deleteMerchantImage,
};

export default connect(mapStateToProps, mapActionsToProps)(Merchant);

import React, { useEffect, useState } from 'react';
import { DropdownOnSearchChangeData, DropdownProps as SemanticDropdownProps, DropdownItemProps, Form, LabelProps } from 'semantic-ui-react';
import { DropdownChangeEvent } from 'components/SalesDashboardV2/types';

interface Option {
  key: number;
  name: string;
  text: string;
  value: number;
}

interface DropdownProps {
  name: string;
  value: number[];
  placeholder: string;
  options: Option[];
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  icon?: React.ReactElement;
  renderLabel?: (item: DropdownItemProps, index: number, defaultLabelProps: LabelProps) => any;
  handleChange: (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownChangeEvent) => void;
  handleSearchChange?: (searchQuery: string) => void;
  getOptionData?: (value: number) => Promise<Option>;
}

const Dropdown = ({
  name,
  value,
  placeholder,
  options,
  disabled,
  loading,
  className,
  icon,
  renderLabel,
  handleChange,
  handleSearchChange,
  getOptionData,
}: DropdownProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const updateSearch = (e: React.SyntheticEvent<HTMLElement, Event>, { searchQuery }: DropdownOnSearchChangeData) => {
    setSearchQuery(searchQuery);
    if (handleSearchChange) handleSearchChange(searchQuery);
  };

  const handleValueChange = (e: React.SyntheticEvent<HTMLElement, Event>, props: SemanticDropdownProps) => {
    // Update selected items
    const { value } = props;
    if (Array.isArray(value)) {
      const newSelectedOptions = options.filter(option => value.includes(option.value));
      setSelectedOptions(newSelectedOptions);
    } else {
      setSelectedOptions([]);
      console.warn('Multiple Select Dropdown value was not an array. Value:', value);
    }
    handleChange(e, props as DropdownChangeEvent);
  };

  useEffect(() => {
    if (!getOptionData) return;
    const fetchMissingOptions = async () => {
      if (value.length > selectedOptions.length) {
        const neededOptions = value.filter(
          singleValue => !selectedOptions.find(option => option.value === singleValue),
        );
        const newSelectedOptions = await Promise.all(neededOptions.map(value => getOptionData(value)));
        setSelectedOptions(prevOptions => [...prevOptions, ...newSelectedOptions]);
      }
    };
    fetchMissingOptions();
  }, [value, selectedOptions, getOptionData]);

  return (
    <Form.Dropdown
      fluid
      multiple
      selection
      clearable
      search
      searchQuery={searchQuery}
      defaultValue={value}
      disabled={disabled} // Users will only be able to select from either inclusion or exclusion filters
      loading={loading} // Add loading spinners on dropdown menus when options are not ready
      name={name}
      placeholder={placeholder}
      icon={icon}
      renderLabel={renderLabel}
      options={Array.from(new Set([...selectedOptions, ...options]))}
      onChange={handleValueChange}
      onSearchChange={updateSearch}
      className={className}
    />
  );
};

export default Dropdown;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { getMerchants } from 'actions/merchantAdmin';
import { findErrorMessage } from 'helpers';
import RecentlyOnboardedMerchantsExport from './RecentlyOnboardedMerchantsExport';
import RecentlyOnboardedMerchantsTable from './RecentlyOnboardedMerchantsTable';
import { OnboardedMerchant } from './types';
import './RecentlyOnboardedMerchantsBody.scss';

const limitOptions = [
  { key: 1, text: '100 Merchants', value: 100 },
  { key: 2, text: '250 Merchants', value: 250 },
  { key: 3, text: '500 Merchants', value: 500 },
];

interface RecentlyOnboardedMerchantsBodyProps {
  getMerchants: (args: {
    sortBy: string;
    sortOrder: string;
    limit: number;
    minScore: number;
  }) => Promise<OnboardedMerchant[]>;
}

const RecentlyOnboardedMerchantsBody = ({ getMerchants }: RecentlyOnboardedMerchantsBodyProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [recentlyOnboardedMerchants, setRecentlyOnboardedMerchants] = useState<OnboardedMerchant[]>([]);
  const [showScoreGreaterThanZero, setShowScoreGreaterThanZero] = useState(false);
  const [limit, setLimit] = useState(100); // default limit is 100
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        setIsLoading(true);
        const response = await getMerchants({
          sortBy: 'created_date',
          sortOrder: 'desc',
          limit,
          minScore: showScoreGreaterThanZero ? 0.01 : 0,
        });
        setRecentlyOnboardedMerchants(response || []);
      } catch (error) {
        toast.error('Failed to get recently onboarded merchants.');
        setError(findErrorMessage(error) || 'Unknown error has occurred.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchMerchants();
  }, [limit, showScoreGreaterThanZero]);

  return (
    <div className="recent-onboarded-merchants-body">
      <div className="recently-onboarded-merchants-body-button-container">
        <div className="recently-onboarded-merchants-body-toggle-container">
          <label className="mr-2" htmlFor="score-toggle">
            {'Score > 0'}
          </label>
          <Checkbox
            toggle
            id="score-toggle"
            data-testid="score-toggle"
            checked={showScoreGreaterThanZero}
            onChange={() => setShowScoreGreaterThanZero(!showScoreGreaterThanZero)}
          />
        </div>
        <div className="merchant-amount-dropdown">
          <Dropdown
            data-testid="merchant-amount-dropdown"
            compact
            selection
            item
            options={limitOptions}
            value={limit}
            onChange={(e: React.SyntheticEvent<HTMLElement, Event>, { value }) => {
              if (typeof value !== 'number') {
                console.error('Invalid limit value.');
                return;
              }
              setLimit(value);
            }}
          />
        </div>
        <RecentlyOnboardedMerchantsExport
          recentlyOnboardedMerchants={recentlyOnboardedMerchants}
          disabled={Boolean(error)}
        />
      </div>
      <RecentlyOnboardedMerchantsTable
        onboardedMerchants={recentlyOnboardedMerchants}
        isLoading={isLoading}
        error={error}
      />
    </div>
  );
};

const mapActionsToProps = {
  getMerchants,
};

export default connect(null, mapActionsToProps)(RecentlyOnboardedMerchantsBody);

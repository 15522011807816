import { combineReducers } from 'redux';
import error from './error';
import featureFlags from './featureFlags';
import merchantAdmin from './merchantAdmin';
import user from './user';
import { LOGOUT, SET_BAD_AUTH } from '../constants/actionTypes';
import initialState from '../initialState';

const appReducer = combineReducers({
  user,
  error,
  merchantAdmin,
  featureFlags,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = initialState();
  }

  if (action.type === SET_BAD_AUTH) {
    state = undefined;

    state = initialState();
  }

  return appReducer(state, action);
};

export default rootReducer;

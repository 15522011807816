import React, { useEffect, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { startCase } from 'lodash';
import { Dropdown, Menu } from 'semantic-ui-react';
import { getWindowSize } from 'helpers';
import { checkIsDQContractor, checkIsWildfireAdmin, checkIsReconciler, checkIsOperations } from 'helpers/auth0';
import { DropdownItem, LinkConfig, LinksToRender } from './types';
import OverflowMenu from '../OverflowMenu/OverflowMenu';
import WFLogo from 'images/wildfire-logo.png';
import './Navigation.scss';

const smallScreenNavConfig: LinkConfig = {
  main: {
    isWildfireAdmin: [],
    isContractor: [],
    isReconciler: [],
    isOperations: [],
  },
  overflow: {
    isWildfireAdmin: [
      'merchant-admin',
      'keyword-targets',
      'keyword-target-queue',
      'sales-dashboard',
      'commission-search',
      'fraud-commissions',
      'recently-onboarded-merchants',
      'network-merchant-onboarding',
      'commission-rate-queue',
      'feature-flags',
      'bonus-tool',
      'aging-report',
      'bonus-reports',
      'tech-support',
      'logout',
    ],
    isContractor: [
      'merchant-admin',
      'keyword-targets',
      'keyword-target-queue',
      'network-merchant-onboarding',
      'commission-rate-queue',
      'logout',
    ],
    isReconciler: ['reconciliation'],
    isOperations: ['bonus-payments'],
  },
};

const mediumScreenNavConfig: LinkConfig = {
  main: {
    isWildfireAdmin: [
      'merchant-admin',
      'sales-dashboard',
      'commission-search',

      {
        dropdownName: 'Data Quality',
        dropdownList: [
          'keyword-targets',
          'keyword-target-queue',
          'network-merchant-onboarding',
          'commission-rate-queue',
        ],
      },
    ],
    isContractor: ['merchant-admin', 'keyword-targets', 'keyword-target-queue', 'network-merchant-onboarding', 'commission-rate-queue'],
    isOperations: [],
    isReconciler: [],
  },
  overflow: {
    isWildfireAdmin: [
      'fraud-commissions',
      'recently-onboarded-merchants',
      'feature-flags',
      'bonus-tool',
      'aging-report',
      'tech-support',
      'logout',
    ],
    isContractor: ['logout'],
    isOperations: ['bonus-payments'],
    isReconciler: ['reconciliation'],
  },
};

const largeScreenNavConfig: LinkConfig = {
  main: {
    isWildfireAdmin: [
      'merchant-admin',
      'sales-dashboard',
      'commission-search',
      {
        dropdownName: 'Data Quality',
        dropdownList: [
          'keyword-targets',
          'keyword-target-queue',
          'network-merchant-onboarding',
          'commission-rate-queue',
        ],
      },
      'feature-flags',
      {
        dropdownName: 'Other Tools',
        dropdownList: ['fraud-commissions', 'recently-onboarded-merchants', 'bonus-tool', 'aging-report'],
      },
    ],
    isContractor: [
      'merchant-admin',
      'keyword-targets',
      'keyword-target-queue',
      'network-merchant-onboarding',
      'commission-rate-queue',
    ],
    isOperations: ['bonus-payments'],
    isReconciler: ['reconciliation'],
  },
  overflow: {
    isWildfireAdmin: ['tech-support', 'logout'],
    isContractor: ['logout'],
    isReconciler: [],
    isOperations: [],
  },
};

const getLinksForScreenSize = (
  windowWidth: number,
  isReconciler: boolean,
  isWildfireAdmin: boolean,
  isDQContractor: boolean,
  isOperations: boolean,
) => {
  const windowSize = getWindowSize(windowWidth);
  const linksToRender: LinksToRender = { main: [], overflow: [] };
  let screenSizeConfig: LinkConfig = {} as LinkConfig;

  // Determine the menu config according to window size
  if (windowSize === 'sm') {
    screenSizeConfig = smallScreenNavConfig;
  } else if (windowSize === 'md') {
    screenSizeConfig = mediumScreenNavConfig;
  } else if (windowSize === 'lg') {
    screenSizeConfig = largeScreenNavConfig;
  }

  // Depending on user permission render correct menu items
  if (isWildfireAdmin) {
    linksToRender.main.push(...screenSizeConfig.main.isWildfireAdmin);
    linksToRender.overflow.push(...screenSizeConfig.overflow?.isWildfireAdmin);

    const techSupportIndex = linksToRender.overflow.indexOf('tech-support');
    linksToRender.overflow.splice(techSupportIndex, 1);
    linksToRender.overflow.push('tech-support');
  } else if (isDQContractor) {
    linksToRender.main.push(...screenSizeConfig.main.isContractor);
    linksToRender.overflow.push(...screenSizeConfig.overflow.isContractor);
  }

  // If user is operations render operations menu items
  if (isOperations) {
    linksToRender.main.push(...screenSizeConfig.main.isOperations);
    linksToRender.overflow.push(...screenSizeConfig.overflow.isOperations);
  }

  // If user is a reconciler render reconciliation menu item
  if (isReconciler) {
    linksToRender.main.push(...screenSizeConfig.main.isReconciler);
    linksToRender.overflow.push(...screenSizeConfig.overflow.isReconciler);
  }

  // Make sure logout is always the last menu item in overflow menu
  const logoutIndex = linksToRender.overflow.indexOf('logout');
  linksToRender.overflow.splice(logoutIndex, 1);
  linksToRender.overflow.push('logout');

  return linksToRender;
};

const Navigation: React.FC = () => {
  const { logout: logoutAuth0, isAuthenticated, user } = useAuth0();
  const location = useLocation();
  const [isWildfireAdmin, setIsWildfireAdmin] = useState(false);
  const [isDQContractor, setIsDQContractor] = useState(false);
  const [isReconciler, setIsReconciler] = useState(false);
  const [isOperations, setIsOperations] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isActiveMenu = (menu: string) => location.pathname.indexOf(menu) !== -1;
  const linksToRender = getLinksForScreenSize(windowWidth, isReconciler, isWildfireAdmin, isDQContractor, isOperations);

  const renderMenuItems = () =>
    linksToRender.main.map((link: string | DropdownItem) => {
      if (typeof link === 'string') {
        if (link === 'logout') return <Menu.Item name="logout" onClick={handleLogout} />;

        const title = startCase(link.split('-').join(' '));

        return (
          <Link key={link} to={`/${link}`}>
            <Menu.Item active={isActiveMenu(`/${link}`)}>{title}</Menu.Item>
          </Link>
        );
      } else {
        return (
          <Dropdown item text={link.dropdownName}>
            <Dropdown.Menu>
              {link.dropdownList.map(dropdownItem => {
                const title = startCase(dropdownItem.split('-').join(' '));
                return (
                  <Link key={dropdownItem} to={`/${dropdownItem}`}>
                    <Dropdown.Item className="menuItem" text={title} active={isActiveMenu(dropdownItem)} />
                  </Link>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    });

  const handleLogout = async () => {
    logoutAuth0();
  };

  useEffect(() => {
    setIsWildfireAdmin(checkIsWildfireAdmin(user));
    setIsDQContractor(checkIsDQContractor(user));
    setIsReconciler(checkIsReconciler(user));
    setIsOperations(checkIsOperations(user));
  }, [user]);

  useEffect(() => {
    const handleWindowResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleWindowResize);
    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/auth',
          state: { from: location.pathname },
        }}
      />
    );
  }

  if (!isWildfireAdmin && !isDQContractor) {
    return null;
  }

  return (
    <Menu pointing secondary className="dropdownMenu m-0">
      <div className="mainMenu">
        <Link to="/">
          <div className="wf-logo">
            <img src={WFLogo} alt="Wildfire logo" />
          </div>
        </Link>
        {renderMenuItems()}
      </div>
      <OverflowMenu linksToRender={linksToRender.overflow} />
    </Menu>
  );
};

export default Navigation;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Form } from 'semantic-ui-react';
import { findErrorMessage } from 'helpers';
import KeywordSearchContainer from '../KeywordSearch/KeywordSearchContainer';

import './AddKeyword.css';

class AddKeyword extends Component {
  state = {
    isLoading: false,
    Kind: '',
    KeywordName: '',
    SearchCode: '',
    DestinationURL: '',
    Rank: '1',
    hasError: false,
    errorMessage: '',
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleKeywordNameChange = KeywordName => this.setState({ KeywordName });

  handleSubmit = async () => {
    const { Kind, KeywordName, SearchCode, DestinationURL, Rank } = this.state;
    const { handleAddKeyword } = this.props;
    const parsedRank = parseFloat(Rank);

    const keyword = {
      Kind,
      KeywordName,
      SearchCode,
      DestinationURL,
      Rank: parsedRank,
      Disabled: false,
    };

    try {
      this.setState({ isLoading: true });
      await handleAddKeyword(keyword);
    } catch (e) {
      this.setState({
        hasError: true,
        isLoading: false,
        errorMessage: findErrorMessage(e),
      });
    }
  };

  render() {
    const { Kind, KeywordName, SearchCode, DestinationURL, Rank, hasError, errorMessage, isLoading } = this.state;

    return (
      <div className="add-keyword">
        <Form className="keyword-form" autoComplete="off" onSubmit={this.handleSubmit}>
          <Input onChange={this.handleChange} required fluid label="Kind" name="Kind" value={Kind} />
          <KeywordSearchContainer keywordName={KeywordName} handleChange={this.handleKeywordNameChange} />
          <Input onChange={this.handleChange} fluid label="Search Code" name="SearchCode" value={SearchCode} />
          <Input
            onChange={this.handleChange}
            fluid
            label="Destination URL"
            name="DestinationURL"
            value={DestinationURL}
          />
          <Input onChange={this.handleChange} required fluid label="Rank" name="Rank" value={Rank} type="number" />
          {hasError && <div className="error">{errorMessage}</div>}
          <Button>{isLoading ? 'Loading' : 'Add'}</Button>
        </Form>
      </div>
    );
  }
}

AddKeyword.propTypes = {
  handleAddKeyword: PropTypes.func.isRequired,
};

export default AddKeyword;

import React from 'react';
import RecentlyOnboardedMerchantsBody from 'components/RecentlyOnboardedMerchants/RecentlyOnboardedMerchantsBody';

import './RecentlyOnboardedMerchants.scss';

export const RecentlyOnboardedMerchants = () => {
  return (
    <div className="recently-onboarded-merchants">
      <h1 className="recently-onboarded-merchants-title">Recently Onboarded Merchants</h1>
      <RecentlyOnboardedMerchantsBody />
    </div>
  );
};

export default RecentlyOnboardedMerchants;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { Network } from 'redux/store/network/types';
import { Accordion, Button, Checkbox, Dimmer, Form, Icon, Loader, Modal, Segment } from 'semantic-ui-react';
import { getDifferingProperties, areEqualArrays, findNetworkNameWithID } from 'helpers';
import { checkIsDQContractor } from 'helpers/auth0';
import { getUserCanEdit } from 'selectors/user';
import NetworkMerchantOnboardingAddMerchants from './NetworkMerchantOnboardingAddMerchants';
import NetworkMerchantOnboardingCommissionsTable from './NetworkMerchantOnboardingCommissionsTable';
import NetworkMerchantOnboardingImageList from './NetworkMerchantOnboardingImageList';
import NetworkMerchantOnboardingKeywordList from './NetworkMerchantOnboardingKeywordList';
import {
  Commission,
  CommissionHistory,
  Keyword,
  Merchant,
  MerchantCategory,
  MerchantCategoryOption,
  NetworkMerchant,
  SelectedCountryOptions,
} from './types';
import {
  CountryCodes,
  europeCountryCodes,
  allCountryCodes,
  seaCountryCodes,
  unitedStateCountryCodes,
} from '../../constants/countryCodes';
import CategoryModal from '../Category/CategoryModal';
import CommissionHistoryLog from '../Commission/CommissionHistoryLog';
import Input from '../Input/Input';
import AddKeyword from '../Keyword/AddKeyword/AddKeyword';
import Lock from '../Lock/Lock';
import './NetworkMerchantOnboardingModal.scss';

interface NetworkMerchantOnboardingModalProps {
  ID: number;
  networks: Network[];
  getNetworkMerchant: (ID: number) => NetworkMerchant;
  updateNetworkMerchant: (update: any) => void;
  getNetworkMerchantCommissions: (ID: number, options: { sortBy: string }) => Commission[];
  getNetworkMerchantCommissionHistory: (ID: number) => CommissionHistory[];
  updateNetworkMerchantCommission: (commission: Commission) => void;
  getNetworkMerchantKeywords: (ID: number) => Keyword[];
  upsertNetworkMerchantKeyword: (ID: number, keyword: Keyword) => void;
  getMerchant: (ID: number) => Merchant;
  getCategoryOptions: (ID: number) => MerchantCategoryOption[];
  getMerchantCategories: (ID: number) => MerchantCategory[];
  updateMerchantCategories: (ID: number, body: { Categories: MerchantCategory[]; ModifiedAuthor: string }) => void;
  setLastUpdated: () => (dispatch: any, getState: any) => Promise<void>;
  addMerchantImage: (ID: number, imageDetails: FormData) => void;
  deleteMerchantImage: (ID: number, merchantImageId: number) => void;
}

const NetworkMerchantOnboardingModal: React.FC<NetworkMerchantOnboardingModalProps> = ({
  ID,
  networks,
  getNetworkMerchant,
  updateNetworkMerchant,
  getNetworkMerchantCommissions,
  getNetworkMerchantCommissionHistory,
  updateNetworkMerchantCommission,
  getNetworkMerchantKeywords,
  upsertNetworkMerchantKeyword,
  getMerchant,
  getCategoryOptions,
  getMerchantCategories,
  updateMerchantCategories,
  setLastUpdated,
  addMerchantImage,
  deleteMerchantImage,
}) => {
  const [error, setError] = useState<string>('');
  const [keywordError, setKeywordError] = useState<string>('');
  const [merchantError, setMerchantError] = useState<string>('');
  const [isDQContractor, setIsDQContractor] = useState<boolean>(false);
  const [networkMerchant, setNetworkMerchant] = useState<NetworkMerchant | null>(null);
  const [isCommissionVisible, setIsCommissionVisible] = useState(false);
  const [commissions, setCommissions] = useState<Commission[]>([]);
  const [commissionHistory, setCommissionHistory] = useState<CommissionHistory[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [countryCheckboxes, setCountryCheckboxes] = useState<string[]>([]);

  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [isKeywordVisible, setIsKeywordVisible] = useState<boolean>(false);
  const [merchant, setMerchant] = useState<Merchant | null>(null);
  const [merchantCategories, setMerchantCategories] = useState<MerchantCategory[]>([]);
  const [merchantCategoryOptions, setMerchantCategoryOptions] = useState<MerchantCategoryOption[]>([]);
  const [isImageVisible, setIsImageVisible] = useState<boolean>(false);
  const [isNetworkMerchantLoading, setIsNetworkMerchantLoading] = useState<boolean>(false);
  const [isCommissionLoading, setIsCommissionLoading] = useState<boolean>(false);
  const [isCommissionHistoryLoading, setIsCommissionHistoryLoading] = useState<boolean>(false);
  const [isKeywordLoading, setIsKeywordLoading] = useState<boolean>(false);
  const [isMerchantLoading, setIsMerchantLoading] = useState<boolean>(false);
  const [isTestLinkPreviewLoading, setIsTestLinkPreviewLoading] = useState<boolean>(true);

  const { user } = useAuth0();
  const canEdit = useSelector(state => getUserCanEdit(state));
  const userEmail = localStorage.getItem('userEmail');
  const history = useHistory();

  // Resuable error handler
  const handleError = (error: unknown, errorHandler: React.Dispatch<React.SetStateAction<string>>) => {
    if (typeof error === 'string') {
      errorHandler(error);
    } else if (error instanceof Error) {
      errorHandler(error.message || 'Unknown error has occurred.');
    } else {
      errorHandler('Unknown error has occurred.');
    }
  };

  const fetchTestLink = () => {
    if (!networks || !networkMerchant) return '';

    const network = networks.find(network => network.ID === networkMerchant?.NetworkID);
    const templateUrl = network?.TemplateURL;

    if (network) {
      if (Number(networkMerchant.NetworkID) === 10) {
        // Use networkMerchant.BannerID to set the AffiliateCode for AvantLink
        return (
          templateUrl
            ?.replace('[[AffiliateCode]]', networkMerchant.BannerID)
            .replace('[[MerchantCode]]', networkMerchant.RedirectCode || networkMerchant.XID)
            .replace('[[BannerCode]]', networkMerchant.BannerID)
            .replace('[[DeepLinkURL]]', networkMerchant.URL)
            // replace with sender token so that if a transaction does happen, it does not cause errors in the system
            .replace('[[SubAffiliateCode]]', localStorage.getItem('deviceId') || '1')
            // this is currently not used on our end
            .replace('[[RecipientCode]]', '')
        );
      }

      return (
        templateUrl
          ?.replace('[[AffiliateCode]]', network.AffiliateCode)
          .replace('[[MerchantCode]]', networkMerchant.RedirectCode || networkMerchant.XID)
          .replace('[[BannerCode]]', networkMerchant.BannerID)
          .replace('[[DeepLinkURL]]', networkMerchant.URL)
          // replace with sender token so that if a transaction does happen, it does not cause errors in the system
          .replace('[[SubAffiliateCode]]', localStorage.getItem('deviceId') || '1')
          // this is currently not used on our end
          .replace('[[RecipientCode]]', '')
      );
    }
  };

  const fetchNetworkMerchant = async () => {
    setIsNetworkMerchantLoading(true);
    try {
      const networkMerchantData = await getNetworkMerchant(ID);
      setNetworkMerchant(networkMerchantData);
      setError('');
    } catch (error) {
      handleError(error, setError);
    }
    setIsNetworkMerchantLoading(false);
  };

  const fetchMerchant = async () => {
    if (!networkMerchant?.MerchantID) return;

    setIsMerchantLoading(true);
    try {
      const merchantData = await getMerchant(networkMerchant.MerchantID);
      setMerchant(merchantData);
      setMerchantError('');
    } catch (error) {
      handleError(error, setMerchantError);
    }
    setIsMerchantLoading(false);
  };

  const fetchNetworkMerchantCommissions = async () => {
    if (!networkMerchant) return;

    setIsCommissionLoading(true);
    try {
      const commissionsData = await getNetworkMerchantCommissions(networkMerchant?.ID, { sortBy: 'disabled' });
      setCommissions(commissionsData);
      setError('');
    } catch (error) {
      handleError(error, setError);
    }
    setIsCommissionLoading(false);
  };

  const fetchNetworkMerchantCommissionHistory = async () => {
    if (!networkMerchant) return;

    setIsCommissionHistoryLoading(true);
    try {
      const newCommissionHistory = await getNetworkMerchantCommissionHistory(networkMerchant.ID);
      setCommissionHistory(newCommissionHistory);
      setError('');
    } catch (error) {
      handleError(error, setError);
    }
    setIsCommissionHistoryLoading(false);
  };

  const fetchNetworkMerchantKeywords = async () => {
    if (!networkMerchant) return;

    setIsKeywordLoading(true);
    try {
      const keywordsData = await getNetworkMerchantKeywords(networkMerchant.ID);
      setKeywords(keywordsData);
      setError('');
    } catch (error) {
      handleError(error, setError);
    }
    setIsKeywordLoading(false);
  };

  const fetchMerchantCategoryOptions = async () => {
    if (!networkMerchant?.MerchantID) return;

    try {
      const categoryOptions = await getCategoryOptions(networkMerchant.MerchantID);
      setMerchantCategoryOptions(categoryOptions);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const fetchMerchantCategories = async () => {
    if (!networkMerchant) return;

    try {
      const merchantCategories = await getMerchantCategories(networkMerchant.MerchantID);
      setMerchantCategories(merchantCategories);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  // Argument update can be one of many data types
  const handleUpdateNetworkMerchant = async (update: any) => {
    try {
      const newNetworkMerchant = {
        ...networkMerchant,
        ...update,
        ModifiedAuthor: userEmail ? userEmail : 'merchant admin nm update',
      };
      await updateNetworkMerchant(newNetworkMerchant);
      setNetworkMerchant(newNetworkMerchant);
      setError('');
    } catch (error) {
      handleError(error, setError);
    }
  };

  const handleUpdateMerchant = async (MerchantID: number) => {
    handleUpdateNetworkMerchant({ MerchantID });
    const currentMerchant = MerchantID > 0 ? await getMerchant(MerchantID) : null;
    setMerchant(currentMerchant);
  };

  const handleUpdateNetworkMerchantCommission = async (updatedCommission: Commission) => {
    const newCommissions = [...commissions].map(commission => {
      if (commission.ID === updatedCommission.ID) {
        // Lock any fields whose value has changed except Locks
        const updatedFields = getDifferingProperties(commission, updatedCommission).filter(field => field !== 'Locks');
        updatedCommission.Locks = [...new Set([...updatedCommission.Locks, ...updatedFields])];

        return updatedCommission;
      }
      return commission;
    });

    try {
      await updateNetworkMerchantCommission(updatedCommission);
      setCommissions(newCommissions);
      setError('');
    } catch (error) {
      handleError(error, setError);
    }
  };

  const handleUpdateNetworkMerchantCountries = async () => {
    if (!networkMerchant) return;

    if (selectedCountries.includes('All Countries')) {
      try {
        const updatedNetworkMerchant: NetworkMerchant = {
          ...networkMerchant,
          Countries: [...new Set(allCountryCodes)],
          ModifiedAuthor: userEmail ?? 'merchant admin nm update',
        };
        await updateNetworkMerchant(updatedNetworkMerchant);
        setNetworkMerchant(updatedNetworkMerchant);
        setSelectedCountries([...selectedCountries]);
        setError('');
      } catch (error) {
        handleError(error, setError);
      }
      return;
    }

    let updatedCountries: string[] = [];
    selectedCountries.forEach(countryString => {
      switch (countryString) {
        case 'South East Asia': {
          updatedCountries = [...updatedCountries, ...seaCountryCodes];
          break;
        }
        case 'US + US Territories': {
          updatedCountries = [...updatedCountries, ...unitedStateCountryCodes];
          break;
        }
        case 'Europe': {
          updatedCountries = [...updatedCountries, ...europeCountryCodes];
          break;
        }
        default: {
          updatedCountries.push(countryString);
        }
      }
    });

    //Removes any duplicates that the grouped values may have added
    const filteredSelectedCountries = [...new Set(updatedCountries)];

    try {
      const updatedNetworkMerchant: NetworkMerchant = {
        ...networkMerchant,
        Countries: filteredSelectedCountries,
        ModifiedAuthor: userEmail ?? 'merchant admin nm update',
      };

      await updateNetworkMerchant(updatedNetworkMerchant);
      setNetworkMerchant(updatedNetworkMerchant);
      setError('');
    } catch (error) {
      handleError(error, setError);
    }
  };

  const handleUpsertNetworkMerchantKeyword = async (keyword: Keyword) => {
    if (!networkMerchant) return;

    try {
      await upsertNetworkMerchantKeyword(networkMerchant.ID, keyword);
      await fetchNetworkMerchantKeywords();
      setKeywordError('');
    } catch (error) {
      handleError(error, setKeywordError);
    }
  };

  const handleUpdateMerchantCategories = async (Categories: MerchantCategory[]) => {
    if (!networkMerchant) return;

    try {
      const ModifiedAuthor = userEmail ?? 'merchant admin category update';
      await updateMerchantCategories(networkMerchant.MerchantID, { Categories, ModifiedAuthor });
      setMerchantCategories(Categories);
    } catch (error) {
      handleError(error, setError);
    }
  };

  const handleGoToMerchant = () => {
    if (!networkMerchant) return;

    history.push(`/merchant-admin/merchant/${networkMerchant.MerchantID}`);
  };

  const handleAddMerchantImage = async (imageDetails: FormData) => {
    if (!networkMerchant) return;

    try {
      await addMerchantImage(networkMerchant.MerchantID, imageDetails);
      const newMerchantData = await getMerchant(networkMerchant.MerchantID);
      setMerchant(newMerchantData);
      setLastUpdated();
    } catch (error) {
      handleError(error, setError);
    }
  };

  const handleRemoveMerchantImage = async (merchantImageId: number) => {
    if (!networkMerchant) return;

    try {
      await deleteMerchantImage(networkMerchant.MerchantID, merchantImageId);
      const newMerchantData = await getMerchant(networkMerchant.MerchantID);
      setMerchant(newMerchantData);
      setLastUpdated();
    } catch (error) {
      handleError(error, setError);
    }
  };

  const handleOpen = () => {
    fetchNetworkMerchant();
    setIsCommissionVisible(false);
  };

  const handleCommissionOpen = () => {
    setIsCommissionVisible(!isCommissionVisible);
  };

  const handleKeywordsOpen = () => {
    setIsKeywordVisible(!isKeywordVisible);
  };

  const handleImagesOpen = () => {
    setIsImageVisible(!isImageVisible);
  };

  const handleSelectedCountriesDropdown = (data: SelectedCountryOptions) => {
    const countryCheckboxesCopy: string[] = [...countryCheckboxes];
    const selectedCountriesCopy: string[] = [...selectedCountries];
    const deletedCountry = selectedCountries.find(country => !data.value?.includes(country));
    const deletedCountryCheckboxIndex = countryCheckboxesCopy.findIndex(country => country === deletedCountry);
    const deletedCountryIndex = selectedCountriesCopy.findIndex(country => country === deletedCountry);

    // When an option is deleted
    if (deletedCountry) {
      // If deleted option is one of the countries checkboxes, uncheck the corresponding checkbox
      if (deletedCountryCheckboxIndex > -1) {
        countryCheckboxesCopy.splice(deletedCountryCheckboxIndex, 1);
      }
      selectedCountriesCopy.splice(deletedCountryIndex, 1);

      setCountryCheckboxes(countryCheckboxesCopy);
      setSelectedCountries(selectedCountriesCopy);
      // When an option is added
      // and the added option is one of the countries checkboxes, check the corresponding checkbox
    } else {
      if (data.value.includes('US + US Territories') && !countryCheckboxesCopy.includes('US + US Territories')) {
        countryCheckboxesCopy.push('US + US Territories');
      }
      if (data.value.includes('Europe') && !countryCheckboxesCopy.includes('Europe')) {
        countryCheckboxesCopy.push('Europe');
      }
      if (data.value.includes('South East Asia') && !countryCheckboxesCopy.includes('South East Asia')) {
        countryCheckboxesCopy.push('South East Asia');
      }

      setCountryCheckboxes(countryCheckboxesCopy);
    }
    setSelectedCountries(data.value);
  };

  const handleCountryCheckboxToggle = (checked: boolean | undefined, countryString: string) => {
    const countryCheckboxesCopy = [...countryCheckboxes];
    const selectedCountriesCopy = [...selectedCountries];
    const countryCheckboxIndex = countryCheckboxesCopy.findIndex(countryCheckbox => countryCheckbox === countryString);
    const selectedCountryIndex = selectedCountriesCopy.findIndex(selectedCountry => selectedCountry === countryString);

    // When countries checkboxes are checked also add the corresponding countries to selected countries dropdown
    if (checked) {
      countryCheckboxesCopy.push(countryString);
      selectedCountriesCopy.push(countryString);

      setCountryCheckboxes(countryCheckboxesCopy);
      setSelectedCountries(selectedCountriesCopy);
      // When countries checkboxes are unchecked also remove the corresponding countries from the dropdown
    } else {
      countryCheckboxesCopy.splice(countryCheckboxIndex, 1);
      selectedCountriesCopy.splice(selectedCountryIndex, 1);

      setCountryCheckboxes(countryCheckboxesCopy);
      setSelectedCountries(selectedCountriesCopy);
    }
  };

  const isLoading = () => {
    const allLoadingStates = [
      isNetworkMerchantLoading,
      isCommissionLoading,
      isCommissionHistoryLoading,
      isKeywordLoading,
      isMerchantLoading,
    ];
    // Returns true if any of the requests are still loading
    return allLoadingStates.some(isLoading => isLoading);
  };

  useEffect(() => {
    setIsDQContractor(checkIsDQContractor(user));
  }, [user]);

  useEffect(() => {
    if (!networkMerchant) return;
    if (networkMerchant.MerchantID) fetchMerchant();

    fetchTestLink();
    setSelectedCountries(networkMerchant?.Countries);
    fetchNetworkMerchantKeywords();
    fetchNetworkMerchantCommissions();
    fetchNetworkMerchantCommissionHistory();
  }, [networkMerchant]);

  useEffect(() => {
    fetchNetworkMerchantCommissionHistory();
  }, [commissions]);

  useEffect(() => {
    if (!merchant || !networkMerchant?.MerchantID) return;

    fetchMerchantCategories();
    fetchMerchantCategoryOptions();
  }, [merchant]);

  return (
    <Segment.Group className="network-merchant-onboarding-form">
      <Modal
        className="network-merchant-onboarding-modal"
        closeIcon={true}
        trigger={
          <Button
            onClick={() => {
              ReactGA.event('network_merchant_onboarding_click', {
                action: 'click',
                userId: user?.sub || 'Not provided',
                page: 'Network Merchant Onboarding',
              });
            }}
          >
            Onboard
          </Button>
        }
        onOpen={handleOpen}
        onClose={() => setIsTestLinkPreviewLoading(true)}
      >
        <Modal.Header>
          <h1 className="modal-title">Network Merchant Onboarding Form</h1>
          <h3 className="network-merchant-name">{`${networkMerchant?.Name} (ID: ${networkMerchant?.ID})`}</h3>
        </Modal.Header>
        <Modal.Content scrolling>
          <p className="message my-3">This form saves automatically every time a value is changed.</p>
          {error ? (
            <>
              <p>An error occurred. Please try again in a few minutes.</p>
              <p>{error}</p>
            </>
          ) : isLoading() ? (
            <Dimmer active inverted>
              <Loader size="big" inline="centered" />
            </Dimmer>
          ) : (
            networkMerchant && (
              <>
                <div className="split-container-wrapper">
                  <div className="split-container field-container">
                    <div className="field">
                      <p className="input-label">URL</p>
                      <div className="input-container">
                        <Input
                          label="URL"
                          value={networkMerchant.URL}
                          handleResourceChange={handleUpdateNetworkMerchant}
                          disabled={isDQContractor || !canEdit}
                        />
                        <a href={networkMerchant.URL} target="_blank" rel="noopener noreferrer">
                          <Icon name="external alternate" />
                        </a>
                      </div>
                    </div>
                    {!isDQContractor && (
                      <div className="field">
                        <p className="input-label">Banner ID</p>
                        <div className="input-container">
                          <Input
                            label={
                              <Lock
                                locks={networkMerchant?.Locks}
                                label="BannerID"
                                name="BannerID"
                                handleResourceChange={handleUpdateNetworkMerchant}
                                disabled={!canEdit}
                              />
                            }
                            value={networkMerchant.BannerID}
                            handleResourceChange={handleUpdateNetworkMerchant}
                            disabled={isDQContractor || !canEdit}
                          />
                        </div>
                      </div>
                    )}
                    <div className="field">
                      <p className="input-label">Network</p>
                      <div className="input-container">
                        <Input
                          label="Network"
                          value={`${networkMerchant.NetworkID} (${
                            findNetworkNameWithID(networks, networkMerchant.NetworkID) || 'Unknown Network'
                          })`}
                          handleResourceChange={handleUpdateNetworkMerchant}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="split-container field-container">
                    <div className="field">
                      <p className="input-label">Test Link</p>
                      <div className="input-container test-link">
                        <div className="input-info">
                          <Form.Input label="Test Link" value={fetchTestLink()} disabled={!canEdit} />
                          <a href={fetchTestLink()} target="_blank" rel="noopener noreferrer">
                            <Icon name="external alternate" />
                          </a>
                        </div>
                        <div className="preview-container">
                          {isTestLinkPreviewLoading && <p>Loading preview...</p>}
                          <iframe
                            src={fetchTestLink()}
                            title="Test link preview"
                            onLoad={() => setIsTestLinkPreviewLoading(false)}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!!commissions.length && (
                  <div className="field-container">
                    <div className="accordion-container">
                      <Accordion>
                        <Accordion.Title className="header" onClick={handleCommissionOpen}>
                          <p className="input-label">
                            Commissions
                            <span> ({commissions.length})</span>
                          </p>
                          <Icon name={isCommissionVisible ? 'triangle up' : 'triangle down'} />
                        </Accordion.Title>
                        <Accordion.Content active={isCommissionVisible}>
                          <NetworkMerchantOnboardingCommissionsTable
                            isDQContractor={isDQContractor}
                            commissions={commissions}
                            handleUpdateCommission={handleUpdateNetworkMerchantCommission}
                          />
                          <CommissionHistoryLog
                            commissions={commissions}
                            nm={networkMerchant}
                            commissionHistory={commissionHistory}
                          />
                        </Accordion.Content>
                      </Accordion>
                    </div>
                  </div>
                )}
                <div className="field-container countries">
                  <div className="label-container">
                    <Lock
                      locks={networkMerchant?.Locks}
                      label="Countries"
                      name="Countries"
                      handleResourceChange={handleUpdateNetworkMerchant}
                      disabled={!canEdit}
                    />
                    <p className="input-label">Countries</p>
                  </div>
                  <div className="checkboxContainer">
                    {canEdit && (
                      <div className="checkbox-container">
                        <Form.Checkbox
                          label="All Countries"
                          checked={countryCheckboxes.includes('All Countries')}
                          onChange={(event, { checked }) => {
                            if (checked) {
                              setCountryCheckboxes(['All Countries']);
                              setSelectedCountries(['All Countries']);
                            } else {
                              setCountryCheckboxes([]);
                              setSelectedCountries([]);
                            }
                          }}
                        />
                        <Form.Checkbox
                          label="US + US Territories"
                          checked={countryCheckboxes.includes('US + US Territories')}
                          onChange={(event, { checked }) => handleCountryCheckboxToggle(checked, 'US + US Territories')}
                          disabled={countryCheckboxes.includes('All Countries')}
                        />
                        <Form.Checkbox
                          label="Europe"
                          checked={countryCheckboxes.includes('Europe')}
                          onChange={(event, { checked }) => handleCountryCheckboxToggle(checked, 'Europe')}
                          disabled={countryCheckboxes.includes('All Countries')}
                        />
                        <Form.Checkbox
                          label="South East Asia"
                          checked={countryCheckboxes.includes('South East Asia')}
                          onChange={(event, { checked }) => handleCountryCheckboxToggle(checked, 'South East Asia')}
                          disabled={countryCheckboxes.includes('All Countries')}
                        />
                      </div>
                    )}
                  </div>
                  <div className="dropdowns-container">
                    <Form.Dropdown
                      className="dropdown"
                      fluid
                      multiple
                      selection
                      clearable
                      search
                      name="countries"
                      placeholder="Select Countries"
                      options={CountryCodes}
                      value={selectedCountries}
                      // Semantic UI's DropdownProps expects value to be one of many types but we need it to be a string[]
                      onChange={(e, data) => handleSelectedCountriesDropdown(data as SelectedCountryOptions)}
                      disabled={!canEdit}
                    />
                    <Form.Button
                      className="button countries-submit"
                      type="submit"
                      onClick={handleUpdateNetworkMerchantCountries}
                      disabled={!canEdit || areEqualArrays(networkMerchant.Countries, selectedCountries)} // Disable button if nothing is changed
                    >
                      Save
                    </Form.Button>
                  </div>
                </div>
                {!isDQContractor && (
                  <div className="field-container">
                    <div className="accordion-container">
                      <Accordion>
                        <Accordion.Title className="header" onClick={handleKeywordsOpen}>
                          <h3 className="input-label">
                            Keywords
                            <span> ({keywords?.length})</span>
                          </h3>
                          <div className="icons-container">
                            <Modal
                              className="add-keywords"
                              onClose={() => setKeywordError('')}
                              trigger={<Button icon="add" className="button-add-keywords" />}
                            >
                              <Modal.Header>Add Keyword</Modal.Header>
                              <Modal.Content>
                                {keywordError ? (
                                  <>
                                    <p>An error occurred. Please try again in a few minutes.</p>
                                    <p>{keywordError}</p>
                                  </>
                                ) : (
                                  <AddKeyword handleAddKeyword={handleUpsertNetworkMerchantKeyword} />
                                )}
                              </Modal.Content>
                            </Modal>
                            <Icon name={isKeywordVisible ? 'triangle up' : 'triangle down'} />
                          </div>
                        </Accordion.Title>
                        <Accordion.Content active={isKeywordVisible}>
                          <NetworkMerchantOnboardingKeywordList
                            keywords={keywords}
                            keywordError={keywordError}
                            handleUpsertNetworkMerchantKeyword={handleUpsertNetworkMerchantKeyword}
                            setKeywordError={setKeywordError}
                          />
                        </Accordion.Content>
                      </Accordion>
                    </div>
                  </div>
                )}
                <div className="pays-new-customer-container">
                  <Checkbox
                    toggle
                    label={
                      <label>
                        <p className="input-label">Pays new customers only</p>
                      </label>
                    }
                    checked={networkMerchant.ExistingCustomerRateDisabled}
                    onChange={() =>
                      handleUpdateNetworkMerchant({
                        ExistingCustomerRateDisabled: !networkMerchant.ExistingCustomerRateDisabled,
                        Locks: [...networkMerchant.Locks, 'ExistingCustomerRateDisabled'],
                      })
                    }
                    disabled={!canEdit}
                  />
                </div>
                <div className="field-container merchant-id">
                  <div className="field">
                    <p className="input-label">Merchant ID</p>
                    <div className="input-container">
                      <NetworkMerchantOnboardingAddMerchants
                        merchant={merchant}
                        merchantID={networkMerchant.MerchantID}
                        merchantError={merchantError}
                        handleUpdateMerchant={handleUpdateMerchant}
                        setMerchantError={setMerchantError}
                      />
                      <Button disabled={!networkMerchant.MerchantID} onClick={handleGoToMerchant}>
                        <Icon name="cube" />
                      </Button>
                    </div>
                  </div>
                </div>
                {isMerchantLoading ? (
                  <Dimmer active inverted>
                    <Loader size="big" inline="centered" />
                  </Dimmer>
                ) : (
                  merchant &&
                  networkMerchant?.MerchantID && (
                    <>
                      <div className="field-container merchant-category">
                        <div className="label-container">
                          <p className="input-label">Primary Category</p>
                        </div>
                        <div className="input-container">
                          <CategoryModal
                            merchantID={networkMerchant?.MerchantID}
                            categoryOptions={merchantCategoryOptions}
                            merchantCategories={merchantCategories}
                            updateMerchantCategories={handleUpdateMerchantCategories}
                            disabled={!canEdit}
                          />
                        </div>
                      </div>
                      <div className="field-container images">
                        <div className="accordion-container">
                          <NetworkMerchantOnboardingImageList
                            images={merchant.Images}
                            isImageVisible={isImageVisible}
                            addMerchantImage={handleAddMerchantImage}
                            removeMerchantImage={handleRemoveMerchantImage}
                            handleImagesOpen={handleImagesOpen}
                          />
                        </div>
                      </div>
                    </>
                  )
                )}
              </>
            )
          )}
        </Modal.Content>
      </Modal>
    </Segment.Group>
  );
};

export default NetworkMerchantOnboardingModal;

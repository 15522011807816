import React from 'react';
import { Table, TableHeader, TableHeaderCell, TableBody, TableRow, TableCell } from 'semantic-ui-react';

const SearchGlossary = () => {
  return (
    <Table compact style={{ marginBottom: '20px' }}>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Search Prefix</TableHeaderCell>
          <TableHeaderCell>Description</TableHeaderCell>
          <TableHeaderCell>Notes</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>CreatedDate:&gt;"YYYY-MM-DD"</TableCell>
          <TableCell>Created after the given date</TableCell>
          <TableCell>Date surrounded by quotes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>CreatedDate:&lt;"YYYY-MM-DD"</TableCell>
          <TableCell>Created before the given date</TableCell>
          <TableCell>Date surrounded by quotes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>EventDate:&gt;"YYYY-MM-DD"</TableCell>
          <TableCell>Sale occurred after the given date</TableCell>
          <TableCell>Date surrounded by quotes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>EventDate:&lt;"YYYY-MM-DD"</TableCell>
          <TableCell>Sale occurred before the given date</TableCell>
          <TableCell>Date surrounded by quotes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Merchant:"Merchant Name"</TableCell>
          <TableCell>Merchant Name</TableCell>
          <TableCell>Case Sensitive</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>SaleAmount:&gt;123</TableCell>
          <TableCell>Sale amount greater than provided value</TableCell>
          <TableCell>No quotes around number</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>SaleAmount:&lt;123</TableCell>
          <TableCell>Sale amount less than provided value</TableCell>
          <TableCell>No quotes around number</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>TrackingCode:"uid=b7b09b78-141f-46a0-af7b-076c6cac4405,o=transaction,dp=safari-mbl"</TableCell>
          <TableCell>Tracking Code for commission</TableCell>
          <TableCell>Tracking Code surrounded by quotes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>MerchantSKU:1234</TableCell>
          <TableCell>Merchant SKU</TableCell>
          <TableCell>SKU not surrounded by quotes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>MerchantID:1234</TableCell>
          <TableCell>Wildfire Merchant ID</TableCell>
          <TableCell>ID not surrounded by quotes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>MerchantOrderID:1234</TableCell>
          <TableCell>Order ID</TableCell>
          <TableCell>ID not surrounded by quotes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>DeviceID:1234</TableCell>
          <TableCell>Device used during sale that resulted in commission</TableCell>
          <TableCell>ID not surrounded by quotes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ID:1234</TableCell>
          <TableCell>Commission ID</TableCell>
          <TableCell>ID not surrounded by quotes</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default SearchGlossary;

import { wfGet, wfPut } from '../../actions';

export const getKeywordNetworkMerchants = id => async dispatch => {
  try {
    const networkMerchants = await dispatch(wfGet(`/api/keyword/${id}/network-merchant`));
    return networkMerchants;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const renameKeyword = (newKeywordName, networkMerchantKeywords) => async dispatch => {
  try {
    const newKeyword = await dispatch(
      wfPut('/api/keyword/network-merchant', { newKeywordName, networkMerchantKeywords }),
    );
    return newKeyword;
  } catch (error) {
    return Promise.reject(error);
  }
};

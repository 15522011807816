import React, { useState, useEffect } from 'react';
import { Button, Input } from 'semantic-ui-react';
import './JSONConverstionTool.scss';

const arrayToCSV = (data: Array<{ [key: string]: any }>): string => {
  if (data.length === 0) return '';
  const propertyKeys = Object.keys(data[0]);
  const csvHeaders = propertyKeys.map(key => `"${key.replaceAll('"', '""')}"`).join(',');
  const rows = [csvHeaders];
  for (let i = 0; i < data.length; i++) {
    const row = [];
    for (let j = 0; j < propertyKeys.length; j++) {
      const key = propertyKeys[j];
      const datum = data[i][key];
      if (datum == null) {
        row.push('');
      } else if (typeof datum == 'object') {
        row.push(`"${JSON.stringify(datum).replaceAll('"', '""')}"`);
      } else {
        row.push(`"${String(datum).replaceAll('"', '""')}"`);
      }
    }
    rows.push(row.join(','));
  }
  return rows.join('\n');
};

const JSONConversionTool = () => {
  const [file, setFile] = useState<File | undefined>();
  const [objectURL, setObjectURL] = useState<string | undefined>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFile(file);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (file == null) return;
    const reader = new FileReader();
    reader.addEventListener('load', loadEvent => {
      const json = loadEvent.target?.result;
      if (typeof json != 'string') return;
      let payload;
      try {
        payload = JSON.parse(json);
      } catch (err) {
        console.error(err);
        alert('JSON data is malformed. Failed to parse!');
        return;
      }
      if (!Array.isArray(payload)) {
        alert(`JSON data must be an Array (list). Got '${typeof payload}'`);
        return;
      }
      if (payload.length === 0) {
        alert('JSON data Array is empty (0 elements). Nothing to convert!');
        return;
      }
      const csv = arrayToCSV(payload);
      const objectURL = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
      setObjectURL(objectURL);
    });
    reader.readAsText(file);
  };

  useEffect(() => {
    if (file == null || objectURL == null) {
      return;
    }
    const $a = document.createElement('a');
    $a.setAttribute('href', objectURL);
    $a.setAttribute('download', file.name.replace(/\.json$/, '.csv'));
    $a.click();
    return () => URL.revokeObjectURL(objectURL);
  }, [file, objectURL]);

  const isSubmitDisabled = typeof file === 'undefined';

  return (
    <form className="json-conversion-form" onSubmit={handleSubmit}>
      <Input color="blue" type="file" accept="application/json" onChange={handleChange} />
      <Button type="submit" color="blue" disabled={isSubmitDisabled}>
        Convert
      </Button>
    </form>
  );
};

export default JSONConversionTool;

import React from 'react';
import PropTypes from 'prop-types';

import './FetchError.scss';

const FetchError = ({ onRetry }) => (
  <div className="error">
    <div>There was error fetching the resource.</div>
    <div className="retry" onClick={onRetry}>
      Retry
    </div>
  </div>
);

FetchError.propTypes = {
  onRetry: PropTypes.func.isRequired,
};

export default FetchError;

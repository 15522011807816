import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Search as SearchUI, Grid, Icon } from 'semantic-ui-react';
import AddMerchantContainer from './AddMerchant/AddMerchantContainer';
import MerchantSearchResult from './MerchantSearchResult';
import { objectKeysToLowerCase } from '../../../helpers';

import './MerchantSearch.css';

class MerchantSearch extends Component {
  state = {
    query: '',
    isLoading: false,
    merchants: [],
    currentMerchant: null,
    addingMerchant: false,
  };

  inputRef = React.createRef();

  search = async query => {
    const { searchMerchants } = this.props;
    this.setState({ isLoading: true });
    try {
      const merchants = await searchMerchants(query);
      this.setState({
        merchants,
        isLoading: false,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  };

  debounceSearch = _.debounce(this.search, 300);

  handleChange = e => {
    const { value } = e.target;
    this.setState({ query: value });
    if (value.length > 2) {
      this.debounceSearch(value);
    }
    if (value.length === 0) {
      this.setState({ merchants: [] });
    }
  };

  // params because we are passing to semantic ui Search
  handleResultSelect = (e, data) => {
    const { handleResultSelect } = this.props;
    try {
      handleResultSelect(data.result.id);
    } catch (error) {
      console.error(error);
    }
  };

  toggleAddingMerchant = () => {
    const { addingMerchant } = this.state;
    this.setState({ addingMerchant: !addingMerchant });
  };

  removeNetworkMerchantMerchant = () => {
    this.props.handleResultSelect(0);
  };

  async componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    const { isLoading, merchants, query, addingMerchant } = this.state;
    const { currentMerchant, handleResultSelect } = this.props;
    const formattedMerchants = merchants.map(i => objectKeysToLowerCase(i));

    return (
      <Grid className="merchant-search">
        <Grid.Column>
          <div className="header">
            <div className="current-merchant">
              Current Merchant:
              {currentMerchant ? (
                <span>
                  {currentMerchant.Name} ({currentMerchant.ID}){' '}
                  <Icon name="times" onClick={this.removeNetworkMerchantMerchant} />
                </span>
              ) : (
                ' NONE'
              )}
            </div>
            <Icon
              name={addingMerchant ? 'times' : 'add'}
              size="large"
              className="add-merchant-icon"
              onClick={this.toggleAddingMerchant}
            />
          </div>
          {addingMerchant ? (
            <AddMerchantContainer handleUpdateNetworkMerchantMerchant={handleResultSelect} />
          ) : (
            <SearchUI
              className="search"
              fluid
              input={{ fluid: true, placeholder: 'Search...', iconPosition: 'left', ref: this.inputRef }}
              loading={isLoading}
              onResultSelect={this.handleResultSelect}
              onSearchChange={this.handleChange}
              results={formattedMerchants}
              value={query}
              resultRenderer={MerchantSearchResult}
              open={formattedMerchants.length > 0}
            />
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

MerchantSearch.propTypes = {
  searchMerchants: PropTypes.func.isRequired,
  handleResultSelect: PropTypes.func.isRequired,
  currentMerchant: PropTypes.object,
};

export default MerchantSearch;

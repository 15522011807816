import { ImageKindType } from '../components/Image/AddImage/types';

declare global {
  interface Window {
    env: {
      serverUri: string;
      auth0Domain: string;
      auth0ClientId: string;
      auth0Audience: string;
    };
  }
}

// dev uses import.meta.env and prod uses window.env
export const SERVER_ENDPOINT = import.meta.env.VITE_APP_SERVER_ENDPOINT || window.env.serverUri;

export const AUTH0_DOMAIN = import.meta.env.VITE_APP_AUTH0_DOMAIN || window.env.auth0Domain;
export const AUTH0_CLIENT_ID = import.meta.env.VITE_APP_AUTH0_CLIENT_ID || window.env.auth0ClientId;
export const AUTH0_AUDIENCE = import.meta.env.VITE_APP_AUTH0_AUDIENCE || window.env.auth0Audience;

export const IMAGE_KINDS: ImageKindType = {
  FEATURED: {
    NAME: 'FEATURED',
    WIDTH: 660,
    HEIGHT: 380,
  },
  LOGO: {
    NAME: 'LOGO',
    WIDTH: 200,
    HEIGHT: 200,
  },
  LOGORECT: {
    NAME: 'LOGORECT',
    WIDTH: 260,
    HEIGHT: 200,
  },
};

import { wfGet } from 'actions/wfApi';

export const searchCommissions = (query, pageSize) => async dispatch => {
  try {
    const response = await dispatch(wfGet(`/api/commission-search?q=${query}&pagesize=${pageSize}`));
    return response.body;
  } catch (error) {
    return Promise.reject(error);
  }
};

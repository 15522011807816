import {
  NetworkActionTypes,
  GET_NETWORKS_REQUEST,
  GET_NETWORKS_SUCCESS,
  NetworkAction,
  GET_NETWORKS_FAILURE,
} from './types';

export const getNetworksRequest = (): NetworkActionTypes => ({
  type: GET_NETWORKS_REQUEST,
  payload: undefined,
});

export const getNetworksSuccess = (
  payload: NetworkAction<typeof GET_NETWORKS_SUCCESS>['payload'],
): NetworkActionTypes => ({
  type: GET_NETWORKS_SUCCESS,
  payload,
});

export const getNetworksFailure = (
  payload: NetworkAction<typeof GET_NETWORKS_FAILURE>['payload'],
): NetworkActionTypes => ({
  type: GET_NETWORKS_FAILURE,
  payload,
});

import React from 'react';
import FindDeviceID from 'components/BonusPayments/FindDeviceID';
import './BonusPayments.scss';
import BonusPaymentsList from 'components/BonusPayments/BonusPaymentsList';

const BonusPayments = () => {
  return (
    <div className="bonus-payments">
      <h1 className="bonus-payments-title">Bonus Payments</h1>
      <FindDeviceID />
      <BonusPaymentsList />
    </div>
  );
};

export default BonusPayments;

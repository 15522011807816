import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';

const MerchantSearchResult = ({ disabled, name, onResultSelect }) => {
  return (
    <Grid className="searchResult" columns="equal" verticalAlign="middle" onClick={onResultSelect}>
      <Grid.Row className={disabled ? 'disabled' : null}>
        <Grid.Column>
          <Icon name="cube" />
          {name}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

MerchantSearchResult.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onResultSelect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default MerchantSearchResult;

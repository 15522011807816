import { connect } from 'react-redux';
import SalesDashboard from './SalesDashboard';
import {
  getDashboardStats,
  getFilterOptions,
  getSalesDashboardFilterItems,
} from '../../actions/salesDashboard/dashboardStats';

const mapStateToProps = state => ({
  dashboardStats: state.dashboardStats,
});

const mapActionsToProps = {
  getDashboardStats,
  getFilterOptions,
  getSalesDashboardFilterItems,
};

export default connect(mapStateToProps, mapActionsToProps)(SalesDashboard);
